import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import useDimensions from 'react-use-dimensions';

import { Grid, Hidden } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

import PageLayout from '../../components/Display/PageLayout';
import TabPanel from '../../components/Display/TabPanel';
import Tabs from '../../components/Display/Tabs';
import TabItem from '../../components/Inputs/TabItem';
import PanelHeader from '../../components/Display/PanelHeader';
import Button from '../../components/Buttons/Button';
import AppCard from '../../components/Display/AppCard';
import AddNewUserForm from '../../components/Display/Organisation/AddNewUserForm';
import OrganisationMetaData from '../../components/Display/Organisation/OrganisationMetaData';
import TeamDetails from '../../components/Display/Organisation/TeamDetails';
import YourProfile from '../../components/Display/Organisation/YourProfile';
import Billing from '../../components/Display/Organisation/Billing';

import ConfirmNameModal from '../../components/Modals/ConfirmNameModal';
import EditAppNameModal from '../../components/Modals/EditAppNameModal';
import DeleteAppModal from '../../components/Modals/DeleteAppModal';
import AppKeyModal from '../../components/Modals/AppKeyModal';
import TeamMemberNotificationModal from '../../components/Modals/TeamMemberNotificationModal';

import useActions from '../../redux/actions/useActions';
import userOptionsSelector from '../../redux/selectors/userOptionsSelector';
import userOptionsActions from '../../redux/actions/userOptionsActions';
import ModalContext from '../../contexts/modalContext';

import AppsApi from '../../api/v1/app/app';
import OrganisationApi from '../../api/v1/organisation/organisations';
import CustomerApi from '../../api/v1/customer/customer';
import type NewUserBody from '../../api/v1/organisation/types/NewUserBody.type';

import styles from './_organization.module.scss';

import useNotifications from '../../contexts/notificationContext';
import messages from '../../messages/info';

const Organization = () => {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [apps, setApps] = useState([]);
  const [organisation, setOrganisation] = useState({});
  const [team, setTeam] = useState({});
  const [profileDetails, setProfileDetails] = useState({});
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { addSuccessNotification, addErrorNotification } = useNotifications();

  const { fromDate, toDate, apps: allApps, currentAppId } = useSelector(userOptionsSelector);
  const { setApps: setAppsAction, setCurrentApp, setSelectedDateRange } = useActions(
    userOptionsActions,
  );

  const { setModalContent, clearModalContent } = useContext(ModalContext);

  const [tallestAppCard, tallestAppCardSize] = useDimensions();

  const hasAdminPermission = profileDetails.role !== 'USER';

  const TAB_IDX_APPS = 0;
  const TAB_IDX_ORG = 1;
  const TAB_IDX_PROFILE = 2;
  const TAB_IDX_BILLING = 3;

  const resetCurrentApp = async (updatedApps) => {
    const firstApp = updatedApps[0];
    const { data: appKey } = await AppsApi.getAppKey(firstApp.id);

    await setCurrentApp({
      appId: firstApp.id,
      appKey,
    });
  };

  const getApps = (shouldResetCurrentApp = false) => {
    (async () => {
      setLoading(true);
      try {
        const { data: fetchedApps } = await AppsApi.getAuthorisedApps();
        const { data: updatedProfileDetails } = await CustomerApi.getProfileDetails();
        // Keep this one last because of defect WA-417 - Race condition at Auth0
        const { data: organisationOverview } = await OrganisationApi.getOrganisationOverview();

        setApps(fetchedApps);
        setAppsAction(fetchedApps);
        setOrganisation(organisationOverview.organisation);
        setTeam(organisationOverview.team);
        setProfileDetails(updatedProfileDetails);
        setError(null);

        if (shouldResetCurrentApp) {
          await resetCurrentApp(fetchedApps);
        }
      } catch (apiError) {
        setError(apiError.message);
      } finally {
        setLoading(false);
      }
    })();
  };

  useEffect(() => {
    getApps();
  }, []);

  const handleTabChange = (__, tabIndex) => {
    setSelectedTabIndex(tabIndex);
  };

  const handleModalClose = (
    shouldRerender: boolean = false,
    shouldReselectApp: boolean = false,
  ) => {
    clearModalContent();

    if (shouldRerender === true) {
      getApps(shouldReselectApp);
    }
  };

  const handleProfileUpdate = () => {
    getApps();
  };

  const showAddNewAppModal = () => {
    setModalContent(
      <ConfirmNameModal
        title='Add a new app to your account'
        info={messages.ADD_NEW_APP_INFO}
        onModalClose={handleModalClose}
        confirmButtonLabel='Add New App'
        placeholder='Enter your app name'
        onConfirmButtonPress={AppsApi.addNewApp}
        data-testid='addNewAppModal'
      />,
    );
  };

  const showEditAppNameModal = (app) => {
    setModalContent(<EditAppNameModal app={app} onModalClose={handleModalClose} />);
  };

  const handleDeleteAppModalClose = (app, isAppDeleted = false) => {
    const isCurrentApp = app.id === currentAppId;

    if (isAppDeleted && isCurrentApp) {
      setCurrentApp({
        appId: null,
        appKey: null,
      });
    }

    handleModalClose(isAppDeleted, isCurrentApp);
  };

  const showDeleteAppModal = (app) => {
    setModalContent(<DeleteAppModal app={app} onModalClose={handleDeleteAppModalClose} />);
  };

  const showAppKeyModal = (app) => {
    setModalContent(<AppKeyModal app={app} onModalClose={handleModalClose} />);
  };

  const resendConfirmationEmail = async (emailAddress: string) => {
    try {
      handleModalClose();
      await OrganisationApi.sendNewUserConfirmationEmail(emailAddress);
      addSuccessNotification('Email Resent');
    } catch (apiError) {
      addErrorNotification(apiError.message);
    }
  };

  const onTeamMemberNotVerifiedIconPress = (teamMember) => {
    setModalContent(
      <TeamMemberNotificationModal
        emailAddress={teamMember.email}
        resendConfirmationEmail={() => resendConfirmationEmail(teamMember.email)}
        onModalClose={handleModalClose}
      />,
    );
  };

  const handleAddNewUserSuccess = (newUserBody: NewUserBody) => {
    setModalContent(
      <TeamMemberNotificationModal
        titleText='A new user has been added!'
        bodyText='An email has been sent to confirm and create a full account.'
        emailAddress={newUserBody.emailAddress}
        onModalClose={handleModalClose}
      />,
    );
    getApps(false);
  };

  const getLongestAppName = () => {
    const nameLengthSortedApps = [...apps].sort((app1, app2) => {
      return app1.appName.length < app2.appName.length ? 1 : -1;
    });
    return nameLengthSortedApps[0].appName;
  };

  const renderAppCards = () => {
    return apps.map((app) => {
      const hasLongestAppName = app.appName === getLongestAppName();
      return (
        <Grid item xs={12} md={3} key={app.id}>
          <AppCard
            app={app}
            onEditPress={() => showEditAppNameModal(app)}
            onDeletePress={() => showDeleteAppModal(app)}
            onViewAppKeyPress={() => showAppKeyModal(app)}
            style={hasLongestAppName ? null : { height: tallestAppCardSize.height - 60 }}
            ref={hasLongestAppName ? tallestAppCard : null}
            isAdmin={hasAdminPermission}
          />
        </Grid>
      );
    });
  };

  const renderAppsPanel = () => {
    return (
      <TabPanel
        panelIndex={TAB_IDX_APPS}
        currentIndex={selectedTabIndex}
        className={styles.panel}
        data-testid='Organization_TabPanel_Apps'
      >
        <PanelHeader
          title='All Apps'
          rightComponent={
            hasAdminPermission && <Button onClick={showAddNewAppModal}>Add New App</Button>
          }
        />
        <Grid container className={styles.allAppsListContainer} spacing={5}>
          {renderAppCards()}
        </Grid>
      </TabPanel>
    );
  };

  const renderOrganizationPanel = () => {
    const { createdOn, subscription, name } = organisation;
    return (
      <TabPanel
        panelIndex={TAB_IDX_ORG}
        currentIndex={selectedTabIndex}
        className={styles.panel}
        data-testid='Organization_TabPanel_Organization'
      >
        <PanelHeader title={name} />
        <OrganisationMetaData
          createdOn={createdOn}
          team={team.length}
          applications={apps.length}
          subscription={subscription}
        />

        <Divider className={styles.organisationDivider} />

        <Box className={styles.organisationRoot}>
          <TeamDetails
            team={team}
            currentUser={profileDetails}
            onUserDeleted={getApps}
            onUserRoleChange={getApps}
            onTeamMemberNotVerifiedIconPress={onTeamMemberNotVerifiedIconPress}
          />

          {hasAdminPermission && (
            <>
              <Hidden smDown>
                <Divider orientation='vertical' />
              </Hidden>
              <AddNewUserForm onSuccess={handleAddNewUserSuccess} />
            </>
          )}
        </Box>
      </TabPanel>
    );
  };

  const renderYourProfilePanel = () => {
    return (
      <TabPanel
        panelIndex={TAB_IDX_PROFILE}
        currentIndex={selectedTabIndex}
        className={styles.panel}
        data-testid='Organization_TabPanel_YourProfile'
      >
        <YourProfile
          email={profileDetails.email}
          firstName={profileDetails.firstName}
          lastName={profileDetails.lastName}
          createdOnDate={profileDetails.createdOnDate}
          onProfileUpdated={handleProfileUpdate}
        />
      </TabPanel>
    );
  };

  const renderBillingPanel = () => {
    return (
      <TabPanel
        panelIndex={TAB_IDX_BILLING}
        currentIndex={selectedTabIndex}
        className={styles.panel}
        data-testid='Organization_TabPanel_Billing'
      >
        <Billing setError={setError} />
      </TabPanel>
    );
  };

  const renderPanel = () => {
    switch (selectedTabIndex) {
      case TAB_IDX_APPS:
        return renderAppsPanel();
      case TAB_IDX_ORG:
        return renderOrganizationPanel();
      case TAB_IDX_PROFILE:
        return renderYourProfilePanel();
      case TAB_IDX_BILLING:
        return renderBillingPanel();
      default:
        return renderAppsPanel();
    }
  };

  return (
    <PageLayout
      fromDate={fromDate}
      toDate={toDate}
      onDatesSelected={setSelectedDateRange}
      apps={allApps}
      currentAppId={currentAppId}
      isLoading={isLoading}
      error={error}
      highlightTab='Organization'
    >
      <div className={styles.organisationContainer}>
        {!isLoading && (
          <>
            <Tabs value={selectedTabIndex} onChange={handleTabChange}>
              <TabItem label='Apps' selected={selectedTabIndex === TAB_IDX_APPS} start />
              <TabItem label='Your Team' selected={selectedTabIndex === TAB_IDX_ORG} />
              <TabItem
                label='Your Profile'
                selected={selectedTabIndex === TAB_IDX_PROFILE}
                end={!hasAdminPermission}
              />
              {hasAdminPermission && (
                <TabItem label='Billing' selected={selectedTabIndex === TAB_IDX_BILLING} end />
              )}
            </Tabs>
            {renderPanel()}
          </>
        )}
      </div>
    </PageLayout>
  );
};

export default Organization;
