import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      flexShrink: 0,
      marginRight: 44,
      marginLeft: 50,
      minWidth: 300,

      [`@media only screen and (max-width: 960px)`]: {
        maxWidth: 'none',
        height: '50%',
        marginTop: 50,
        marginRight: 0,
        marginLeft: 0,
      },
    },

    subheader: {
      fontSize: 20,
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },

    description: {
      fontSize: 15,
      color: theme.palette.text.secondary,
      marginTop: 10,
      maxWidth: 665,
    },

    form: {
      ...theme.scrollbar,
      height: '100%',
      marginTop: 30,
      paddingTop: 5,
      paddingRight: 44,
      overflowX: 'hidden',
      overflowY: 'auto',
    },

    textfield: {
      margin: 0,
      width: '100%',
    },

    formLabel: {
      color: theme.palette.text.primary,
      marginBottom: 10,
    },

    formControlLabel: {
      color: theme.palette.text.primary,
    },

    button: {
      padding: '16px 14px',
      float: 'right',

      [`@media only screen and (max-width: 960px)`]: {
        float: 'none',
      },
    },
  }),
);
