import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  organisationMetadataRoot: {
    marginTop: 10,
  },
  organisationMetadata: {
    marginRight: '5%',
  },
  header: {
    marginBottom: 5,
    fontSize: 14,
  },
  label: {
    fontSize: 15,
  },
}));
