import { shape, string, oneOf } from 'prop-types';

const currentUser = shape({
  id: string,
  email: string,
  firstname: string,
  lastname: string,
  role: oneOf(['USER', 'ADMIN', 'OWNER']),
});

export default {
  currentUser,
};
