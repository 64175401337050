import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Formik, Form } from 'formik';
import Box from '@material-ui/core/Box';

import Button from '../Buttons/Button';
import LoadingButton from '../Buttons/LoadingButton';
import AlertArray from '../Display/AlertArray';
import TextField from '../TextFields/TextField';

import { updateProfileValidation } from '../../validation/updateProfileValidation';
import useStyles from './UpdateProfileStyle';

const defaultInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
};

const UpdateProfile = ({ onSubmit, initialValues = defaultInitialValues }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={updateProfileValidation}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, resetForm }) => (
        <Form method='post'>
          <AlertArray severity='error' alerts={errors.general} />
          <AlertArray severity='info' alerts={values.general} />

          <Box className={classes.nameInputContainer}>
            <Box className={clsx(classes.nameInputLabelContainer, classes.inputMarginEnd)}>
              <TextField
                variant='outlined'
                id='firstName'
                label='First name'
                placeholder='First name'
                value={values.firstName}
                error={touched.firstName && errors.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>

            <Box className={clsx(classes.nameInputLabelContainer, classes.inputMarginStart)}>
              <TextField
                variant='outlined'
                id='lastName'
                label='Last name'
                placeholder='Last name'
                value={values.lastName}
                error={touched.lastName && errors.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>
          </Box>

          {/* Removed for MVP */}
          {/* <TextField */}
          {/*  variant='outlined' */}
          {/*  id='email' */}
          {/*  label='Email' */}
          {/*  placeholder='Email */}
          {/*  value={values.email} */}
          {/*  error={touched.email && errors.email} */}
          {/*  onChange={handleChange} */}
          {/*  onBlur={handleBlur} */}
          {/* /> */}

          <Box className={classes.submitButtonContainer}>
            <Button
              variant='text'
              color='primary'
              onClick={resetForm}
              className={classes.submitButton}
            >
              Cancel
            </Button>

            <LoadingButton
              type='submit'
              name='Submit'
              isLoading={isSubmitting}
              className={classes.submitButton}
            >
              Submit
            </LoadingButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateProfile;

UpdateProfile.propTypes = {
  initialValues: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
};

UpdateProfile.defaultProps = {
  initialValues: defaultInitialValues,
};
