import Fade from '@material-ui/core/Fade';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/ClearRounded';
import { Hidden } from '@material-ui/core';

import React from 'react';
import PropTypes from 'prop-types';
import MaterialTextField from '@material-ui/core/TextField';

import FormTextError from './FormTextError';

const TextField = ({ id, error, onChange, setFieldValue, value, InputProps, noClear, ...rest }) => {
  const clearText = () => {
    onChange({ target: { value: '' } });
    if (setFieldValue) setFieldValue(id, '');
  };

  const clearButton = !noClear && (
    <Hidden smUp>
      <Fade in={!!value}>
        <InputAdornment
          position='end'
          onClick={clearText}
          style={{ cursor: 'pointer' }}
          data-testid='TextField-clearButton'
        >
          <ClearIcon />
        </InputAdornment>
      </Fade>
    </Hidden>
  );

  return (
    <>
      <MaterialTextField
        variant='standard'
        fullWidth
        margin='normal'
        name={id}
        error={!!error}
        InputProps={{ endAdornment: clearButton, ...InputProps }}
        onChange={(e) => onChange(e)}
        value={value}
        {...rest}
      />
      {!!error && <FormTextError error={error} />}
    </>
  );
};

TextField.propTypes = {
  ...MaterialTextField.propTypes,
  error: PropTypes.string,
  setFieldValue: PropTypes.func,
};

// Override any 'required' attributes that have been given a default value
TextField.defaultProps = {
  ...MaterialTextField.defaultProps,
  variant: 'standard',
  setFieldValue: undefined,
};

export default TextField;
