import React from 'react';
import Moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';

import GraphHolder from './GraphHolder';
import Tooltip from './Tooltip';

const buildData = (data) => {
  return _.map(data, (dataObject) => ({
    id: dataObject.version,
    data: [
      ..._.map(dataObject.data, (internalObject) => ({
        x: internalObject.label,
        y: internalObject.value,
      })),
    ],
  }));
};

export const _renderTooltip = (pointData) => {
  const { serieId: versionNumber } = pointData.point;
  const { x: day, y: amount } = pointData.point.data;
  const formattedDay = Moment(day).format('ddd MMM D');

  const toolTipItems = [
    { amount: `${amount} %` },
    { date: formattedDay },
    { versionNumber: `Version: ${versionNumber}` },
  ];

  return <Tooltip tooltipItems={toolTipItems} />;
};

const BarchartMultiLineGraph = ({ data, title }) => {
  const tickBottomValues =
    // eslint-disable-next-line no-nested-ternary
    data.length !== 0 ? (data[0].data.length < 10 ? data[0].data.length : 10) : 1;

  return (
    <GraphHolder title={title}>
      <ResponsiveLine
        data={buildData(data)}
        tooltip={_renderTooltip}
        margin={{ top: 30, right: 90, bottom: 40, left: 50 }}
        xScale={{
          type: 'time',
          format: '%d/%m/%Y',
        }}
        yScale={{ type: 'linear', min: 0, max: 100, stacked: true, reverse: false }}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: 10,
        }}
        axisBottom={{
          format: '%b %d',
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickValues: tickBottomValues,
          tickRotation: 0,
        }}
        colors={['#AF3552', '#FFCE5F', '#FF8900', '#3E72C6', '#46C3AF']}
        useMesh
        enableArea
        enablePoints={false}
        enableCrosshair={false}
        areaOpacity={0.1}
        legends={[
          {
            anchor: 'top-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </GraphHolder>
  );
};

BarchartMultiLineGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};

export default BarchartMultiLineGraph;
