import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    drawer: {
      width: '100pt',
    },
    mainContainerDesktop: {
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: '1px solid #EAEDF3',
      height: theme.headerHeight,
    },
    mainContainerMobile: {
      backgroundColor: 'white',
      display: 'flex',
      minWidth: '300px',
      flexDirection: 'row',
      alignItems: 'space-between',
      borderBottom: '1px solid #EAEDF3',
      height: theme.headerHeight,
    },
    logo: {
      backgroundSize: 'contain',
      width: '105px',
      height: '100%',
      margin: '0pt 15pt',
    },
    appIconContainerDesktop: {
      height: '100%',
      borderRight: '1px solid #EAEDF3',
    },
    appIconContainerMobile: {
      height: '100%',
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
    },
    navItemsContainer: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      flexGrow: 1,
    },
    actionItemsContainer: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    menuButton: {
      display: 'flex',
      width: '55pt',
      marginLeft: '5pt',
      color: '#2F2F4B',
    },
    mobileDateContainer: {
      display: 'flex',
      alignSelf: 'center',
      marginRight: '15pt',
      height: '40pt',
    },
    dividerFullWidth: {
      paddingLeft: '22pt',
      fontSize: 14,
      paddingTop: '20pt',
      paddingBottom: '8pt',
      color: '#747983',
    },
    changeAppDropDownButton: {
      minWidth: 170,
      maxWidth: 170,
      height: 39,
    },
    mobileAppButton: {
      textAlign: 'left',
      justifyContent: 'left',
      paddingLeft: '30pt',
      paddingRight: '22pt',
      fontSize: 18,
      lineHeight: '18pt',
      paddingTop: '8pt',
      paddingBottom: '8pt',
      width: '100%',
      '&:hover': {
        backgroundColor: '#F4F6FA',
      },
    },
    changeAppDropDownMenuItem: {
      textDecoration: 'none',
      textOverflow: 'ellipsis',
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      minWidth: 170,
      maxWidth: 300,
      paddingHorizontal: 16,
      paddingVertical: 8,
    },
    addNewAppButton: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderRadius: 0,
    },
    addNewAppIcon: {
      paddingRight: 15,
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '10px 0px',
      padding: 0,
    },
  }),
);
