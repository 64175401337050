import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import PageLayout from '../../components/Display/PageLayout';
import userOptionsActions from '../../redux/actions/userOptionsActions';
import userOptionsSelector from '../../redux/selectors/userOptionsSelector';
import useActions from '../../redux/actions/useActions';
import EventsOverviewTable from '../../components/Tables/EventsOverviewTable';
import eventsAPI from '../../api/v1/events/events';
import styles from '../sessions/_sessions.module.scss';
import { NO_EVENTS_BODY_MESSAGE, NO_EVENTS_TITLE_MESSAGE } from '../../messages/info';
import hiding from '../../static/media/hiding_squirrel.svg';
import DisplayError from '../../components/Generic/DisplayError';

const Events = () => {
  const { fromDate, toDate, apps, currentAppId } = useSelector(userOptionsSelector);
  const { setSelectedDateRange } = useActions(userOptionsActions);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [eventOverviewData, setEventOverviewData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setError('');
        setLoading(true);
        const payload = await eventsAPI.getEventOverview(currentAppId, fromDate, toDate);
        setEventOverviewData(payload.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [fromDate, toDate]);

  const hasData = eventOverviewData.length > 0;
  return (
    <PageLayout
      title={hasData ? 'Events' : ''}
      highlightTab='Events'
      isLoading={loading}
      error={error}
      fromDate={fromDate}
      apps={apps}
      currentAppId={currentAppId}
      toDate={toDate}
      onDatesSelected={setSelectedDateRange}
      centerContentHorizontal={!hasData}
      centerContentVertical={!hasData}
    >
      {hasData ? (
        <Box className={styles.eventContentContainer}>
          <EventsOverviewTable data={eventOverviewData} />
        </Box>
      ) : (
        <DisplayError
          title={NO_EVENTS_TITLE_MESSAGE}
          description={NO_EVENTS_BODY_MESSAGE}
          image={hiding}
        />
      )}
    </PageLayout>
  );
};

export default Events;
