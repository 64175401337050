import cookie from 'js-cookie';
import { store } from '../../redux/store';
import authRoutes from './authRoutes';

const { loginRoute, dashboardRoute } = authRoutes;

export const login = ({ token, history } = {}) => {
  console.log('Logging in user...');

  cookie.set('token', token, { expires: 1 });

  if (history) history.replace(dashboardRoute);
};

export const logout = ({ history, windowRedirect } = {}) => {
  console.log('Logging out user...');
  store.dispatch({ type: 'LOGOUT' });

  sessionStorage.clear();
  localStorage.clear();
  cookie.remove('token');

  if (history) {
    history.replace(loginRoute);
  } else if (windowRedirect) {
    window.location.replace('/');
  }
};
