import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import SubHeader from '../Text/SubHeader';
import styles from './GraphHolderStyle';

const GraphHolder = ({ children, title }) => {
  const classes = styles();
  return (
    <Grid container component={Paper} direction='row' className={classes.container} elevation={0}>
      <Grid item className={classes.headerRow} xs={12}>
        <SubHeader className={classes.subheader}>{title}</SubHeader>
      </Grid>
      <Grid item className={classes.graphContainer}>
        {children}
      </Grid>
    </Grid>
  );
};

GraphHolder.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default GraphHolder;
