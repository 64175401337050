import PropTypes from 'prop-types';
import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useStyles from './PanelHeaderStyle';

const PanelHeader = ({ title, rightComponent }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant='h5' className={classes.title}>
        {title}
      </Typography>
      {rightComponent}
    </Box>
  );
};

export default PanelHeader;

PanelHeader.propTypes = {
  title: PropTypes.string.isRequired,
  rightComponent: PropTypes.node,
};

PanelHeader.defaultProps = {
  rightComponent: null,
};
