import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import SubHeader from '../Text/SubHeader';
import { getFacts } from '../../utils/generator/factsGenerator';
import styles from './LoadingAnimationStyles';
import Text from '../Text/Text';
import CustomLoadingSpinner from './CustomLoadingSpinner';

const LoadingAnimation = ({ height, width, className }) => {
  const { paperContainer, gridContainer, factTextStyle, questionStyle } = styles();
  return (
    <Paper className={paperContainer} data-testid='LoadingAnimation'>
      <Grid className={gridContainer} container>
        <Grid item xs={12} md={12}>
          <CustomLoadingSpinner
            className={className}
            height={height}
            width={width}
            style={{ height, width }}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ paddingTop: 40 }} data-testid='loadingAnimation_title'>
          <SubHeader className={questionStyle}>Did you know?</SubHeader>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{ paddingTop: 20 }}
          data-testid='loadingAnimation_subtitle'
        >
          <Text className={factTextStyle}>{getFacts()}</Text>
        </Grid>
      </Grid>
    </Paper>
  );
};

LoadingAnimation.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  className: PropTypes.string,
};

LoadingAnimation.defaultProps = {
  className: undefined,
};

export default LoadingAnimation;
