import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import { SwipeableDrawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

import styles from './TopNavigationStyle';
import logo from '../../../static/media/logo.svg';
import { logout } from '../../../utils/auth/auth';
import RangeDatePickerPopover from '../../Pickers/RangeDatePickerPopover';
import MobileNavButton from './MobileNavButton';
import Loading from '../../Spinners/LoadingSpinner';
import appsTypes from '../../../utils/types/apps';

const changeApp = (e, setDrawerOpen, setLocalAppId, app) => {
  e.preventDefault();
  setLocalAppId(app.id);
};

const TopMobileNavigation = ({
  fromDate,
  toDate,
  onDatesSelected,
  highlightTab,
  currentAppId,
  apps,
  loading,
  setLocalAppId,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = styles();

  useEffect(() => {
    setDrawerOpen(false);
  }, [currentAppId]);

  const renderAppLogo = () => (
    <Link to='/' className={classes.appIconContainerMobile} data-testid='TopNavigation-app-icon'>
      <CardMedia image={logo} className={classes.logo} data-testid='contentMissing' />
    </Link>
  );

  const mobileDrawer = () => (
    <SwipeableDrawer
      className={classes.drawer}
      anchor='left'
      open={drawerOpen}
      onOpen={() => setDrawerOpen(true)}
      onClose={() => setDrawerOpen(false)}
    >
      <MobileNavButton key='dashboard' label='Dashboard' page='/' activePage={highlightTab} />
      <MobileNavButton key='session' label='Sessions' page='/sessions' activePage={highlightTab} />
      <MobileNavButton key='crashes' label='Crashes' page='/crashes' activePage={highlightTab} />
      <MobileNavButton key='events' label='Events' page='/events' activePage={highlightTab} />
      <MobileNavButton key='funnels' label='Funnels' page='/funnels' activePage={highlightTab} />
      <MobileNavButton
        key='organization'
        label='Organization'
        page='/organization'
        activePage={highlightTab}
      />
      <Divider />

      <Typography
        className={classes.dividerFullWidth}
        color='textSecondary'
        display='block'
        variant='caption'
      >
        Apps
      </Typography>

      {loading ? (
        <Container className={classes.loadingContainer}>
          <Loading size={25} color='primary' />
        </Container>
      ) : (
        apps.map((app) => (
          <Button
            key={app.id}
            className={classes.mobileAppButton}
            onClick={(e) => changeApp(e, setDrawerOpen, setLocalAppId, app)}
          >
            {app.appName}
          </Button>
        ))
      )}

      <Divider />
      <MobileNavButton key='sign_out' label='Sign out' onClick={logout} activePage={false} />
    </SwipeableDrawer>
  );

  return (
    <div className={classes.mainContainerMobile} data-testid='TopNavigation-mobile-container'>
      <IconButton
        className={classes.menuButton}
        color='inherit'
        data-testid='TopNavigation-mobile-open-drawer'
        onClick={() => setDrawerOpen(true)}
      >
        <MenuIcon />
      </IconButton>
      {renderAppLogo()}
      <div className={classes.mobileDateContainer}>
        <RangeDatePickerPopover
          fromDate={fromDate}
          toDate={toDate}
          onDatesSelected={onDatesSelected}
        />
      </div>
      {mobileDrawer()}
    </div>
  );
};

TopMobileNavigation.propTypes = {
  fromDate: PropTypes.instanceOf(Date).isRequired,
  toDate: PropTypes.instanceOf(Date).isRequired,
  onDatesSelected: PropTypes.func.isRequired,
  highlightTab: PropTypes.string.isRequired,
  apps: appsTypes.apps.isRequired,
  currentAppId: PropTypes.string.isRequired,
  setLocalAppId: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TopMobileNavigation;
