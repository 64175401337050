import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import useStyles from './SidebarStyle';

const Sidebar = ({ title, children }) => {
  const [open, setOpen] = useState(true);
  const toggleOpen = () => setOpen(!open);

  const classes = useStyles();
  return (
    <Box
      className={clsx(classes.root, {
        [classes.rootOpen]: open,
      })}
    >
      <Box onClick={toggleOpen} className={classes.header} data-testid='sidebar-header'>
        <TuneRoundedIcon className={classes.menuIcon} color='primary' />

        <Box
          className={clsx(classes.titleContainer, {
            [classes.visible]: open,
            [classes.hidden]: !open,
          })}
        >
          {title}
        </Box>

        {open && (
          <ArrowBackIosRoundedIcon
            className={classes.menuIconRight}
            color='primary'
            fontSize='small'
          />
        )}
      </Box>
      <Box
        className={[
          clsx({
            [classes.visible]: open,
            [classes.hidden]: !open,
          }),
        ]}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
};

Sidebar.defaultProps = {
  children: undefined,
  title: '',
};
