import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import DayPicker, { DateUtils } from 'react-day-picker';
import NavBar from './SubComponents/NavBar';

const isSelectingFirstDay = (from, to) => {
  const isRangeSelected = from && to;
  return !from || isRangeSelected;
};

const getModifiers = (enteredTo, fromDate) => {
  if (enteredTo && fromDate && DateUtils.isDayBefore(enteredTo, fromDate)) {
    return { start: enteredTo, end: fromDate };
  }

  return { start: fromDate, end: enteredTo };
};

const RangeDatePicker = ({
  fromDate: fromDateInitial,
  toDate: toDateInitial,
  onDatesSelected,
  numberOfMonths,
  toMonth,
  disabledDays,
}) => {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [enteredTo, setEnteredTo] = useState();
  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59, 59);

  useEffect(() => {
    if (fromDateInitial) fromDateInitial.setHours(0, 0, 0, 0);
    if (toDateInitial) toDateInitial.setHours(23, 59, 59);

    setFromDate(fromDateInitial);
    setToDate(toDateInitial);
    setEnteredTo(toDateInitial);
  }, [fromDateInitial, toDateInitial]);

  const resetDates = () => {
    setFromDate(undefined);
    setToDate(undefined);
    setEnteredTo(undefined);
  };

  const handleDayClick = (day) => {
    if (day > endOfDay) {
      return;
    }

    if (fromDate && toDate && day >= fromDate && day <= toDate) {
      resetDates();
      return;
    }

    if (isSelectingFirstDay(fromDate, toDate)) {
      day.setHours(0, 0, 0, 0);

      setFromDate(day);
      setToDate(undefined);
      setEnteredTo(undefined);
      return;
    }

    if (DateUtils.isDayBefore(day, fromDate)) {
      day.setHours(0, 0, 0, 0);
      fromDate.setHours(23, 59, 59);

      onDatesSelected({ fromDate: day, toDate: fromDate });
    } else {
      day.setHours(23, 59, 59);
      fromDate.setHours(0, 0, 0, 0);

      onDatesSelected({ fromDate, toDate: day });
    }
  };

  const handleDayMouseEnter = (day) => {
    if (!isSelectingFirstDay(fromDate, toDate)) {
      setEnteredTo(day);
    }
  };

  const modifiers = getModifiers(enteredTo, fromDate);
  const selectedDays = [fromDate, { from: fromDate, to: enteredTo }];

  return (
    <DayPicker
      className='Range'
      numberOfMonths={numberOfMonths}
      selectedDays={selectedDays}
      modifiers={modifiers}
      navbarElement={<NavBar />}
      toMonth={toMonth}
      onDayClick={handleDayClick}
      onDayMouseEnter={handleDayMouseEnter}
      disabledDays={disabledDays}
    />
  );
};

RangeDatePicker.propTypes = {
  disabledDays: DayPicker.propTypes.disabledDays,
  toMonth: DayPicker.propTypes.toMonth,
  numberOfMonths: DayPicker.propTypes.numberOfMonths,
  onDatesSelected: PropTypes.func.isRequired,
  fromDate: PropTypes.instanceOf(Date),
  toDate: PropTypes.instanceOf(Date),
};

RangeDatePicker.defaultProps = {
  disabledDays: undefined,
  fromDate: undefined,
  numberOfMonths: 2,
  toDate: undefined,
  toMonth: undefined,
};

export default RangeDatePicker;
