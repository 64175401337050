import React from 'react';
import PropTypes from 'prop-types';

import { Fade, Paper, Grid, Typography, IconButton } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { Alert, AlertTitle } from '@material-ui/lab';

import Button from '../Buttons/Button';
import LoadingButton from '../Buttons/LoadingButton';
import styles from './GenericModalStyles';

const BasicFormModal = ({
  title,
  info,
  controls,
  cancelButtonText,
  confirmButtonText,
  onCancel,
  onConfirm,
  cancelButtonDisabled,
  confirmButtonDisabled,
  cancelButtonAccessibilityLabel,
  confirmButtonAccessibilityLabel,
  onClose,
  errorMessage,
  successMessage,
  isLoading,
}) => {
  const classes = styles();

  const renderFormControls = () =>
    !!controls &&
    controls.map((control) => {
      return (
        <Grid item xs={12} key={control.props.placeholder}>
          {React.cloneElement(control, {
            className: classes.formControl,
            'data-testid': 'BasicFormModal-formControl',
          })}
        </Grid>
      );
    });

  const renderErrorMessage = () => (
    <Grid item xs={12}>
      <Alert severity='error' data-testid='BasicFormModal-errorMessage'>
        <AlertTitle>Error</AlertTitle>
        {errorMessage}
      </Alert>
    </Grid>
  );

  const renderSuccessMessage = () => (
    <Grid item xs={12}>
      <Alert severity='success' data-testid='BasicFormModal-successMessage'>
        <AlertTitle>Success</AlertTitle>
        {successMessage}
      </Alert>
    </Grid>
  );

  const renderFormActionButtons = () => (
    <>
      <Grid item xs={12} md={6}>
        <Button
          onClick={onCancel}
          disabled={cancelButtonDisabled}
          variant='outlined'
          className={classes.formActionButton}
          aria-label={cancelButtonAccessibilityLabel}
          data-testid='BasicFormModal-cancelButton'
        >
          {cancelButtonText}
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <LoadingButton
          onClick={onConfirm}
          isLoading={isLoading}
          disabled={!!confirmButtonDisabled}
          spinnerSize={26}
          className={classes.formActionButton}
          aria-label={confirmButtonAccessibilityLabel}
          data-testid='BasicFormModal-confirmButton'
        >
          {confirmButtonText}
        </LoadingButton>
      </Grid>
    </>
  );

  const renderForm = () => (
    <Grid container item xs={12} className={classes.formContainer}>
      <Grid container item spacing={2} xs={10} md={8}>
        <Grid item xs={12}>
          <Typography variant='body1' className={classes.formTitle}>
            {title}
          </Typography>
        </Grid>
        {info && (
          <Grid item xs={12}>
            <Typography
              variant='body1'
              color='textSecondary'
              className={classes.formInfoText}
              data-testid='BasicFormModal-info'
            >
              {info}
            </Typography>
          </Grid>
        )}
        {renderFormControls()}
        {errorMessage && renderErrorMessage()}
        {successMessage && renderSuccessMessage()}
        {renderFormActionButtons()}
      </Grid>
    </Grid>
  );

  return (
    <Fade in>
      <Grid item xs={10} md={5} className={classes.modalContainer}>
        <Paper className={classes.modal} data-testid='BasicFormModal'>
          <Grid container>
            <IconButton
              onClick={onClose}
              color='primary'
              className={classes.closeButton}
              aria-label='close modal'
              data-testid='BasicFormModal-closeIcon'
            >
              <CloseRoundedIcon />
            </IconButton>
            {renderForm()}
          </Grid>
        </Paper>
      </Grid>
    </Fade>
  );
};

BasicFormModal.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string,
  controls: PropTypes.arrayOf(PropTypes.node),
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancelButtonDisabled: PropTypes.bool,
  confirmButtonDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  cancelButtonAccessibilityLabel: PropTypes.string,
  confirmButtonAccessibilityLabel: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  isLoading: PropTypes.bool,
};

BasicFormModal.defaultProps = {
  info: null,
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Confirm',
  cancelButtonDisabled: false,
  confirmButtonDisabled: false,
  cancelButtonAccessibilityLabel: 'cancel',
  confirmButtonAccessibilityLabel: 'confirm',
  controls: [],
  errorMessage: null,
  successMessage: null,
  isLoading: false,
};

export default BasicFormModal;
