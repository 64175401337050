import axios from 'axios';
import { useEffect, useState } from 'react';
import sessionsAPI from '../api/v1/sessions/sessions';
import { mapSessionTimeline } from '../utils/mappers/sessionMappers';

const cancelToken = axios.CancelToken;

// eslint-disable-next-line import/prefer-default-export
export const useSessionDetails = ({ sessionId }) => {
  const [error, setError] = useState('');
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [sessionDetails, setSessionDetails] = useState(undefined);

  useEffect(() => {
    if (!sessionId) {
      return () => {};
    }

    const cancellationToken = cancelToken.source();
    setLoadingDetails(true);

    (async () => {
      try {
        const { data } = await sessionsAPI.getSessionTimeline({
          id: sessionId,
          cancellationToken,
        });
        setSessionDetails(mapSessionTimeline(data));
        setError('');
      } catch (e) {
        if (axios.isCancel(e)) return;

        console.error('Unable to retrieve session timeline!', e);
        setSessionDetails(undefined);
        setError('Error fetching timeline!');
      } finally {
        setLoadingDetails(false);
      }
    })();

    return () => {
      cancellationToken.cancel();
    };
  }, [sessionId]);

  return {
    error,
    loadingDetails,
    sessionDetails,
  };
};
