import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default makeStyles((theme) =>
  createStyles({
    textStyle: {
      fontWeight: 600,
      fontSize: '24pt',
      color: theme.palette.primary.dark,
    },
  }),
);
