import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import SubHeader from '../../../components/Text/SubHeader';
import SavedFunnelButton from './savedFunnelButton';

const useStyle = makeStyles((theme) =>
  createStyles({
    containerStyle: {
      marginBottom: 10,
    },
    savedFunnelsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    subHeaderText: {
      fontSize: 18,
      marginBottom: 8,
      color: theme.palette.text.primary,
    },
  }),
);

const YourFunnels = ({ savedFunnels, onFunnelGenerate, onDeleteFunnel }) => {
  const styles = useStyle();
  if (savedFunnels.length === 0) return null;
  return (
    <Box className={styles.containerStyle}>
      <SubHeader className={styles.subHeaderText}>Your Funnels</SubHeader>
      <Box className={styles.savedFunnelsContainer}>
        {savedFunnels.map((funnel) => (
          <SavedFunnelButton
            key={funnel.id}
            onDelete={() => onDeleteFunnel(funnel.id)}
            onClick={() => onFunnelGenerate(JSON.parse(funnel.query))}
            label={funnel.funnelName}
            data-testid='saved-funnel-button'
          />
        ))}
      </Box>
    </Box>
  );
};

export default YourFunnels;

YourFunnels.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  savedFunnels: PropTypes.array.isRequired,
  onFunnelGenerate: PropTypes.func.isRequired,
  onDeleteFunnel: PropTypes.func.isRequired,
};
