import { shape, number, string, arrayOf, array, object } from 'prop-types';

const timelineItem = shape({
  id: string.isRequired,
  label: string.isRequired,
  type: string.isRequired,
  timestamp: string.isRequired,
  properties: object,
  events: array,
});

const timeline = arrayOf(timelineItem);

const sessionDetail = shape({
  id: string.isRequired,
  timestamp: string.isRequired,
  endTimestamp: string.isRequired,
  deviceId: string.isRequired,
  number: number.isRequired,
});

const sessionMetadata = shape({
  id: string.isRequired,
  username: string.isRequired,
  session: number.isRequired,
  screenCount: number.isRequired,
  eventCount: number.isRequired,
  crashCount: number.isRequired,
  sessionStart: string.isRequired,
  duration: string.isRequired,
});

export default {
  timeline,
  timelineItem,
  sessionDetail,
  sessionMetadata,
};
