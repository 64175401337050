import React from 'react';
import PropTypes from 'prop-types';
import CardMedia from '@material-ui/core/CardMedia';
import squirrelLoading from '../../static/media/loading_nuts.gif';

const CustomLoadingSpinner = ({ height, width, className }) => (
  <CardMedia
    className={className}
    image={squirrelLoading}
    style={{ height, width }}
    data-testid='loadingAnimation'
  />
);

CustomLoadingSpinner.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  className: PropTypes.string,
};

CustomLoadingSpinner.defaultProps = {
  className: undefined,
};

export default CustomLoadingSpinner;
