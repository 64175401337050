import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

import useStyles from './TabPanelStyle';

const TabPanel = ({ currentIndex, panelIndex, children, className, 'data-testid': testId }) => {
  const classes = useStyles();

  if (currentIndex !== panelIndex) {
    return null;
  }

  return (
    <Box className={clsx(classes.root, className)} data-testid={testId}>
      {children}
    </Box>
  );
};

export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  currentIndex: PropTypes.number.isRequired,
  panelIndex: PropTypes.number.isRequired,
  'data-testid': PropTypes.string,
};

TabPanel.defaultProps = {
  children: undefined,
  className: undefined,
  'data-testid': undefined,
};
