import React from 'react';
import MaterialButton from '@material-ui/core/Button';

const Button = ({ ...rest }) => (
  <MaterialButton type='button' variant='contained' color='primary' {...rest} />
);

export default Button;

Button.propTypes = {
  ...MaterialButton.propTypes,
};
