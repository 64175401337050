import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
import { Hidden } from '@material-ui/core';

import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AlertArray from '../../components/Display/AlertArray';
import PasswordField from '../../components/TextFields/PasswordField';
import TextField from '../../components/TextFields/TextField';
import { signInValidation } from '../../validation/userAuthValidation';
import { useSignInCallback } from '../../hooks/authHooks';
import LoadingButton from '../../components/Buttons/LoadingButton';
import { useQuery } from '../../hooks/queryHooks';

import basicSquirrel from '../../static/media/Squirrel basic.svg';
import logo from '../../static/media/logo.svg';

import classes from './_auth.module.scss';

const initialValues = { emailAddress: '', password: '' };

const SignIn = () => {
  const query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const onSubmit = useSignInCallback(history, setLoading);
  const redirectMessage = history.location.state?.message;
  const queryMessage = query.get('message');

  return (
    <Grid container className={classes.root}>
      <Hidden smDown>
        <Grid item md={6}>
          <Container className={classes.leftGridContainer}>
            <CardMedia
              image={basicSquirrel}
              className={classes.squirrelLogo}
              data-testid='signInLogo'
            />
          </Container>
        </Grid>
      </Hidden>
      <Grid item md={6} xs={12} className={classes.rightGrid}>
        <Container className={classes.rightGridContainer}>
          <CardMedia image={logo} className={classes.forageLogo} data-testid='contentMissing' />

          <Typography variant='h6' align='left' color='textPrimary'>
            Welcome back to Forage. Please sign in to see your latest data.
          </Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={signInValidation}
            onSubmit={onSubmit}
            validateOnChange={false}
          >
            {({ values, errors, handleChange, setFieldValue, handleBlur }) => (
              <Form method='post'>
                <AlertArray severity='info' alerts={[redirectMessage]} />
                <AlertArray severity='warning' alerts={[queryMessage]} />
                <AlertArray severity='error' alerts={errors.general} />
                <AlertArray severity='info' alerts={values.general} />

                <TextField
                  variant='outlined'
                  required
                  id='emailAddress'
                  label='Email'
                  autoComplete='email'
                  value={values.emailAddress}
                  error={errors.emailAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />

                <PasswordField
                  required
                  id='password'
                  label='Password'
                  value={values.password}
                  error={errors.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <LoadingButton
                  isLoading={loading}
                  className={classes.loadingButton}
                  type='submit'
                  fullWidth
                  variant='contained'
                  id='submit'
                >
                  Sign In
                </LoadingButton>

                <Link id='signup' to='/auth/signUp' className={classes.signUpLink}>
                  <Typography variant='body1' align='center' display='inline' color='textSecondary'>
                    Don&apos;t have an account?
                  </Typography>

                  <Typography
                    variant='body1'
                    align='center'
                    display='inline'
                    className={classes.signUpUnderLine}
                  >
                    Sign Up
                  </Typography>
                </Link>
              </Form>
            )}
          </Formik>
        </Container>
      </Grid>
    </Grid>
  );
};

export default SignIn;
