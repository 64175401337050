import React from 'react';
import PropTypes from 'prop-types';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Text from '../../../components/Text/Text';

const useStyle = makeStyles((theme) =>
  createStyles({
    containerStyle: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'white',
      marginRight: 10,
      marginLeft: 0,
      border: '1px solid',
      borderRadius: 100,
      borderColor: theme.palette.common.borderColor,
      padding: 3,
      paddingLeft: 13,
      paddingRight: 10,
      marginTop: 2,
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    iconButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      margin: 0,
      marginLeft: 8,
    },
    iconStyle: {
      fontSize: 14,
      marginBottom: 1.7,
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
    textStyle: {
      fontSize: 12,
      verticalAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const SavedFunnelButton = ({ onClick, label, onDelete }) => {
  const styles = useStyle();
  return (
    <Button onClick={onClick} className={styles.containerStyle}>
      <Text className={styles.textStyle}>{label}</Text>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          onDelete();
        }}
        className={styles.iconButtonContainer}
        data-testid='delete-button'
      >
        <HighlightOffIcon className={styles.iconStyle} />
      </IconButton>
    </Button>
  );
};

export default SavedFunnelButton;

SavedFunnelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};
