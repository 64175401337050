import { createStyles, makeStyles } from '@material-ui/core/styles';

const eventsWidth = '400px';
const rowHeight = 50;
const horizontalPadding = 20;
const hoverItemColor = '#fafafa';
const selectedItemColor = '#dbdbdb';

export default makeStyles((theme) =>
  createStyles({
    column: {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.common.borderColor}`,
      borderRadius: '4px',
      marginRight: '40px',
    },
    propertyNameSearch: {
      padding: `0 ${horizontalPadding}px`,

      '& input': {
        padding: '10pt 0',
        fontWeight: 'bold',
      },
    },
    propertiesContainer: {
      height: ({ propertiesCount }) => Math.max(rowHeight, propertiesCount * rowHeight),
      minWidth: eventsWidth,
    },
    list: {
      ...theme.scrollbar,

      '&:focus': {
        outline: 'none',
      },
    },
    propertyRow: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      cursor: 'pointer',
      backgroundColor: '#00000000',
      justifyContent: 'center',
      alignItems: 'center',

      '& .iconContainer': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: '10px',
        opacity: 0,
      },

      '&:hover': {
        backgroundColor: hoverItemColor,
      },

      '&.selected': {
        backgroundColor: selectedItemColor,
      },

      '&:hover .iconContainer': {
        opacity: 1,
      },
      '&.selected .iconContainer': {
        opacity: 1,
      },
    },
    propertyText: {
      flex: 1,
      marginLeft: `${horizontalPadding}px`,
      lineHeight: '1.1',
    },
    noProperties: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minWidth: eventsWidth,
      alignItems: 'center',
      justifyContent: 'center',
    },
    noPropertiesIcon: {
      width: '50%',
      height: '100%',
      backgroundSize: 'contain',
    },
    loading: {
      minWidth: eventsWidth,
      backgroundSize: 'contain',
    },
  }),
);
