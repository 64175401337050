import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    textStyle: {
      color: theme.palette.text.secondary,
      fontSize: 12,
      letterSpacing: 0.75,
    },
  }),
);
