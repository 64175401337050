import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    caption: {
      paddingTop: '5px',
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
    value: {
      fontSize: 23,
      color: theme.palette.text.primary,
    },
    boldValue: {
      fontSize: 26,
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  }),
);
