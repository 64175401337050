import React, { useState } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Box, TableRow, Typography } from '@material-ui/core';

import styles from './BillingHistoryInvoiceItemStyle';

const BillingHistoryInvoiceItem = ({ invoice, isHeading = false }) => {
  const [hovered, setHovered] = useState(false);

  const classes = styles();

  const onMouseEnter = () => {
    if (isHeading) return;
    setHovered(true);
  };

  const onMouseLeave = () => {
    if (isHeading) return;
    setHovered(false);
  };

  const renderTableCellWithText = (text: string): React.ReactElement => (
    <Box className={clsx(classes.tableCell, { hovered })}>
      <Typography variant='body1' className={classes.textItem}>
        {text}
      </Typography>
    </Box>
  );

  return (
    <TableRow
      component={Box}
      className={clsx(classes.base, {
        [classes.root]: isHeading,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={isHeading ? 'BillingHistoryInvoiceItem_heading' : 'BillingHistoryInvoiceItem'}
    >
      {isHeading ? (
        ['Date', 'Free Sessions', 'Paid Sessions', 'Amount', 'Status', ''].map((heading) =>
          renderTableCellWithText(heading),
        )
      ) : (
        <>
          <Box className={clsx(classes.tableCell, { hovered })}>
            <Typography
              variant='body1'
              className={[classes.textItem]}
              data-testid='BillingHistoryInvoiceItem_date'
            >
              {Moment(invoice.created).format('DD MMM YYYY')}
            </Typography>
          </Box>
          <Box className={clsx(classes.tableCell, { hovered })}>
            <Typography
              variant='body1'
              className={classes.textItem}
              data-testid='BillingHistoryInvoiceItem_freeSessionsUsedCount'
            >
              {invoice.freeSessionsUsed}
            </Typography>
          </Box>
          <Box className={clsx(classes.tableCell, { hovered })}>
            <Typography
              variant='body1'
              className={classes.textItem}
              data-testid='BillingHistoryInvoiceItem_paidSessionsUsedCount'
            >
              {invoice.paidSessionsUsed}
            </Typography>
          </Box>
          <Box className={clsx(classes.tableCell, { hovered })}>
            <Typography
              variant='body1'
              className={[classes.textItem, classes.amountText]}
              data-testid='BillingHistoryInvoiceItem_amountDue'
            >
              {`$${invoice.amountDue}`}
            </Typography>
          </Box>
          <Box className={clsx(classes.tableCell, { hovered })}>
            <Typography
              variant='body1'
              className={[classes.textItem, !invoice.paid && classes.statusTextUnpaid]}
              data-testid='BillingHistoryInvoiceItem_paidStatus'
            >
              {invoice.status.toString().toUpperCase()}
            </Typography>
          </Box>
          <Box className={clsx(classes.tableCell, { hovered })}>
            <Typography variant='body1' className={classes.textItem}>
              <a
                href={invoice.hostedInvoiceUrl}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.viewInvoiceText}
                data-testid='BillingHistoryInvoiceItem_viewInvoice'
              >
                View
              </a>
            </Typography>
          </Box>
        </>
      )}
    </TableRow>
  );
};

BillingHistoryInvoiceItem.propTypes = {
  invoice: PropTypes.objectOf(PropTypes.object),
  isHeading: PropTypes.bool,
};

BillingHistoryInvoiceItem.defaultProps = {
  invoice: null,
  isHeading: false,
};

export default BillingHistoryInvoiceItem;
