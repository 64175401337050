import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';

import SubHeader from '../Text/SubHeader';
import styles from './StatCounterStyle';
import { numberWithCommas } from '../../utils/general/generalFunctions';

const StatCounter = ({ label, value, image, percentChange, imageHeight }) => {
  const classes = styles();
  const increase = percentChange > 0;
  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container className={classes.container}>
        <Grid container>
          <Grid item className={classes.gridTopLeft}>
            <Typography variant='subtitle1' color='textSecondary' className={classes.textStyle}>
              {label}
            </Typography>
          </Grid>
          {!!percentChange && (
            <Grid item className={classes.gridTopRight}>
              <Typography
                variant='subtitle1'
                className={clsx(classes.textStyle, classes.gridTopRight, {
                  [classes.percentile]: increase,
                  [classes.percentileNegative]: !increase,
                })}
              >
                {increase ? (
                  <ArrowUpwardRoundedIcon className={classes.icon} aria-label='increase' />
                ) : (
                  <ArrowDownwardRoundedIcon className={classes.icon} aria-label='decrease' />
                )}
                {`${Math.abs(percentChange)}% ${increase ? 'growth' : 'loss'}`}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item className={classes.gridBottomLeft}>
            <SubHeader className={classes.subheader}>
              {/* eslint-disable-next-line no-restricted-globals */}
              {isNaN(value) ? value : numberWithCommas(value)}
            </SubHeader>
          </Grid>
          {image && (
            <Grid item className={classes.gridBottomRight}>
              <CardMedia
                image={image}
                aria-label='stat counter image'
                className={classes.image}
                style={{ height: imageHeight }}
                data-testid='statCounterImage'
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

StatCounter.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  imageHeight: PropTypes.number,
  percentChange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

StatCounter.defaultProps = {
  value: 0,
  image: undefined,
  imageHeight: 0,
  percentChange: 0,
};

export default StatCounter;
