import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '../TextFields/TextField';
import BasicFormModal from './BasicFormModal';
import messages from '../../messages/info';
import appTypes from '../../utils/types/apps';
import AppsApi from '../../api/v1/app/app';
import useNotifications from '../../contexts/notificationContext';

const EditAppNameModal = ({ app, onModalClose }) => {
  const [inputValue, setInputValue] = useState(app.appName);
  const [isLoading, setIsLoading] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState(null);
  const { addSuccessNotification, addErrorNotification } = useNotifications();

  const onInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value);

    if (value.length > 255) {
      setInputErrorMessage('App name cannot be greater than 255 characters long');
    } else {
      setInputErrorMessage(null);
    }
  };

  const onConfirmButtonPress = async () => {
    setIsLoading(true);

    try {
      await AppsApi.editAppName(app.id, inputValue);
      setInputErrorMessage(null);
      addSuccessNotification('App name changed successfully');
      onModalClose(true);
    } catch (err) {
      addErrorNotification(err.message);
      setIsLoading(false);
    }
  };

  const formControls = [
    <TextField
      value={inputValue}
      onChange={onInputChange}
      placeholder='Enter your app name to confirm'
      helperText={inputErrorMessage}
      variant='outlined'
      data-testid='input-app-name'
    />,
  ];

  return (
    <BasicFormModal
      title='Edit App Name'
      info={messages.EDIT_APP_NAME_INFO}
      controls={formControls}
      confirmButtonText='Save new name'
      confirmButtonDisabled={!inputValue.length || inputErrorMessage}
      onCancel={onModalClose}
      onConfirm={onConfirmButtonPress}
      onClose={onModalClose}
      isLoading={isLoading}
    />
  );
};

EditAppNameModal.propTypes = {
  app: appTypes.app.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default EditAppNameModal;
