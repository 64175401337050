import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Box, Divider, Typography } from '@material-ui/core';

import PanelHeader from '../PanelHeader';
import TitledText from '../../Text/TitledText';
import SubHeader from '../../Text/SubHeader';
import BillingHistoryInvoiceItem from './BillingHistoryInvoiceItem';
import ValueWithCaption from '../ValueWithCaption';
import Loading from '../../Spinners/LoadingSpinner';

import organisationApi from '../../../api/v1/organisation/organisations';

import useStyles from './BillingStyle';

const Billing = ({ setError }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const [paidSessions, setPaidSessions] = useState(0);
  const [freeSessions, setFreeSessions] = useState(0);
  const [freeSessionsCap, setFreeSessionsCap] = useState(0);
  const [sessionCost, setSessionCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [billingType, setBillingType] = useState('');
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
  const [nextPayment, setNextPayment] = useState(null);

  const formatDate = (date) => moment(date).format('ddd DD MMM, YYYY');

  useEffect(() => {
    (async () => {
      try {
        setError('');
        setLoading(true);

        const payload = await organisationApi.getBillingData();
        const {
          freeSessionsAmount,
          freeSessionsUsed,
          paidSessionsUsed,
          costPerSession,
          currentMonthlyCost,
          invoices: orgInvoices,
          subscriptionStart,
          billingType: orgBillingType,
          nextPaymentDate,
        } = payload.data;

        setFreeSessionsCap(freeSessionsAmount);
        setFreeSessions(freeSessionsUsed);
        setPaidSessions(paidSessionsUsed);
        setSessionCost(costPerSession);
        setTotalCost(currentMonthlyCost);
        setInvoices(orgInvoices && orgInvoices.length > 0 ? orgInvoices : null);
        setBillingType(orgBillingType);
        setSubscriptionStartDate(formatDate(subscriptionStart));
        setNextPayment(formatDate(nextPaymentDate));
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const renderSubscriptionInfo = () => (
    <>
      <Typography variant='h3' className={classes.sectionText}>
        {`In your subscription you receive ${freeSessionsCap} sessions for free every month.`}
        <br />
        After that, the payment is calculated based on your usage.
      </Typography>
      <Box className={classes.subscriptionInfo}>
        <ValueWithCaption value={freeSessions} caption='Free Sessions' />
        <Divider orientation='vertical' />
        <ValueWithCaption value={paidSessions} caption='Paid Sessions' />
        <ValueWithCaption value='*' />
        <ValueWithCaption value={`$ ${sessionCost}`} caption='Cost per Session' />
        <ValueWithCaption value='=' />
        <ValueWithCaption value={`$ ${totalCost}`} caption='Current Monthly Cost' bold />
      </Box>
    </>
  );

  if (loading) return <Loading />;

  return (
    <Box className={classes.root}>
      <PanelHeader title='Billing Details' />
      <Box className={classes.billingDetailsContainer}>
        <TitledText
          title='Subscription Start'
          text={subscriptionStartDate}
          containerClass={classes.billingDetailsItem}
        />
        <TitledText
          title='Billing Type'
          text={billingType}
          containerClass={classes.billingDetailsItem}
        />
        <TitledText
          title='Next Payment'
          text={nextPayment}
          containerClass={classes.billingDetailsItem}
        />
      </Box>

      <Divider className={classes.dividerHorizontal} />

      <Box className={classes.mainContent}>
        <Box className={classes.section}>
          <SubHeader className={classes.sectionHeader}>Subscription</SubHeader>
          {renderSubscriptionInfo()}
        </Box>

        <Divider orientation='horizontal' />

        <Box className={classes.billingHistorySection}>
          <SubHeader className={classes.sectionHeader}>Billing History</SubHeader>
          {invoices ? (
            <Box className={classes.root} data-testid='billingHistoryList'>
              <BillingHistoryInvoiceItem isHeading />
              {invoices?.map((invoice) => (
                <BillingHistoryInvoiceItem invoice={invoice} key={invoice.id} />
              ))}
            </Box>
          ) : (
            <Typography variant='h3' className={classes.sectionText}>
              You currently have no invoices. Check back on your next payment date.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Billing.propTypes = {
  setError: PropTypes.func.isRequired,
};

export default Billing;
