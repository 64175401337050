import apiUrls from '../../apiUrls';
import { HTTP } from '../../index';
import type NewUserBody from './types/NewUserBody.type';

const {
  ORGANISATION_OVERVIEW,
  ORGANISATION_BILLING,
  ADD_NEW_USER,
  SEND_NEW_USER_CONFIRMATION,
} = apiUrls;

const getOrganisationOverview = () => HTTP.waApi.get(ORGANISATION_OVERVIEW);

const getBillingData = () => HTTP.waApi.get(ORGANISATION_BILLING);

const addNewUser = (newUserBody: NewUserBody) => HTTP.waApi.post(ADD_NEW_USER, newUserBody);

const sendNewUserConfirmationEmail = (emailAddress: string) =>
  HTTP.waApi.post(SEND_NEW_USER_CONFIRMATION, { emailAddress });

export default {
  getOrganisationOverview,
  addNewUser,
  sendNewUserConfirmationEmail,
  getBillingData,
};
