import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import PageLayout from '../../components/Display/PageLayout';
import userOptionsActions from '../../redux/actions/userOptionsActions';
import userOptionsSelector from '../../redux/selectors/userOptionsSelector';
import useActions from '../../redux/actions/useActions';
import funnelsApi from '../../api/v1/funnels/funnelsApi';
import styles from './_funnels.module.scss';
import FunnelSidebar from '../../components/SideBars/FunnelSidebar';
import FunnelGraph from '../../components/Graph/FunnelGraph';
import FunnelsTable from '../../components/Tables/FunnelsTable';
import DisplayError from '../../components/Generic/DisplayError';
import squirrelPicture from '../../static/media/squirrelPodium.svg';
import { NO_DATA_BODY_MESSAGE, NO_DATA_TITLE_MESSAGE } from '../../messages/info';
import hiding from '../../static/media/hiding_squirrel.svg';
import { numberWithCommas } from '../../utils/general/generalFunctions';
import ModalContext from '../../contexts/modalContext';
import ConfirmNameModal from '../../components/Modals/ConfirmNameModal';
import YourFunnels from './yourFunnels/yourfunnels';

const Funnels = () => {
  const { fromDate, toDate, apps, currentAppId } = useSelector(userOptionsSelector);
  const { setSelectedDateRange } = useActions(userOptionsActions);
  const [loading, setLoading] = useState(true);
  const [isLoadingFunnel, setIsLoadingFunnel] = useState(false);
  const [error, setError] = useState('');
  const [funnelEventData, setFunnelEventData] = useState([]);
  const [initialFunnelData, setInitialFunnelData] = useState({
    sessionCount: 0,
  });
  const [funnelResultData, setFunnelResultData] = useState([]);
  const [savedFunnelsData, setSavedFunnelsData] = useState([]);
  const [currentFunnel, setCurrentFunnel] = useState([]);
  const { setModalContent, clearModalContent } = useContext(ModalContext);

  const onFunnelGenerate = async (funnelData) => {
    setFunnelResultData([]);
    setCurrentFunnel(funnelData);
    setIsLoadingFunnel(true);
    try {
      setError('');
      const payload = await funnelsApi.getUserFunnel(currentAppId, funnelData, fromDate, toDate);

      const { data } = payload;
      setInitialFunnelData(payload.data[0]);

      const dataWithoutInitialStep = _.filter(data, (step) => step.stepNum !== 0);
      setFunnelResultData(dataWithoutInitialStep);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoadingFunnel(false);
    }
  };

  const getSavedFunnels = async () => {
    const savedFunnels = await funnelsApi.getSavedFunnels(currentAppId);
    setSavedFunnelsData(savedFunnels.data);
  };

  const saveCurrentFunnel = async (query, funnelName) => {
    try {
      await funnelsApi.saveFunnel(currentAppId, funnelName, query);
      await getSavedFunnels();
      await onFunnelGenerate(query);
    } catch (e) {
      setError(e.message);
    }
  };

  const deleteFunnel = async (funnelId) => {
    try {
      await funnelsApi.deleteFunnel(funnelId);
      await getSavedFunnels();
    } catch (e) {
      setError(e.message);
    }
  };

  useEffect(() => {
    if (currentFunnel.length) onFunnelGenerate(currentFunnel);
  }, [fromDate, toDate]);

  useEffect(() => {
    (async () => {
      try {
        setError('');
        setLoading(true);

        const payload = await funnelsApi.getFunnelEvents(currentAppId);
        const sortedByAlphabeticalEvents = _.sortBy(payload.data, (x) => x.label);
        setFunnelEventData(sortedByAlphabeticalEvents);

        await getSavedFunnels();
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [currentAppId]);

  const sidebar = (
    <FunnelSidebar
      funnelEventData={funnelEventData}
      onFunnelGenerate={onFunnelGenerate}
      isLoading={isLoadingFunnel}
      currentFunnel={currentFunnel}
      setCurrentFunnel={setCurrentFunnel}
      onSaveFunnel={(query) => {
        setModalContent(
          <ConfirmNameModal
            title='Name your funnel'
            onModalClose={clearModalContent}
            confirmButtonLabel='Save Funnel'
            placeholder='Please enter funnel name'
            onConfirmButtonPress={(funnelName) => saveCurrentFunnel(query, funnelName)}
          />,
        );
      }}
    />
  );

  const hasData = funnelResultData.length;

  const savedFunnelComponent = (
    <YourFunnels
      savedFunnels={savedFunnelsData}
      onFunnelGenerate={onFunnelGenerate}
      onDeleteFunnel={deleteFunnel}
    />
  );
  return (
    <PageLayout
      sidebar={sidebar}
      highlightTab='Funnels'
      isLoading={loading || isLoadingFunnel}
      error={error}
      fromDate={fromDate}
      apps={apps}
      currentAppId={currentAppId}
      toDate={toDate}
      onDatesSelected={setSelectedDateRange}
      centerContentHorizontal={!hasData}
      centerContentVertical={!hasData}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {!funnelEventData.length ? (
        <DisplayError
          title={NO_DATA_TITLE_MESSAGE}
          description={NO_DATA_BODY_MESSAGE}
          linkLocation='instructions'
          linkText='See Instructions'
          image={hiding}
        />
      ) : hasData ? (
        <Grid container className={styles.root}>
          <Grid item md={12}>
            {savedFunnelComponent}
          </Grid>

          <Grid item md={12} className={styles.funnelGraph}>
            <FunnelGraph
              title={`Initial Sessions: ${numberWithCommas(initialFunnelData.sessionCount) || 0}`}
              data={funnelResultData}
            />
          </Grid>
          <Grid item md={12} className={styles.funnelsTable}>
            <FunnelsTable data={funnelResultData} />
          </Grid>
        </Grid>
      ) : (
        <DisplayError
          image={squirrelPicture}
          title='Create Funnel'
          description='Use the funnel creator in the sidebar on the left to create your custom funnel'
          additionalContent={savedFunnelComponent}
        />
      )}
    </PageLayout>
  );
};

export default Funnels;
