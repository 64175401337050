import PropTypes, { shape, string, number, boolean, arrayOf, oneOfType } from 'prop-types';

export const organisation = shape({
  createdOn: oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  applications: number,
  team: number,
  subscription: string,
});

export const teamMember = shape({
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  emailVerified: boolean,
  role: string,
});

export const team = arrayOf(teamMember);
