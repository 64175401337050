import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../routing/PrivateRoute';
import Home from './dashboard';
import Sessions from './sessions';
import Events from './events/events';
import Organization from './organization';
import Admin from './admin/admin';
import SignUp from './auth/signUp';
import SignIn from './auth/signIn';
import Page404 from './404/page404';
import Crashes from './crashes';
import Funnels from './funnels/funnels';

const Router = () => {
  return (
    <BrowserRouter forceRefresh>
      <Switch>
        <PrivateRoute exact path='/' component={Home} />
        <PrivateRoute path='/sessions' component={Sessions} />
        <PrivateRoute path='/events' component={Events} />
        <PrivateRoute path='/organization' component={Organization} />
        <PrivateRoute path='/crashes' component={Crashes} />
        <PrivateRoute path='/admin' component={Admin} />
        <PrivateRoute path='/funnels' component={Funnels} />

        <Route path='/auth/signUp' component={SignUp} />
        <Route path='/auth/signIn' component={SignIn} />
        <Route
          path='/instructions'
          component={() => {
            window.location.href = 'https://www.npmjs.com/package/react-native-forage';
            return null;
          }}
        />
        <Route component={Page404} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
