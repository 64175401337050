import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  container: {
    minHeight: '200pt',
    display: 'flex',
    paddingRight: 3,
    paddingLeft: 3,
    flex: 1,
    flexDirection: 'column',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar ': {
      display: 'none',
    },
  },
  table: {
    borderSpacing: '0 3pt',
    borderCollapse: 'inherit',
  },
  tableHeaderContainer: {
    backgroundColor: '#fff',
    borderRadius: 8,
    justifyContent: 'space-between',
  },
  tableHeaderTitle: {
    color: '#8D8D8D',
    fontSize: 14,
  },
  tableRowTextCell: {
    width: '20%',
    backgroundColor: '#fff',
  },
  tableRowCellProgressContainer: {
    width: '70%',
    backgroundColor: '#fff',
  },
  tableRowProgressBarContainer: {
    display: 'flex',
    marginRight: 50,
    backgroundColor: '#F4F6FA',
    borderRadius: 50,
  },
  tableRowProgressBar: {
    height: 20,
    borderRadius: 50,
  },
}));
