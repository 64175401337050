import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useStyles from './SessionMetadataStyle';

const Metadata = ({ sessionStart, duration, sessionEnd }) => {
  const startDate = moment(sessionStart).format('ddd D MMM YYYY');
  const startTime = moment(sessionStart).format('HH:mm:ss.SSS');
  const endTime = moment(sessionEnd).format('HH:mm:ss.SSS');

  const classes = useStyles();

  return (
    <Grid container className={classes.sessionMetadataRoot}>
      <Box className={classes.sessionMetadata}>
        <Typography variant='subtitle2' color='textSecondary' className={classes.header}>
          Session Date
        </Typography>
        <Typography variant='body1' color='textPrimary' className={classes.label}>
          {startDate}
        </Typography>
      </Box>

      <Box className={classes.sessionMetadata}>
        <Typography variant='subtitle2' color='textSecondary' className={classes.header}>
          Start Time
        </Typography>
        <Typography variant='body1' color='textPrimary' className={classes.label}>
          {startTime}
        </Typography>
      </Box>

      <Box className={classes.sessionMetadata}>
        <Typography variant='subtitle2' color='textSecondary' className={classes.header}>
          Duration
        </Typography>
        <Typography variant='body1' color='textPrimary' className={classes.label}>
          {duration}
        </Typography>
      </Box>

      {!!sessionEnd && (
        <Box className={classes.sessionMetadata}>
          <Typography variant='subtitle2' color='textSecondary' className={classes.header}>
            End Time
          </Typography>
          <Typography variant='body1' color='textPrimary' className={classes.label}>
            {endTime}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default Metadata;

Metadata.propTypes = {
  duration: PropTypes.string,
  sessionStart: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  sessionEnd: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

Metadata.defaultProps = {
  // Pass in default values so we don't see flickering on session close
  duration: '0:00',
  sessionStart: '2020-01-01T12:00:00',
  sessionEnd: undefined,
};
