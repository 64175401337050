import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  noTimelineContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    border: `1px solid ${theme.palette.common.borderColor}`,
    borderRadius: '4px',
  },
  noTimelineIcon: {
    backgroundSize: 'contain',
    height: '100px',
    width: '100%',
    marginBottom: 10,
  },
  timelineList: {
    ...theme.scrollbar,
    paddingRight: 20,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
}));
