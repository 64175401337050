import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Hidden } from '@material-ui/core';

import TopMobileNavigation from './TopMobileNavigation';
import TopDesktopNavigation from './TopDesktopNavigation';

import userOptionsActions from '../../../redux/actions/userOptionsActions';
import useActions from '../../../redux/actions/useActions';

import appsApi from '../../../api/v1/app/app';

const actions = {
  setCurrentApp: userOptionsActions.setCurrentApp,
};

const TopNavigation = ({ ...args }) => {
  const { currentAppId } = args;
  const [localAppId, setLocalAppId] = useState(currentAppId);
  const { setCurrentApp } = useActions(actions);

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    (async () => {
      try {
        const appId = localAppId;
        const { data: appKey } = await appsApi.getAppKey(appId);

        setCurrentApp({ appId, appKey });
      } catch (error) {
        console.error('Error fetching app key:', error);
      }
    })();
  }, [localAppId]);

  return (
    <>
      <Hidden lgUp>
        <TopMobileNavigation {...args} loading={!currentAppId} setLocalAppId={setLocalAppId} />
      </Hidden>
      <Hidden mdDown>
        <TopDesktopNavigation {...args} loading={!currentAppId} setLocalAppId={setLocalAppId} />
      </Hidden>
    </>
  );
};

TopNavigation.propTypes = {
  actionItems: PropTypes.objectOf(PropTypes.element),
  navigationItems: PropTypes.objectOf(PropTypes.element),
};

TopNavigation.defaultProps = {
  actionItems: {},
  navigationItems: {},
};

export default TopNavigation;
