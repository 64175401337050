import { makeStyles } from '@material-ui/core/styles';

const verticalLayoutWidth = '960px';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '25px 40px 40px 40px',
    overflowY: 'hidden',
    backgroundColor: 'white',
    border: '1px solid #EAEDF3',
    borderRadius: 4,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.common.borderColor,
    margin: '25px 0',
  },
  errorContainer: {
    height: '100%',
  },
  contentMissing: {
    marginTop: 30,
  },
  sessionDetailsRoot: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    overflowY: 'hidden',

    [`@media only screen and (max-width: ${verticalLayoutWidth})`]: {
      flexDirection: 'column',
    },
  },
  timelineContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: '700px',
    flexShrink: 0,
    marginRight: 44,
    minWidth: 300,

    [`@media only screen and (max-width: ${verticalLayoutWidth})`]: {
      maxWidth: 'none',
      height: '50%',
      marginRight: 0,
    },
  },
  timelineTitle: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: 11,
    fontSize: 20,
  },
  timelineList: {
    ...theme.scrollbar,
    paddingRight: 10,
    overflowY: 'auto',
  },
  detailsTabContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',

    [`@media only screen and (max-width: ${verticalLayoutWidth})`]: {
      height: '50%',
      marginTop: 5,
      paddingTop: 5,
      borderTop: `1px solid ${theme.palette.common.borderColor}`,
    },
  },
}));
