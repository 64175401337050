import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffRounded';

import FormTextError from './FormTextError';

const PasswordField = ({ id, error, value, InputProps, ...rest }) => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const togglePassword = () => setVisiblePassword(!visiblePassword);

  const clearButton = (
    <Fade in={!!value}>
      <InputAdornment
        position='end'
        onClick={togglePassword}
        style={{ cursor: 'pointer' }}
        data-testid='TextField-clearButton'
      >
        {visiblePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </InputAdornment>
    </Fade>
  );

  return (
    <>
      <MaterialTextField
        variant='outlined'
        fullWidth
        margin='normal'
        label='Password'
        type={visiblePassword ? 'text' : 'password'}
        InputProps={{ endAdornment: clearButton, ...InputProps }}
        autoComplete='current-password'
        id={id}
        name={id}
        error={!!error}
        value={value}
        {...rest}
      />
      {!!error && <FormTextError error={error} />}
    </>
  );
};

PasswordField.propTypes = {
  ...MaterialTextField.propTypes,
  error: PropTypes.string,
};

// Override any 'required' attributes that have been given a default value
PasswordField.defaultProps = {
  ...MaterialTextField.defaultProps,
  variant: 'outlined',
  id: 'password',
};

export default PasswordField;
