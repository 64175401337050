import { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import eventsAPI from '../api/v1/events/events';
import { addEventToEventFilters } from '../utils/event/eventsUtils';

const cancelToken = axios.CancelToken;

// eslint-disable-next-line import/prefer-default-export
export const useEvents = (currentAppId) => {
  const [events, setEvents] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(true);

  useEffect(() => {
    const cancellationToken = cancelToken.source();
    (async () => {
      // Events fetching here
      try {
        if (!currentAppId) {
          throw new Error('No appId defined to fetch Events');
        }

        const { data: apiEvents } = await eventsAPI.getEvents({ appId: currentAppId });

        setEvents(apiEvents);
      } catch (e) {
        console.error(e);
        setEvents([]);
      }

      setLoadingEvents(false);
    })();

    return () => {
      cancellationToken.cancel();
    };
  }, [currentAppId]);

  return { events, loadingEvents };
};

export const useEventFilters = (initialEvents = []) => {
  const [eventFilters, setEventFilters] = useState(initialEvents);

  const addEventFilter = (newEvent) => {
    const updatedEvents = addEventToEventFilters(newEvent, eventFilters);
    setEventFilters(updatedEvents);
  };

  const removeEventFilter = (eventName) => {
    const updatedEvents = eventFilters.filter((event) => event.eventName !== eventName);
    setEventFilters(updatedEvents);
  };

  /* This function performs object mutation, however it's required to be performant and deep cloning is too expensive */
  const updateEventFilterProperty = (eventName, key, value) => {
    const updatedEvents = [...eventFilters];
    const event = _.find(updatedEvents, { eventName });
    const property = _.find(event.propertyFilters, { key });

    Object.assign(property, { value });

    setEventFilters(updatedEvents);
  };

  return {
    eventFilters,
    eventFilterFunctions: {
      addEventFilter,
      removeEventFilter,
      updateEventFilterProperty,
    },
  };
};
