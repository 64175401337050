import { makeStyles, createStyles } from '@material-ui/core/styles';

const headerHeight = '60pt';

export default makeStyles((theme) =>
  createStyles({
    root: {
      height: `calc(95vh - ${headerHeight} - 5pt)`,
      overflow: 'hidden',
    },
    statsWrapper: {
      height: '40%',
      paddingBottom: 0,
      [theme.breakpoints.down('sm')]: {
        height: '50%',
      },
    },
    countersWrapper: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        height: '50%',
      },
      height: '100%',
    },
    counter: {
      height: '50%',
      '&:first-child': {
        paddingBottom: '16px',
      },
      '&:last-child': {
        paddingTop: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        height: '100%',
        '&:first-child': {
          paddingBottom: 0,
          paddingRight: '16px',
        },
        '&:last-child': {
          paddingTop: 0,
          paddingLeft: '16px',
        },
      },
    },
    graph: {
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        height: '50%',
      },
    },
    tableWrapper: {
      height: '60%',
      [theme.breakpoints.down('sm')]: {
        height: '50%',
      },
    },
  }),
);
