import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    container: {
      paddingTop: 8,
      paddingBottom: 8,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    tabButton: {
      display: 'flex',
      textTransform: 'none',
      backgroundColor: 'white',
      color: theme.palette.text.primary,
      fontSize: 15,
      padding: 0,
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    tabButtonLabel: {
      maxWidth: '30ch',
      maxHeight: 30,
      textDecoration: 'none',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingRight: 30,
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 13,
    },
    tabButtonLabelLoading: {
      padding: 5,
    },
    buttonArrowIcon: {
      position: 'absolute',
      right: 0,
      paddingRight: 10,
      color: theme.palette.primary.main,
      transform: 'rotate(0deg)',
    },
    buttonArrowIconRotate: {
      transform: 'rotate(180deg)',
      paddingLeft: 10,
    },
    paper: {
      border: `1px solid ${theme.palette.common.borderColor}`,
      boxShadow: '0px 0px 5px 5px rgba(123,123,123,0.1)',
    },
  }),
);
