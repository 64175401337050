import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const column = {
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 5,
  justifyContent: 'center',
};

export default makeStyles((theme) =>
  createStyles({
    row: {
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
      background: '#fff',
      height: '90%',
      width: '90%',
      padding: 60,

      '@media (min-width: 600px)': {
        width: 580,
        height: 532,
        padding: 0,
      },

      '@media (min-width: 960px)': {
        width: 840,
      },
    },

    container: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',

      '@media (min-width: 600px)': {
        padding: 0,
      },
    },

    leftGrid: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 0,
      paddingRight: 25,

      '@media (min-width: 600px)': {
        padding: '50px 0 50px 50px !important',
      },

      '@media (min-width: 960px)': {
        padding: '80px 0 80px 80px !important',
      },
    },

    gridItem: {
      flexBasis: 'auto',

      '@media (max-width: 600px)': {
        marginBottom: 15,
      },
    },

    link: {
      textDecoration: 'none',
    },

    title: {
      fontSize: 42,
      fontWeight: 'bold',
      color: theme.palette.text.primary,

      '@media (min-width: 600px)': {
        fontSize: 60,
      },
    },

    description: {
      fontSize: 15,
      lineHeight: 1.6,
      fontWeight: 'normal',
      color: theme.palette.text.primary,
      width: 'auto',

      '@media (min-width: 600px)': {
        fontSize: 20,
        width: '70%',
      },

      '@media (min-width: 960px)': {
        fontSize: 20,
        width: 'auto',
      },

      '@media (min-width: 1280px)': {
        fontSize: 20,
      },
    },

    error: {
      fontSize: 14,
      color: theme.palette.text.secondary,

      '@media (min-width: 600px)': {
        fontSize: 16,
      },
    },

    button: {
      height: 50,
      width: 158,
      textTransform: 'none',
    },

    imageContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },

    image: {
      position: 'relative',
      backgroundSize: 'contain',
      width: 225,
      height: 225,
      right: 0,
      bottom: 75,

      '@media (max-width: 600px)': {
        width: 'auto',
        height: 180,
        right: 0,
        bottom: -20,
      },

      '@media (min-width: 600px) and (max-width: 960px)': {
        position: 'absolute',
        right: 50,
        bottom: 50,
      },
    },

    xsColumn: {
      ...column,
      justifyContent: 'center',
      alignItems: 'center',
    },

    xsLink: {
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },

    xsButton: {
      height: 50,
      marginTop: 20,
      width: '100%',
      textTransform: 'none',
    },
  }),
);
