import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TeamList from './TeamList';
import SubHeader from '../../Text/SubHeader';

import * as OrganisationTypes from '../../../utils/types/organisation';
import CustomerTypes from '../../../utils/types/customer';

import styles from './TeamDetailsStyle';

const TeamDetails = ({
  team,
  currentUser,
  onUserRoleChange,
  onUserDeleted,
  onTeamMemberNotVerifiedIconPress,
}) => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <SubHeader className={classes.teamSubHeader}>Team</SubHeader>
      <Typography variant='body1' className={classes.teamDescription}>
        Below is a list of all users in your organization&apos;s team. Hover over a user to see more
        actions.
      </Typography>
      <TeamList
        team={team}
        currentUser={currentUser}
        onUserRoleChange={onUserRoleChange}
        onUserDeleted={onUserDeleted}
        onTeamMemberNotVerifiedIconPress={onTeamMemberNotVerifiedIconPress}
      />
    </Box>
  );
};

TeamDetails.propTypes = {
  team: OrganisationTypes.team.isRequired,
  currentUser: CustomerTypes.currentUser.isRequired,
  onUserRoleChange: PropTypes.func.isRequired,
  onUserDeleted: PropTypes.func.isRequired,
  onTeamMemberNotVerifiedIconPress: PropTypes.func.isRequired,
};

export default TeamDetails;
