import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      ...theme.scrollbar,
      display: 'compact',
      height: '100%',
      overflowY: 'auto',
    },
    loadingContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    loadingImage: {
      height: '50%',
      width: '50%',
      backgroundSize: 'contain',
    },
    userDetailsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    userDetailItem: {
      margin: '0 50px 15px 0',
    },
    inputsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      marginTop: 25,
      '@media only screen and (max-width: 960px)': {
        flexDirection: 'column',
      },
    },
    updateProfileContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    changePasswordContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      '@media only screen and (max-width: 960px)': {
        marginBottom: 40,
      },
    },
    updateProfileHeader: {
      fontWeight: 'bold',
      fontSize: 24,
    },
    updatePasswordTitle: {
      fontWeight: 'bold',
      fontSize: 24,
    },
    divider: {
      margin: '0 40px',

      '@media only screen and (max-width: 960px)': {
        margin: '40px 0',
      },
    },
  }),
);
