import React from 'react';
import PropTypes from 'prop-types';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Text from '../../../components/Text/Text';

const customStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 13,
      paddingRight: 10,
      '&:hover': {
        backgroundColor: theme.palette.common.borderColor,
        cursor: 'pointer',
      },
    },
    eventText: {
      paddingLeft: 5,
      fontSize: 12,
      paddingTop: 2,
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      color: theme.palette.text.primary,
    },
    textAndIconContainer: {
      alignItems: 'center',
      justifyContent: 'space-between',
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
    },
  }),
);

const getIconField = (eventType) => {
  const iconStyle = { fontSize: 16 };
  if (eventType.toUpperCase() === 'EVENT') {
    return (
      <FlagOutlinedIcon style={iconStyle} data-testid='funnelEventRowEventIcon' color='primary' />
    );
  }
  return (
    <PhoneIphoneOutlinedIcon
      style={iconStyle}
      data-testid='funnelEventRowScreenIcon'
      color='primary'
    />
  );
};

const FunnelEventRow = ({ eventName, eventType, onClick }) => {
  const classes = customStyles();
  return (
    <Box
      className={classes.container}
      data-testId='FunnelEventRow-ClickBox'
      onClick={() => onClick({ label: eventName, type: eventType })}
    >
      {getIconField(eventType)}
      <div className={classes.textAndIconContainer}>
        <Text className={classes.eventText}>{eventName}</Text>
        <AddIcon style={{ fontSize: 13, color: '#747983' }} />
      </div>
    </Box>
  );
};

export default FunnelEventRow;

FunnelEventRow.propTypes = {
  eventName: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
