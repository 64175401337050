import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './NavBarStyle';

const NavBar = ({ onPreviousClick, onNextClick }) => {
  const classes = styles();
  return (
    <>
      <IconButton
        className={classes.styleLeft}
        color='primary'
        onClick={() => onPreviousClick()}
        aria-label='date backwards'
      >
        <ArrowBackIosRoundedIcon />
      </IconButton>
      <IconButton
        className={classes.styleRight}
        color='primary'
        onClick={() => onNextClick()}
        aria-label='date forwards'
      >
        <ArrowForwardIosRoundedIcon />
      </IconButton>
    </>
  );
};

NavBar.propTypes = {
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
};

NavBar.defaultProps = {
  onPreviousClick: undefined,
  onNextClick: undefined,
};

export default NavBar;
