/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import _ from 'lodash';
import {
  TableBody,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
} from '@material-ui/core';
import PropTypes, { shape } from 'prop-types';
import styles from './FunnelsTableStyles';
import { numberWithCommas } from '../../utils/general/generalFunctions';

// eslint-disable-next-line no-restricted-globals
const roundTo2dp = (value) => (isNaN(value) ? 0 : Math.round(value * 100) / 100);

const calculateDropOffSessions = (currentData, previousData) => {
  if (!previousData || !currentData) return 0;
  return previousData.sessionCount - currentData.sessionCount;
};

const calculateDropOffPercentage = (currentData, previousData) => {
  if (!currentData || !previousData) return 0;
  const value = Math.abs((currentData.sessionCount / previousData.sessionCount) * 100 - 100);
  return roundTo2dp(value);
};

const calculatePercentageComplete = (currentData, initialData) => {
  if (!currentData || !initialData) return 0;
  const totalPercentageCompleted = calculateDropOffPercentage(currentData, initialData);
  const value = Math.abs(totalPercentageCompleted - 100);
  return roundTo2dp(value);
};

const Row = ({ data, previousData, initialData }) => {
  const classes = styles();
  const { sessionCount, stepName } = data;
  return (
    <TableRow>
      <TableCell className={classes.tableRowTextCell}>{stepName}</TableCell>
      <TableCell className={classes.tableRowTextCell}>
        {numberWithCommas(sessionCount) || 0}
      </TableCell>
      <TableCell className={classes.tableRowTextCell}>
        {numberWithCommas(calculateDropOffSessions(data, previousData)) || 0}
      </TableCell>
      <TableCell className={classes.tableRowTextCell}>
        {calculateDropOffPercentage(data, previousData)} %
      </TableCell>
      <TableCell className={classes.tableRowTextCell}>
        {calculatePercentageComplete(data, initialData)} %
      </TableCell>
    </TableRow>
  );
};

const FunnelsTable = ({ data }) => {
  const classes = styles();
  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeaderContainer}>
            <TableCell className={classes.tableHeaderTitle}>Step</TableCell>
            <TableCell className={classes.tableHeaderTitle}>Session Count</TableCell>
            <TableCell className={classes.tableHeaderTitle}>Dropped Off Sessions</TableCell>
            <TableCell className={classes.tableHeaderTitle}>Dropped Off Percentage</TableCell>
            <TableCell className={classes.tableHeaderTitle}>Percentage Complete</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.length > 0 &&
            _.map(data, (dataRow, index) => (
              <Row
                key={index}
                data={dataRow}
                previousData={index ? data[index - 1] : null}
                initialData={data[0]}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

Row.propTypes = {
  data: shape({
    stepName: PropTypes.string.isRequired,
    stepNum: PropTypes.number.isRequired,
    sessionCount: PropTypes.number.isRequired,
  }).isRequired,
  previousData: shape({
    stepName: PropTypes.string.isRequired,
    stepNum: PropTypes.number.isRequired,
    sessionCount: PropTypes.number.isRequired,
  }),
  initialData: shape({
    stepName: PropTypes.string.isRequired,
    stepNum: PropTypes.number.isRequired,
    sessionCount: PropTypes.number.isRequired,
  }).isRequired,
};

Row.defaultProps = {
  previousData: null,
};

FunnelsTable.propTypes = {
  data: PropTypes.arrayOf(
    shape({
      stepName: PropTypes.string.isRequired,
      stepNum: PropTypes.number.isRequired,
      sessionCount: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export const _exposers_ = {
  roundTo2dp,
  calculateDropOffSessions,
  calculateDropOffPercentage,
  calculatePercentageComplete,
  Row,
};

export default FunnelsTable;
