import createAxiosInstance from './axiosConfig';
import { FORAGE_API_TIMEOUT } from '../config';

const baseURLs = {
  REACT_APP_WA_API_URL: process.env.REACT_APP_WA_API_URL,
};

export const httpFactory = () => ({
  waApi: createAxiosInstance(baseURLs.REACT_APP_WA_API_URL, { timeout: FORAGE_API_TIMEOUT }),
});

const HTTP = httpFactory();

export { HTTP };
