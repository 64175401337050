import PropTypes from 'prop-types';
import React from 'react';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) =>
  createStyles({
    boxContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: (paddingValue) => `${paddingValue}pt`,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.05)',
      },
      '&:active': {
        backgroundColor: 'rgba(0,0,0,0.08)',
      },
    },
    checkboxLabel: {
      fontSize: 16,
      color: theme.palette.text.primary,
      paddingLeft: 10,
    },
  }),
);

const CheckboxInput = ({ label, isActive, onSetActive, padding }) => {
  const classes = styles(padding);
  return (
    <Box className={classes.boxContainer} onClick={() => onSetActive(!isActive)}>
      {!isActive ? (
        <CheckBoxOutlineBlank color='primary' fontSize='small' data-testid='checkbox-inactive' />
      ) : (
        <CheckBox color='primary' fontSize='small' data-testid='checkbox-active' />
      )}
      <Typography variant='body2' className={classes.checkboxLabel}>
        {label}
      </Typography>
    </Box>
  );
};

export default CheckboxInput;

CheckboxInput.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onSetActive: PropTypes.func.isRequired,
  padding: PropTypes.number,
};

CheckboxInput.defaultProps = {
  padding: 5,
};
