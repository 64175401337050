import { createStyles, makeStyles } from '@material-ui/core/styles';

const height = '60vh';

export default makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
    },
    root: {
      display: 'flex',
      margin: 'auto',
      textWrap: 'normal',
      flexDirection: 'row',
      padding: '40px',
      height,
    },
  }),
);
