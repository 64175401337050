import Modal from '@material-ui/core/Modal';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';

import EventFilterEventsColumn from './SubComponents/EventFilterEventsColumn';
import EventFilterPropertiesColumn from './SubComponents/EventFilterPropertiesColumn';

import useStyles from './EventFilterModalStyles';
import types from '../../utils/types/events';

const EventFilterModal = ({ open, onClose, addEventFilter, events, loadingEvents }) => {
  const classes = useStyles();
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [hoveredEvent, setHoveredEvent] = useState(undefined);

  const eventToShow = selectedEvent ?? hoveredEvent;
  const propertiesToShow = eventToShow?.properties;

  const onPropertyClick = (property) => {
    const { properties, ...rest } = eventToShow;

    addEventFilter({
      ...rest,
      propertyFilters: [{ key: property }],
    });

    onClose();
  };

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Fade in>
        <Paper className={classes.root}>
          {/* Left Column */}
          <EventFilterEventsColumn
            events={events}
            clearModalContent={onClose}
            addEventFilter={addEventFilter}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            hoveredEvent={hoveredEvent}
            setHoveredEvent={setHoveredEvent}
            loadingEvents={loadingEvents}
          />

          {/* Right Column */}
          <EventFilterPropertiesColumn
            properties={propertiesToShow}
            onPropertyClick={onPropertyClick}
            loadingEvents={loadingEvents}
          />
        </Paper>
      </Fade>
    </Modal>
  );
};

export default EventFilterModal;

EventFilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  events: types.events.isRequired,
  addEventFilter: PropTypes.func.isRequired,
  loadingEvents: PropTypes.bool,
};

EventFilterModal.defaultProps = {
  loadingEvents: false,
};
