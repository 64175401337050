import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: '15px',
      paddingBottom: '25px',
    },
    title: {
      fontWeight: 'bold',
      flex: 1,
      color: theme.palette.primary.main,
      fontSize: 20,
    },
  }),
);
