import React from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';

import GraphHolder from './GraphHolder';
import Tooltip from './Tooltip';

const buildData = (data, title) => [
  {
    id: title,
    data: data.map((dataObject) => ({
      x: dataObject.label,
      y: dataObject.value,
    })),
  },
];

const LineGraph = ({ data, title }) => {
  const renderTooltip = (pointData) => {
    const { x: day, y: amount } = pointData.point.data;
    const formattedDay = Moment(day).format('ddd MMM D');

    const toolTipItems = [{ amount }, { date: formattedDay }];

    return <Tooltip tooltipItems={toolTipItems} />;
  };

  const xTickAmount = data.length < 10 ? data.length : 10;
  const maxValueInGraph = Math.max(...data.map((x) => x.value));
  const yTickValue = maxValueInGraph > 8 ? undefined : maxValueInGraph;

  return (
    <GraphHolder title={title}>
      <ResponsiveLine
        data={buildData(data, title)}
        tooltip={renderTooltip}
        margin={{ top: 30, right: 30, bottom: 50, left: 50 }}
        xScale={{
          type: 'time',
          format: '%d/%m/%Y',
        }}
        colors={['#AF3552']}
        yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: '%b %d',
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickValues: xTickAmount,
          tickRotation: 0,
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: yTickValue,
        }}
        pointSize={10}
        pointBorderWidth={2}
        pointBorderColor='#AF3552'
        pointColor='#fff'
        pointLabel='y'
        pointLabelYOffset={-12}
        enableCrosshair={false}
        useMesh
        enableArea
        areaOpacity={0.1}
      />
    </GraphHolder>
  );
};

LineGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};

export default LineGraph;
