import apiUrls from '../../apiUrls';
import { HTTP } from '../../index';

const { SIGN_IN, SIGN_UP } = apiUrls;

const signIn = (emailAddress, password) => HTTP.waApi.post(SIGN_IN, { emailAddress, password });

const signUp = (
  emailAddress,
  firstName,
  lastName,
  organisationName,
  password,
  passwordConfirmation,
  termsAndConditionsAccepted,
) =>
  HTTP.waApi.post(SIGN_UP, {
    emailAddress,
    firstName,
    lastName,
    organisationName,
    password,
    passwordConfirmation,
    termsAndConditionsAccepted,
  });

export default {
  signIn,
  signUp,
};
