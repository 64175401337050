/* eslint-disable react/style-prop-object */
import PropTypes from 'prop-types';
import RedditPixel from 'react-reddit-pixel';
import TwitterConvTrkr from 'react-twitter-conversion-tracker';
import ReactPixel from 'react-facebook-pixel';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import LoadingButton from '../../Buttons/LoadingButton';
import TextField from '../../TextFields/TextField';
import squirrelLooking from '../../../static/media/Squirrel looking.svg';
import UnderlinedButton from '../../Buttons/UnderlinedButton';
import { logout } from '../../../utils/auth/auth';
import AppsApi from '../../../api/v1/app/app';
import useNotifications from '../../../contexts/notificationContext';
import CustomerApi from '../../../api/v1/customer/customer';

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      padding: '50pt',
      maxWidth: '50%',
    },
    title: {
      fontSize: '35pt',
      marginBottom: '20pt',
    },
    message: {
      fontSize: '15pt',
      marginBottom: '10pt',
    },
    button: {
      padding: '10pt',
      marginTop: '10pt',
    },
    image: {
      padding: '15pt',
      width: '50%',
      transform: 'scaleX(-1)',
      pointerEvents: 'none',
      alignSelf: 'center',
    },
  }),
);

const initialValues = { appName: '' };

const firstTimeUserValidation = yup.object().shape({
  appName: yup
    .string()
    .min(2, ({ min }) => `App Name must be at least ${min} characters`)
    .max(255, ({ max }) => `App Name must be at most ${max} characters`),
});

const isProduction = process.env.REACT_APP_SHOW_DEBUGGING_INFO === 'false';

// eslint-disable-next-line react/prop-types
const GoogleTagManagerContainer = ({ children }) => {
  const classes = useStyle();

  if (!isProduction) {
    return <Card className={classes.card}>{children}</Card>;
  }
  return (
    // id conv2 is a Google Tag Manager for Marketing
    <Card className={classes.card} id='conv2'>
      {children}
    </Card>
  );
};

const FirstTimeUserAppCreation = ({ getUsersApps }) => {
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addSuccessNotification, addErrorNotification } = useNotifications();

  const classes = useStyle();

  const checkAndSetPermissions = async () => {
    const { data: userDetails } = await CustomerApi.getProfileDetails();
    setHasPermission(userDetails.role !== 'USER');
  };

  useEffect(() => {
    checkAndSetPermissions();
  }, []);

  const onButtonPress = async (values) => {
    setLoading(true);
    try {
      if (!hasPermission) {
        throw Error('Only an Account Owner / Admin, Can Create The First App');
      }

      await AppsApi.addNewApp(values.appName);

      // I hate this method
      if (isProduction) {
        // Google Tracking
        window.dataLayer.push({
          event: 'Custom Event - Signup Conversion',
        });

        // Reddit Tracking
        RedditPixel.init('t2_bobeo8vn');
        RedditPixel.track('PageVisit');
        RedditPixel.track('SignUp');

        // Facebook Tracking
        ReactPixel.init('846684746190941');
        ReactPixel.track('PageView');
        ReactPixel.track('SignUp');

        // Twitter Tracking
        TwitterConvTrkr.init('o61gp');
        TwitterConvTrkr.pageView();
      }

      getUsersApps(true);
      addSuccessNotification(`${values.appName} Created`);
    } catch (err) {
      addErrorNotification(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.root}>
      <GoogleTagManagerContainer>
        <Typography variant='h1' className={classes.title}>
          Welcome To Forage!
        </Typography>

        <Typography variant='subtitle2' className={classes.message}>
          To start foraging simply enter your app name below and connect it with our tool.
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={firstTimeUserValidation}
          onSubmit={onButtonPress}
          validateOnChange={false}
        >
          {({ values, errors, handleChange, setFieldValue, handleBlur }) => (
            <Form method='post'>
              <TextField
                required
                variant='outlined'
                id='appName'
                label='App Name'
                placeholder='Enter your App Name'
                value={values.appName}
                error={errors.appName}
                onChange={handleChange}
                onBlur={handleBlur}
                setFieldValue={setFieldValue}
                testid='appNameTextField'
              />

              <LoadingButton
                className={classes.button}
                fullWidth
                isLoading={loading}
                id='submit'
                type='submit'
              >
                Add New App
              </LoadingButton>
            </Form>
          )}
        </Formik>

        <img alt='Squirrel Having A Look' src={squirrelLooking} className={classes.image} />

        <UnderlinedButton style={{ color: '#AF3552' }} onClick={logout} label='Log out' />
      </GoogleTagManagerContainer>
    </Box>
  );
};

export default FirstTimeUserAppCreation;

FirstTimeUserAppCreation.propTypes = {
  getUsersApps: PropTypes.func.isRequired,
};
