import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

const AlertArray = ({ alerts, severity }) =>
  !!alerts &&
  alerts.map(
    (alert) =>
      !!alert && (
        <Alert key={alert} severity={severity} data-testid='AlertArray-alert'>
          {alert}
        </Alert>
      ),
  );

AlertArray.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.string),
  severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
};

export default AlertArray;
