import axios from 'axios';
import qs from 'qs';
import {
  setAxiosRequestInterceptorHelper,
  setAxiosResponseInterceptorHelper,
} from './axiosHelpers';

export default (baseUrl, config = {}) => {
  const apiRequest = axios.create({
    baseURL: baseUrl,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' });
    },
    // Note: Config is only shallow merged. Consider when overriding configs above.
    ...config,
  });

  setAxiosRequestInterceptorHelper(apiRequest);
  setAxiosResponseInterceptorHelper(apiRequest);
  return apiRequest;
};
