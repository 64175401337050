import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';

import GraphHolder from './GraphHolder';
import Tooltip from './Tooltip';

const BarchartGraph = ({ data, title }) => {
  const renderTooltip = (pointData) => {
    const { label, value } = pointData.data;

    const toolTipItems = [{ value }, { label }];

    return <Tooltip tooltipItems={toolTipItems} />;
  };
  return (
    <GraphHolder title={title}>
      <ResponsiveBar
        tooltip={renderTooltip}
        data={data}
        keys={['value']}
        indexBy='label'
        margin={{ top: 30, right: 30, bottom: 40, left: 60 }}
        padding={0.5}
        groupMode='grouped'
        colors={['#AF3552']}
        axisTop={null}
        axisRight={null}
        theme={{ tooltip: { container: { padding: 0 } } }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Sessions',
          legendPosition: 'middle',
          legendOffset: -45,
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor='#ffffff'
        legends={[]}
        animate
        motionStiffness={90}
        motionDamping={15}
      />
    </GraphHolder>
  );
};

BarchartGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};

export default BarchartGraph;
