import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import LoadingSpinner from '../Spinners/LoadingSpinner';

const LoadingButton = ({ isLoading, spinnerSize, ...rest }) => {
  if (isLoading) {
    return (
      <Button {...rest} disabled>
        <LoadingSpinner size={spinnerSize} />
      </Button>
    );
  }
  return <Button {...rest} />;
};

export default LoadingButton;

LoadingButton.propTypes = {
  ...Button.propTypes,
  isLoading: PropTypes.bool.isRequired,
  spinnerSize: PropTypes.number,
};

LoadingButton.defaultProps = {
  spinnerSize: 26,
};
