import { makeStyles, createStyles } from '@material-ui/core/styles';

const icon = {
  padding: 5,
  fontSize: 15,
  borderRadius: '50%',
  backgroundColor: '#fff',
};
export default makeStyles((theme) =>
  createStyles({
    leftGridItem: {
      display: 'flex',
      alignItems: 'center',
    },
    rightGridItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',

      '& *': {
        fontSize: 14,
      },
    },
    iconPrevious: {
      ...icon,
      marginRight: 10,
      color: theme.palette.text.secondary,
    },
    iconNext: {
      ...icon,
      marginLeft: 10,
      color: theme.palette.text.secondary,
    },
    button: {
      textTransform: 'none',
      fontSize: 16,
      color: theme.palette.text.secondary,
    },
    pageTextField: {
      '& .MuiOutlinedInput-input': {
        backgroundColor: 'white',
        textAlign: 'center',
        padding: '9.5px 7px',
      },

      borderRadius: 0,
      width: 30,
      marginLeft: 10,
      marginRight: 10,
      border: 'none',
      marginTop: 8,
      color: theme.palette.text.secondary,

      '& fieldset': {
        border: 'none',
        padding: 0,
      },
    },
    pageInput: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      textAlign: 'center',
      borderRadius: 4,
      fontWeight: 'bold',
      border: '1px solid #EAEDF3',
      height: 30,
      padding: '0 !important',
      margin: 0,
    },
    divider: {
      margin: 12,
      height: '50%',
    },
    paginationSessionShowing: {
      fontSize: 14,
    },
  }),
);
