const squirrelFacts = [
  'The biggest species of squirrel is the Indian giant squirrel, which can grow up to an impressive 36 inches (1m) long.',
  'The smallest species of squirrel is the tiny African pygmy squirrel which is a minute 2.8-5 inches (7-13cm).',
  'Squirrels have four front teeth which never stop growing throughout their lives',
  'Squirrels can be categorised into three types: ground squirrels, tree squirrels and flying squirrels.',
  'Flying squirrels are able to glide through the air due to the flaps of skin which connect their limbs, providing a wing-like surface.',
  'Squirrels can recognise ripe nuts using their sense of smell.',
  'Squirrels can tell if an acorn or hazelnut has been hollowed out by weevils by recognising that the nut is too light.',
  'In order to find previously buried nuts, Squirrels use a combination of memory, smell and observation of disturbed soil',
  'Squirrel nests are called ‘dreys’.',
  'Squirrels communicate using a wide range of calls, such as territorial barks and ‘quacking’ noises, but their main form of communication is their tails.',
  'Squirrels can run up to 20 mph and have padded feet which cushion jumps from up to 20 feet high. ',
  'Squirrels can use their fluffy tails like parachutes in order to balance themselves during high leaps.',
  'Squirrels store acorns in the ground but only recover around 70 per cent of them, allowing the forgotten acorns to grow into healthy trees.',
  'Red squirrels were widespread in the UK until the 1940s but suffered a sharp decline in numbers and are now classified as an endangered species.',
];

const instructionFacts = [
  'Forage is specifically built for React Native',
  'If you choose specific dates on your calendar, this will apply to all pages',
];

const getArrayRandomElement = (arrayPassedIn) => {
  const factsSize = arrayPassedIn.length - 1;
  const randomArrayNumber = Math.round(Math.random() * factsSize);
  return arrayPassedIn[randomArrayNumber];
};

// eslint-disable-next-line import/prefer-default-export
export const getFacts = () => {
  const arraySelector = Math.round(Math.random());
  return getArrayRandomElement(arraySelector === 0 ? squirrelFacts : instructionFacts);
};
