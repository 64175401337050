import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import TimelineGroup from './TimelineGroup';
import TimelineItem from './TimelineItem';
import squirrelClosedEyes from '../../../../static/media/squirrel_closed_eyes.svg';

import useStyles from './SessionTimelineStyle';
import types from '../../../../utils/types/session';

const SessionTimeline = ({ timeline, selectedItemId, onItemClick }) => {
  const classes = useStyles();

  /* No Content */
  if (!timeline || !timeline.length) {
    return (
      <Box className={classes.noTimelineContainer}>
        <CardMedia className={classes.noTimelineIcon} image={squirrelClosedEyes} />
        <Typography variant='subtitle2'>No user interactions</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.timelineList}>
      {timeline.map((timelineItem) => {
        /* Grouped Events */
        if (timelineItem.events) {
          return (
            <TimelineGroup
              key={timelineItem.id}
              timelineGroupItem={timelineItem}
              selectedItemId={selectedItemId}
              onItemClick={onItemClick}
            />
          );
        }

        /* Ungrouped Events */
        return (
          <TimelineItem
            key={timelineItem.id}
            timelineItem={timelineItem}
            selected={timelineItem.id === selectedItemId}
            onItemClick={onItemClick}
            data-testid={`TimelineItem-${timelineItem.id}`}
          />
        );
      })}
    </Box>
  );
};

export default SessionTimeline;

SessionTimeline.propTypes = {
  timeline: types.timeline,
  selectedItemId: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
};

SessionTimeline.defaultProps = {
  timeline: undefined,
  selectedItemId: undefined,
};
