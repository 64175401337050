import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      ...theme.scrollbar,
      display: 'compact',
      height: '100%',
      flexDirection: 'column',
      marginTop: 35,
      marginRight: 5,
      overflowY: 'auto',

      [`@media only screen and (max-width: 960px)`]: {
        marginRight: 0,
      },
    },
  }),
);
