import moment from 'moment';
import {
  USER_OPTIONS_CLEAR_FORM,
  USER_OPTIONS_CLEAR_SELECTED_DATE,
  USER_OPTIONS_SELECT_DATE_RANGE,
  USER_OPTIONS_SET_CURRENT_APP,
  USER_OPTIONS_SET_APPS,
} from '../actions/userOptionsActions';

const INITIAL_STATE = {
  currentAppId: undefined,
  currentAppKey: undefined,
  apps: undefined,
  selectedDateRange: {
    fromDate: moment().subtract(6, 'days').startOf('day').toString(),
    toDate: moment().endOf('day').toString(),
  },
};

const userOptionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_OPTIONS_SET_CURRENT_APP:
      return { ...state, currentAppId: action.appId, currentAppKey: action.appKey };
    case USER_OPTIONS_SET_APPS:
      return { ...state, apps: action.apps };
    case USER_OPTIONS_SELECT_DATE_RANGE:
      return { ...state, selectedDateRange: action.selectedDateRange };
    case USER_OPTIONS_CLEAR_SELECTED_DATE:
      return { ...state, selectedDateRange: INITIAL_STATE.selectedDateRange };
    case USER_OPTIONS_CLEAR_FORM:
    case 'LOGOUT':
      return INITIAL_STATE;
    default:
      return { ...state };
  }
};

export default userOptionsReducer;
