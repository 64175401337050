import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.common.borderColor}`,
    borderRadius: '3px',
    marginBottom: 20,
  },
  groupHeaderOverride: {
    backgroundColor: `${theme.palette.common.borderColor}80`,

    '& p': {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
  },
}));
