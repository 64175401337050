import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  squirrelImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  squirrelImage: {
    height: 350,
    width: 400,
    marginRight: '20%',
  },
  loadingSpinner: {
    marginBottom: '20pt',
  },
});
