import clsx from 'clsx';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import useStyle from './SidebarSubHeaderStyle';

const SidebarSubHeader = ({ children, className }) => {
  const classes = useStyle();

  return (
    <Typography variant='h5' className={clsx(classes.textStyle, className)}>
      {children}
    </Typography>
  );
};

SidebarSubHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.oneOfType([PropTypes.string]),
};

SidebarSubHeader.defaultProps = {
  className: undefined,
};

export default SidebarSubHeader;
