import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    minHeight: '200pt',
    display: 'flex',
    paddingRight: 3,
    paddingLeft: 3,
    flex: 1,
    flexDirection: 'column',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar ': {
      display: 'none',
    },
  },
  table: {
    borderSpacing: '0 3pt',
  },
  spinnerContainer: {
    margin: '20pt',
    display: 'flex',
    justifyContent: 'center',
  },
  tableRow: {
    backgroundColor: '#fff',
    borderRadius: 8,
    cursor: 'pointer',
  },
  tableHeadCell: {
    color: '#8D8D8D',
    fontSize: 14,
  },
  tableBodyCell: {
    fontWeight: 'normal',
  },
  squirrelImageContainer: {
    display: 'flex',
    height: '95vh',
    justifyContent: 'center',
  },
  crashSessionIconCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: -16,
  },
  crashSessionIcon: {
    color: theme.palette.warningYellow,
  },
  squirrelImage: {
    height: 350,
    width: 400,
    margin: 'auto',
  },
}));
