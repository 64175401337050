import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: 10,
      backgroundColor: `${theme.palette.common.borderColor}`,
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      border: `1px solid ${theme.palette.common.borderColor}`,
      fontWeight: 'bold',
    },
    base: {
      padding: '10px 25px',
      justifyContent: 'space-between',
      display: 'flex',
      borderWidth: 1,
      borderColor: theme.palette.common.borderColor,
      borderRightStyle: 'solid',
      borderLeftStyle: 'solid',
      borderBottomStyle: 'solid',
      [`@media only screen and (max-width: 960px)`]: {
        width: '95%',
        padding: '10px 0',
      },

      '&:hover p': {
        color: theme.palette.text.primary,
      },
    },
    tableCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '20%',
    },
    textItem: {
      textDecoration: 'none',
      textOverflow: 'ellipsis',
      color: theme.palette.text.secondary,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&.hovered': {
        color: theme.palette.text.primary,
      },
    },
    amountText: {
      color: '#000',
    },
    statusTextUnpaid: {
      color: '#FF003B',
    },
    viewInvoiceText: {
      textDecorationColor: 'inherit',
      color: 'inherit',
    },
    roleSelectRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& .MuiInput-underline': {
        '&:before': {
          border: 'none !important',
        },
        '&:after': {
          border: 'none',
        },
        '& select': {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
        },
      },
    },
  }),
);
