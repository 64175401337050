import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import AddIcon from '@material-ui/icons/AddRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { AutoSizer, List } from 'react-virtualized';
import TextField from '../../TextFields/TextField';
import hidingSquirrel from '../../../static/media/hiding_squirrel.svg';
import types from '../../../utils/types/events';
import useStyles from './EventFilterEventsColumnStyle';
import CustomLoadingSpinner from '../../Spinners/CustomLoadingSpinner';

const EventFilterEventsColumn = ({
  events,
  clearModalContent,
  addEventFilter,
  selectedEvent,
  setSelectedEvent,
  hoveredEvent,
  setHoveredEvent,
  loadingEvents,
}) => {
  const [eventSearch, setEventSearch] = useState('');
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    (async () => {
      if (!eventSearch) {
        setFilteredEvents(events);
      } else {
        const transformedEventSearch = eventSearch.toLowerCase().trim();
        const filter = events.filter(({ eventName }) =>
          eventName.toLowerCase().includes(transformedEventSearch),
        );

        setFilteredEvents(filter);

        // Clear selected event if it isn't within our filtered results
        if (!filter.some(({ eventName }) => eventName === selectedEvent?.eventName)) {
          setSelectedEvent(undefined);
          setHoveredEvent(undefined);
        }
      }
    })();
  }, [eventSearch, events]);

  const handleOnChange = (e) => setEventSearch(e.target.value);

  const handleOnEventAdd = (event) => {
    const { properties, ...rest } = event;
    addEventFilter({ ...rest, propertyFilters: [] });
    clearModalContent();
  };

  const handleOnEventClick = (event) => {
    if (event === selectedEvent) {
      setSelectedEvent(undefined);
    } else {
      setSelectedEvent(event);
    }
  };

  const handleOnEventMouseEnter = (event) => {
    setHoveredEvent(event);
  };

  const handleOnEventMouseLeave = () => {
    if (selectedEvent) {
      setHoveredEvent(undefined);
    }
  };

  const classes = useStyles({ eventsCount: filteredEvents.length });

  // eslint-disable-next-line react/prop-types
  const renderEventRow = ({ index, key, style }) => {
    const event = filteredEvents[index];
    const isSelected = event === selectedEvent;
    const isHovered = event === hoveredEvent;

    return (
      <ButtonBase
        component={Box}
        key={key}
        className={clsx(classes.event, {
          selected: isSelected,
          hovered: isHovered,
        })}
        style={style}
        onClick={() => handleOnEventClick(event)}
        onMouseEnter={() => handleOnEventMouseEnter(event)}
        onMouseLeave={handleOnEventMouseLeave}
      >
        <Typography variant='body1' className={classes.eventText}>
          {event.eventName}
        </Typography>

        <Box className='iconContainer'>
          <Tooltip title='Filter By Event'>
            <IconButton
              className={classes.addButton}
              onClick={() => handleOnEventAdd(event)}
              data-testid='EventFilterEventsColumn-Event-Plus'
            >
              <AddIcon />
            </IconButton>
          </Tooltip>

          <ArrowForwardIosRoundedIcon
            fontSize='small'
            className={clsx(classes.forwardIcon, {
              selected: isSelected,
            })}
          />
        </Box>
      </ButtonBase>
    );
  };

  const renderNoRows = () => (
    <Box className={classes.noEvents}>
      <CardMedia className={classes.noEventsIcon} image={hidingSquirrel} aria-label='No Events' />
    </Box>
  );

  const renderLoading = () => (
    <CustomLoadingSpinner height={150} width={150} className={classes.loading} />
  );

  return (
    <Box className={clsx(classes.column)}>
      <TextField
        className={classes.eventNameSearch}
        value={eventSearch}
        onChange={handleOnChange}
        autoFocus
        margin='none'
        placeholder='Search Events...'
        fullWidth={false}
        InputProps={{ disableUnderline: true }}
      />

      {!!loadingEvents && renderLoading()}

      {!loadingEvents && !filteredEvents.length && renderNoRows()}

      {!loadingEvents && !!filteredEvents.length && (
        <Box className={classes.eventsContainer}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                className={classes.list}
                width={width}
                height={height}
                rowHeight={50}
                overscanRowCount={20}
                rowCount={filteredEvents.length}
                rowRenderer={renderEventRow}
              />
            )}
          </AutoSizer>
        </Box>
      )}
    </Box>
  );
};

export default EventFilterEventsColumn;

EventFilterEventsColumn.propTypes = {
  addEventFilter: PropTypes.func.isRequired,
  clearModalContent: PropTypes.func.isRequired,
  events: types.events.isRequired,
  selectedEvent: types.event,
  setSelectedEvent: PropTypes.func.isRequired,
  hoveredEvent: types.event,
  setHoveredEvent: PropTypes.func.isRequired,
  loadingEvents: PropTypes.bool,
};

EventFilterEventsColumn.defaultProps = {
  selectedEvent: undefined,
  hoveredEvent: undefined,
  loadingEvents: false,
};
