import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tabPanel: {},
  eventDetailsTabPanel: {
    ...theme.scrollbar,
    overflow: 'auto',
    padding: '30px',
  },
  label: {
    marginBottom: 18,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
}));
