import PropTypes from 'prop-types';
import React from 'react';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { format, subDays } from 'date-fns';
import RangeDatePicker from './RangeDatePicker';

import ContainedButton from '../Buttons/Button';

import styles from './RangeDatePickerPopoverStyles';

const getTriggerText = (fromDate, toDate) => {
  if (!fromDate || !toDate) {
    return 'Set Date Range';
  }

  const formatDate = (date) => format(date, 'EEE do MMM');
  return `${formatDate(fromDate)} - ${formatDate(toDate)}`;
};

const RangeDatePickerPopover = ({ fromDate, toDate, onDatesSelected }) => {
  const classes = styles();
  const currentDate = new Date();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'date-popover',
  });
  const { setOpen, isOpen } = popupState;

  const selectPreviousDateRange = (daysToSelect) => {
    const fromDateConverted = subDays(currentDate, daysToSelect - 1);
    fromDateConverted.setHours(0, 0, 0, 0);
    const toDateConverted = currentDate;
    toDateConverted.setHours(23, 59, 59);

    onDatesSelected({
      fromDate: fromDateConverted,
      toDate: toDateConverted,
    });
    setOpen(false);
  };

  return (
    <>
      <ContainedButton
        {...bindTrigger(popupState)}
        data-testid='date-popover-trigger'
        className={classes.tabButton}
      >
        <DateRangeRoundedIcon className={classes.buttonDateIcon} fontSize='small' />
        <Hidden smDown>
          {getTriggerText(fromDate, toDate)}
          {isOpen ? (
            <KeyboardArrowUpRoundedIcon className={classes.buttonArrowIcon} />
          ) : (
            <KeyboardArrowDownRoundedIcon className={classes.buttonArrowIcon} />
          )}
        </Hidden>
      </ContainedButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ top: 10 }}
        PaperProps={{ className: classes.paper }}
      >
        <Grid container className={classes.container} data-testid='date-picker-container'>
          <Grid item xs={12} md={9}>
            <RangeDatePicker
              fromDate={fromDate}
              toDate={toDate}
              disabledDays={{ after: currentDate }}
              onDatesSelected={onDatesSelected}
              toMonth={currentDate}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Grid
              container
              className={classes.buttonContainer}
              justify='flex-start'
              direction='row'
            >
              <ContainedButton
                variant='outlined'
                className={classes.button}
                onClick={() => selectPreviousDateRange(1)}
              >
                Today
              </ContainedButton>
              <ContainedButton
                variant='outlined'
                className={classes.button}
                onClick={() => selectPreviousDateRange(7)}
              >
                Last 7 Days
              </ContainedButton>
              <ContainedButton
                variant='outlined'
                className={classes.button}
                onClick={() => selectPreviousDateRange(14)}
              >
                Last 14 Days
              </ContainedButton>
              <ContainedButton
                variant='outlined'
                className={classes.button}
                onClick={() => selectPreviousDateRange(30)}
              >
                Last 30 Days
              </ContainedButton>

              <Hidden smUp>
                <Grid item xs={12} className={classes.applyContainer}>
                  <ContainedButton className={classes.applyButton} onClick={() => setOpen(false)}>
                    Apply
                  </ContainedButton>
                </Grid>
              </Hidden>

              <Button className={classes.textButton} onClick={() => selectPreviousDateRange(7)}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

RangeDatePickerPopover.propTypes = {
  fromDate: PropTypes.instanceOf(Date),
  toDate: PropTypes.instanceOf(Date),
  onDatesSelected: PropTypes.func.isRequired,
};

RangeDatePickerPopover.defaultProps = {
  fromDate: undefined,
  toDate: undefined,
};

export default RangeDatePickerPopover;
