import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useStyles from './TitledTextStyle';

const TitledText = ({ title, text, containerClass }) => {
  const classes = useStyles();

  return (
    <Box className={containerClass} data-testid='TitledText_container'>
      <Typography variant='subtitle2' color='textSecondary' className={classes.title}>
        {title}
      </Typography>
      <Typography variant='body1' color='textPrimary' className={classes.text}>
        {text}
      </Typography>
    </Box>
  );
};

export default TitledText;

TitledText.propTypes = {
  containerClass: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

TitledText.defaultProps = {
  containerClass: undefined,
  text: undefined,
};
