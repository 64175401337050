import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    root: {
      padding: '10pt 12pt',
    },
    outerField: {
      marginTop: 10,
    },
    textField: {
      height: 40,
    },
  }),
);
