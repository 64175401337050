import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import useActions from '../redux/actions/useActions';
import userOptionsActions from '../redux/actions/userOptionsActions';
import { appsSelector } from '../redux/selectors/userOptionsSelector';
import RouteComponentWrapper from './RouteComponentWrapper';

const actions = {
  setApps: userOptionsActions.setApps,
  setCurrentApp: userOptionsActions.setCurrentApp,
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { apps } = useSelector(appsSelector);
  const { setApps, setCurrentApp } = useActions(actions);

  return (
    <Route
      {...rest}
      render={(props) => (
        <RouteComponentWrapper
          Component={Component}
          apps={apps}
          setApps={setApps}
          setCurrentApp={setCurrentApp}
          {...props}
        />
      )}
    />
  );
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  ...Route.propTypes,
  component: PropTypes.func.isRequired,
};

PrivateRoute.defaultProps = {
  ...Route.defaultProps,
};
