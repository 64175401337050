import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import _ from 'lodash';

import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Box,
} from '@material-ui/core';

import ContentMissing from '../Display/ContentMissing';
import LoadingAnimation from '../Spinners/LoadingAnimation';

import crashesTypes from '../../utils/types/crashes';
import styles from './CrashesTableStyles';

const CrashesTable = ({ data, loading }) => {
  const classes = styles();

  const formatDate = (date) => {
    const formattedTime = Moment(date).local().format('HH:MMa');
    const formattedDate = Moment(date).local().format('D MMM');

    return (
      <>
        {formattedTime}
        <span className='lighterText'> | </span>
        <span className='lighterText'>{formattedDate}</span>
      </>
    );
  };

  const tableValues = [
    { header: 'Crash Message', cellDataKey: 'crashMessage' },
    { header: 'Crash Count', cellDataKey: 'crashAmountCount' },
    { header: 'Session Count', cellDataKey: 'sessionsAffectedCount' },
    { header: 'User Count', cellDataKey: 'usersAffectedCount' },
    { header: 'Anonymous Users', cellDataKey: 'anonDevicesAffectedCount' },
    { header: 'Total Affected', cellDataKey: 'totalPeopleAffectedCount' },
    {
      header: 'Last Occurrence',
      cellDataKey: 'latestOccurrence',
    },
    {
      header: 'First Occurrence',
      cellDataKey: 'earliestOccurrence',
    },
  ];

  if (data.length < 1) {
    return <ContentMissing loading={loading} loadingMessage='Loading Crashes Data...' />;
  }

  const renderHeaders = () => {
    return tableValues.map((column) => (
      <TableCell className={classes.tableHeadCell}>{column.header}</TableCell>
    ));
  };

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow>{renderHeaders()}</TableRow>
        </TableHead>
        {!loading && (
          <TableBody>
            {_.map(_.orderBy(data, ['crashAmountCount'], ['desc']), (row, index) => (
              <TableRow className={classes.tableRow} key={index}>
                <TableCell className={`messageCell ${classes.tableBodyCell}`}>
                  {row.crashMessage}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>{row.crashAmountCount}</TableCell>
                <TableCell className={classes.tableBodyCell}>{row.sessionsAffectedCount}</TableCell>
                <TableCell className={classes.tableBodyCell}>{row.usersAffectedCount}</TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {row.anonDevicesAffectedCount}
                </TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {row.totalPeopleAffectedCount}
                </TableCell>
                <TableCell className={`dateCell ${classes.tableBodyCell}`}>
                  {formatDate(row.latestOccurrence)}
                </TableCell>
                <TableCell className={`dateCell ${classes.tableBodyCell}`}>
                  {formatDate(row.earliestOccurrence)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {loading && (
        <Box className={classes.spinnerContainer}>
          <LoadingAnimation height='15vh' width='15vw' />
        </Box>
      )}
    </TableContainer>
  );
};

CrashesTable.propTypes = {
  data: PropTypes.arrayOf(crashesTypes.crashesMetadata),
  loading: PropTypes.bool,
};

CrashesTable.defaultProps = {
  data: [],
  loading: false,
};

export default CrashesTable;
