import { shape, string, arrayOf, func } from 'prop-types';

const property = string;
const properties = arrayOf(property);

const event = shape({
  eventName: string,
  properties,
});
const events = arrayOf(event);

const propertyFilter = shape({
  key: string.isRequired,
  value: string,
});
const eventFilter = shape({
  eventName: string.isRequired,
  propertyFilters: arrayOf(propertyFilter),
});
const eventFilters = arrayOf(eventFilter);

const eventFilterFunctions = shape({
  addEventFilter: func,
  removeEventFilter: func,
  updateEventFilterProperty: func,
});

export default {
  property,
  properties,
  event,
  events,
  propertyFilter,
  eventFilter,
  eventFilters,
  eventFilterFunctions,
};
