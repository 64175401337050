import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PageLayout from '../../components/Display/PageLayout';
import StatCounter from '../../components/Display/StatCounter';
import dashboardAPI from '../../api/v1/dashboard/dashboard';
import userOptionsActions from '../../redux/actions/userOptionsActions';
import LineGraph from '../../components/Graph/LineGraph';
import BarchartGraph from '../../components/Graph/BarchartGraph';
import BarchartMultiLineGraph from '../../components/Graph/BarchartMultiLineGraph';
import DisplayError from '../../components/Generic/DisplayError';
import userOptionsSelector from '../../redux/selectors/userOptionsSelector';
import useActions from '../../redux/actions/useActions';
import hiding from '../../static/media/hiding_squirrel.svg';
import styles from './_dashboard.module.scss';
import ModalContext from '../../contexts/modalContext';
import AppKeyModal from '../../components/Modals/AppKeyModal';
import { NO_DATA_BODY_MESSAGE, NO_DATA_TITLE_MESSAGE } from '../../messages/info';

const Dashboard = () => {
  const { fromDate, toDate, apps, currentAppId } = useSelector(userOptionsSelector);
  const { setSelectedDateRange } = useActions(userOptionsActions);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [activeUserStats, setActiveUserStats] = useState(0);
  const [sessionStats, setSessionStats] = useState(0);
  const [averageSessionLengthStats, setAverageSessionLengthStats] = useState('0m');
  const [crashCountStats, setCrashCountStats] = useState(0);

  const [userCountGraphData, setUserCountGraphData] = useState([]);
  const [sessionCountGraphData, setSessionCountGraphData] = useState([]);
  const [hourlyUsageGraphData, setHourlyUsageGraphData] = useState([]);
  const [appVersionGraphData, setAppVersionGraphData] = useState([]);

  const { setModalContent, clearModalContent } = useContext(ModalContext);

  const isProduction = process.env.REACT_APP_SHOW_DEBUGGING_INFO === 'false';

  useEffect(() => {
    (async () => {
      try {
        setError('');
        setLoading(true);
        const keys = [
          'USER_COUNT',
          'SESSION_COUNT',
          'AVERAGE_SESSION_DURATION',
          'CRASH_COUNT',
          'USER_COUNT_GRAPH',
          'SESSION_COUNT_GRAPH',
          'HOURLY_SESSIONS_GRAPH',
          'VERSION_ADOPTION_GRAPH',
        ];
        const payload = await dashboardAPI.getDashboardData(currentAppId, fromDate, toDate, keys);
        const {
          AVERAGE_SESSION_DURATION,
          SESSION_COUNT,
          CRASH_COUNT,
          USER_COUNT,
          USER_COUNT_GRAPH,
          SESSION_COUNT_GRAPH,
          HOURLY_SESSIONS_GRAPH,
          VERSION_ADOPTION_GRAPH,
        } = payload.data.dashboardComponents;

        setActiveUserStats(USER_COUNT);
        setSessionStats(SESSION_COUNT);
        setAverageSessionLengthStats(AVERAGE_SESSION_DURATION);
        setCrashCountStats(CRASH_COUNT);

        setUserCountGraphData(USER_COUNT_GRAPH ? USER_COUNT_GRAPH.value : []);
        setSessionCountGraphData(SESSION_COUNT_GRAPH ? SESSION_COUNT_GRAPH.value : []);
        setHourlyUsageGraphData(HOURLY_SESSIONS_GRAPH ? HOURLY_SESSIONS_GRAPH.value : []);
        setAppVersionGraphData(
          VERSION_ADOPTION_GRAPH?.percentage ? VERSION_ADOPTION_GRAPH.percentage : [],
        );
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [fromDate, toDate]);

  const showAppKeyModal = () => {
    setModalContent(<AppKeyModal app={{ id: currentAppId }} onModalClose={clearModalContent} />);
  };

  const viewAppKeyLink = (
    <Link onClick={showAppKeyModal} variant='none' className={styles.viewAppKeyButton}>
      View App Key
    </Link>
  );

  // eslint-disable-next-line react/prop-types
  const GoogleTagManagerContainer = ({ children }) => {
    if (!isProduction) {
      return <>{children}</>;
    }
    return (
      // id conv2 is a Google Tag Manager for Marketing
      <div id='green'>{children}</div>
    );
  };

  return (
    <PageLayout
      isLoading={loading}
      error={error}
      title={sessionStats.value ? 'Dashboard' : null}
      fromDate={fromDate}
      toDate={toDate}
      onDatesSelected={setSelectedDateRange}
      apps={apps}
      currentAppId={currentAppId}
      highlightTab='Dashboard'
      centerContentVertical={!sessionStats.value}
      centerContentHorizontal={!sessionStats.value}
    >
      {!sessionStats.value ? (
        <DisplayError
          title={NO_DATA_TITLE_MESSAGE}
          description={NO_DATA_BODY_MESSAGE}
          additionalContent={viewAppKeyLink}
          linkLocation='instructions'
          linkText='See Instructions'
          image={hiding}
        />
      ) : (
        <GoogleTagManagerContainer>
          <Grid container spacing={4} className={styles.root}>
            <Grid item xs={12} sm={6} md={3} className={styles.statCounter}>
              <StatCounter
                label='Users'
                value={activeUserStats.value}
                percentChange={activeUserStats.percentageChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={styles.statCounter}>
              <StatCounter
                label='Sessions'
                value={sessionStats.value}
                percentChange={sessionStats.percentageChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={styles.statCounter}>
              <StatCounter
                label='Crash Count'
                value={crashCountStats.value}
                percentChange={crashCountStats.percentageChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} className={styles.statCounter}>
              <StatCounter
                label='Average Session Length'
                value={averageSessionLengthStats.value}
                percentChange={averageSessionLengthStats.percentageChange}
              />
            </Grid>

            <Grid item xs={12} md={6} className={styles.graphItem}>
              <Grid item className={styles.subUsersGraph}>
                <LineGraph data={userCountGraphData} title='Users' />
              </Grid>

              <Grid item className={styles.subGraphBarChart}>
                <BarchartGraph data={hourlyUsageGraphData} title='Hourly Sessions' />
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} className={styles.graphItem}>
              <Grid item className={styles.subUsersGraph}>
                <LineGraph data={sessionCountGraphData} title='Sessions' />
              </Grid>

              <Grid item className={styles.subGraphBarChart}>
                <BarchartMultiLineGraph data={appVersionGraphData} title='App Version' />
              </Grid>
            </Grid>
          </Grid>
        </GoogleTagManagerContainer>
      )}
    </PageLayout>
  );
};

export default Dashboard;
