import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveFunnel } from '@nivo/funnel';
import GraphHolder from './GraphHolder';

const buildData = (data) =>
  data.map((dataObject) => ({
    id: dataObject.stepNum,
    value: dataObject.sessionCount,
    label: dataObject.stepName,
  }));

const FunnelGraph = ({ data, title }) => {
  return (
    <GraphHolder title={title}>
      <ResponsiveFunnel
        data={buildData(data)}
        margin={{ right: 20, bottom: 20, left: 20, top: 20 }}
        direction='horizontal'
        enableLabel
        shapeBlending={0.5}
        // valueFormat=' >-.4s' - Formatting if Required
        colors={{ scheme: 'spectral' }}
        borderWidth={20}
        borderColor={{ from: 'color', modifiers: [] }}
        borderOpacity={0.5}
        labelColor='white'
        beforeSeparatorLength={50}
        beforeSeparatorOffset={20}
        afterSeparatorLength={50}
        currentPartSizeExtension={10}
        currentBorderWidth={0}
        motionConfig='gentle'
      />
    </GraphHolder>
  );
};

FunnelGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  title: PropTypes.string.isRequired,
};

export default FunnelGraph;
