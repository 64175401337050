import React, { createContext } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const addErrorNotification = (value) => {
    enqueueSnackbar(value, {
      variant: 'error',
    });
  };

  const addWarningNotification = (value) => {
    enqueueSnackbar(value, {
      variant: 'warning',
    });
  };

  const addSuccessNotification = (value) => {
    enqueueSnackbar(value, {
      variant: 'success',
    });
  };

  const addInfoNotification = (value) => {
    enqueueSnackbar(value, {
      variant: 'info',
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        addErrorNotification,
        addWarningNotification,
        addSuccessNotification,
        addInfoNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
