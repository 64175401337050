import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeButton: {
    right: -15,
  },
  headerTitle: {
    fontWeight: 'bold',
    flex: 1,
    color: theme.palette.primary.main,
    fontSize: 20,
  },
}));
