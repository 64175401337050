export const USER_OPTIONS_CLEAR_FORM = 'user_options_clear_form';
export const USER_OPTIONS_SET_APPS = 'user_options_update_apps';
export const USER_OPTIONS_SET_CURRENT_APP = 'user_options_set_current_app';
export const USER_OPTIONS_SELECT_DATE_RANGE = 'user_options_select_date_range';
export const USER_OPTIONS_CLEAR_SELECTED_DATE = 'user_options_clear_selected_date';

const clearForm = () => ({
  type: USER_OPTIONS_CLEAR_FORM,
});

const setCurrentApp = ({ appId, appKey }) => {
  return {
    type: USER_OPTIONS_SET_CURRENT_APP,
    appId,
    appKey,
  };
};

const setApps = (apps) => ({
  type: USER_OPTIONS_SET_APPS,
  apps,
});

const setSelectedDateRange = (selectedDateRange) => {
  let formattedDateRange = selectedDateRange;

  if (formattedDateRange.fromDate instanceof Date || formattedDateRange.toDate instanceof Date) {
    formattedDateRange = {
      fromDate: selectedDateRange.fromDate.toString(),
      toDate: selectedDateRange.toDate.toString(),
    };
  }

  return {
    type: USER_OPTIONS_SELECT_DATE_RANGE,
    selectedDateRange: formattedDateRange,
  };
};

const clearSelectedDateRange = () => ({ type: USER_OPTIONS_CLEAR_SELECTED_DATE });

export default {
  clearForm,
  setCurrentApp,
  setApps,
  setSelectedDateRange,
  clearSelectedDateRange,
};
