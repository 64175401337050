export default {
  SIGN_UP_SUCCESS: 'Sign up successful, please check your email for a verification link',
  ADD_NEW_APP_INFO:
    'This app will be a part of your organization. Managers will have access to it. You can invite other members to join the app without having access to other apps within your Organisation.',
  EDIT_APP_NAME_INFO:
    'You’re about to change your App name. Make sure that your team will know the name of it and have an access to it.',
  DELETE_APP_INFO:
    'This process cannot be undone and all recorded session data will be lost. If you want to proceed, enter your app name into the input below.',
};

export const NO_SESSIONS_FOUND_MESSAGE = 'Please check the date and filters and try again.';
export const NO_DATA_TITLE_MESSAGE = 'No data to go through!';
export const NO_DATA_BODY_MESSAGE =
  'Connect your app in less than 5 MINUTES!' +
  ' Follow instructions to connect your app with our analytics tools.';
export const NO_EVENTS_TITLE_MESSAGE = `We haven't found any events`;
export const NO_EVENTS_BODY_MESSAGE = 'Please check the dates and filters and try again';
