import React from 'react';
import PropTypes from 'prop-types';
import MaterialButton from '@material-ui/core/Button';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) =>
  createStyles({
    textButton: {
      textDecoration: 'underline',
      alignSelf: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const UnderlinedButton = ({ onClick, label, ...rest }) => (
  <Button className={useStyle().textButton} onClick={onClick} {...rest}>
    {label}
  </Button>
);

export default UnderlinedButton;

UnderlinedButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  ...MaterialButton.propTypes,
};
