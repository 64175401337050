import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import Moment from 'moment';
import { differenceInWeeks } from 'date-fns';
import PageLayout from '../../components/Display/PageLayout';
import StatCounter from '../../components/Display/StatCounter';
import userOptionsActions from '../../redux/actions/userOptionsActions';
import userOptionsSelector from '../../redux/selectors/userOptionsSelector';
import useActions from '../../redux/actions/useActions';
import useStyles from './adminStyles';
import adminApi from '../../api/v1/admin/adminApi';
import { numberWithCommas } from '../../utils/general/generalFunctions';
import Button from '../../components/Buttons/Button';

const generateReport = (data) => {
  const uniqueUsers = _.uniqBy(data, (x) => x.email);
  const uniqueApps = _.uniqBy(data, (x) => x.organisationId && x.appName);
  const topOrganisation = _.maxBy(uniqueApps, (x) => x.totalSessionCount);
  const safeOrganisation = (value) => (topOrganisation ? topOrganisation[value] : '');
  return {
    totalUsers: uniqueUsers.length,
    totalOrganisations: _.uniqBy(data, (x) => x.organisationId).length,
    totalSessions: _.sumBy(uniqueApps, (x) => x.totalSessionCount),
    totalSessionsLastMonth: _.sumBy(uniqueApps, (x) => x.lastMonthSessionCount),
    totalSessionsLastWeek: _.sumBy(uniqueApps, (x) => x.lastWeekSessionCount),
    usersWhoHaveAnApp: _.filter(uniqueUsers, (x) => x.appName).length,
    usersWithSessions: _.filter(uniqueApps, (x) => x.totalSessionCount).length,
    usersWithSessionsInLastMonth: _.filter(uniqueApps, (x) => x.lastMonthSessionCount).length,
    topOrgName: safeOrganisation('organisation'),
    topOrgAppName: safeOrganisation('appName'),
    topOrgTotalSession: safeOrganisation('totalSessionCount'),
    topOrgMonthSessions: safeOrganisation('lastMonthSessionCount'),
    totalLogins: _.sumBy(uniqueUsers, (x) => x.totalSignInCount),
    totalActiveUsersThisWeek: _.filter(uniqueUsers, (x) => {
      if (!x.lastSignInTimestamp) return false;
      const differenceInWeek = differenceInWeeks(new Date(), new Date(x.lastSignInTimestamp));
      return differenceInWeek === 0;
    }).length,
    redUserCount: _.filter(uniqueUsers, (x) => !x.appName).length,
    yellowUserCount: _.filter(uniqueUsers, (x) => x.appName && !x.totalSessionCount).length,
    greenUserCount: _.filter(uniqueUsers, (x) => x.appName && x.totalSessionCount).length,
  };
};

const Admin = () => {
  const classes = useStyles();
  const { fromDate, toDate, apps, currentAppId } = useSelector(userOptionsSelector);
  const { setSelectedDateRange } = useActions(userOptionsActions);

  const [loading, setLoading] = useState(true);
  const [greenUsersOnly, setGreenUsersOnly] = useState(false);
  const [yellowUsersOnly, setYellowUsersOnly] = useState(false);
  const [redUsersOnly, setRedUsersOnly] = useState(false);
  const [lastWeekUsersOnly, setLastWeekUsersOnly] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setError('');
        setLoading(true);
        const payload = await adminApi.getAdminDashboard();
        setData(payload.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [fromDate, toDate]);

  const reportData = generateReport(data);
  const renderGridItem = (label, value) => {
    return (
      <Grid item style={{ paddingTop: 5, paddingBottom: 5 }}>
        <StatCounter label={label} value={value} />
      </Grid>
    );
  };

  return (
    <PageLayout
      isLoading={loading}
      error={error}
      fromDate={fromDate}
      toDate={toDate}
      onDatesSelected={setSelectedDateRange}
      apps={apps}
      currentAppId={currentAppId}
      highlightTab='Admin'
    >
      {/* I Realise this is terrible but its Admin Only (e.g. Internal) */}
      <div style={{ zIndex: 0, position: 'absolute', top: 7, left: 150 }}>
        <Button
          style={{ backgroundColor: 'green', marginTop: 10, marginBottom: 10 }}
          onClick={() => {
            setGreenUsersOnly(!greenUsersOnly);
            setYellowUsersOnly(false);
            setRedUsersOnly(false);
          }}
        >
          Green Users
        </Button>
        <Button
          style={{ backgroundColor: 'yellow', color: 'black', marginTop: 10, marginBottom: 10 }}
          onClick={() => {
            setGreenUsersOnly(false);
            setYellowUsersOnly(!yellowUsersOnly);
            setRedUsersOnly(false);
          }}
        >
          Yellow Users
        </Button>
        <Button
          style={{ backgroundColor: 'red', marginTop: 10, marginBottom: 10 }}
          onClick={() => {
            setGreenUsersOnly(false);
            setYellowUsersOnly(false);
            setRedUsersOnly(!redUsersOnly);
          }}
        >
          Red Users
        </Button>
        <Button
          style={{ backgroundColor: 'cyan', color: 'black', marginTop: 10, marginBottom: 10 }}
          onClick={() => {
            setLastWeekUsersOnly(!lastWeekUsersOnly);
          }}
        >
          {`Last Weeks Users - (${lastWeekUsersOnly})`}
        </Button>
      </div>
      <Grid container className={classes.root}>
        <Grid container style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Grid container style={{ flex: 1 }}>
            <Grid item style={{ flex: 1, padding: 5 }}>
              {renderGridItem('Total Signed Up Users', reportData.totalUsers)}
              {renderGridItem(
                'Red / Yellow / Green',
                `R:${reportData.redUserCount} / Y: ${reportData.yellowUserCount} / G: ${reportData.greenUserCount}`,
              )}
              {renderGridItem(
                'Apps With Sessions / Last Month Session',
                `${reportData.usersWithSessions} / ${reportData.usersWithSessionsInLastMonth}`,
              )}
            </Grid>
            <Grid item style={{ flex: 1, padding: 5 }}>
              {renderGridItem('Total Organisations', reportData.totalOrganisations)}
              {renderGridItem('Total Sessions', reportData.totalSessions)}
              {renderGridItem('Total Sessions Last Month', reportData.totalSessionsLastMonth)}
            </Grid>
            <Grid item style={{ flex: 1, padding: 5 }}>
              {renderGridItem('Active Users This Week', reportData.totalActiveUsersThisWeek)}
              {renderGridItem('Total Logins', reportData.totalLogins)}
              {renderGridItem('Total Sessions Last Week', reportData.totalSessionsLastWeek)}
            </Grid>
          </Grid>

          <Grid
            container
            style={{ maxHeight: '500px', overflow: 'auto', backgroundColor: 'white' }}
          >
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Signup Timestamp</TableCell>
                    <TableCell>Last Sign-in</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Organisation</TableCell>
                    <TableCell>App Name</TableCell>
                    <TableCell>Total Sign-in Count</TableCell>
                    <TableCell>Total Session Count</TableCell>
                    <TableCell>Last Month Session Count</TableCell>
                    <TableCell>Last Week Session Count</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {_.map(data, (item) => {
                    // eslint-disable-next-line no-nested-ternary
                    const rowColor = !item.appName
                      ? 'red'
                      : item.totalSessionCount
                      ? 'green'
                      : 'orange';

                    if (greenUsersOnly && rowColor !== 'green') return null;
                    if (yellowUsersOnly && rowColor !== 'orange') return null;
                    if (redUsersOnly && rowColor !== 'red') return null;
                    if (lastWeekUsersOnly && Moment().diff(item.signUpTimestamp, 'days') >= 7)
                      return null;
                    const renderRow = (value) => (
                      <TableCell style={{ color: rowColor }}>{value}</TableCell>
                    );
                    return (
                      <TableRow>
                        {renderRow(Moment(item.signUpTimestamp).local().format('D MMM - hh:mmA'))}
                        {renderRow(
                          item.lastSignInTimestamp
                            ? Moment(item.lastSignInTimestamp).local().format('D MMM - hh:mmA')
                            : '',
                        )}
                        {renderRow(item.email)}
                        {renderRow(item.organisation)}
                        {renderRow(item.appName)}
                        {renderRow(numberWithCommas(item.totalSignInCount))}
                        {renderRow(numberWithCommas(item.totalSessionCount))}
                        {renderRow(numberWithCommas(item.lastMonthSessionCount))}
                        {renderRow(numberWithCommas(item.lastWeekSessionCount))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default Admin;
