import * as yup from 'yup';
import { newPasswordValidation } from './userAuthValidation';

// eslint-disable-next-line import/prefer-default-export
export const updatePasswordValidation = yup.object().shape({
  currentPassword: newPasswordValidation.required('Password is required'),
  newPassword: newPasswordValidation.required('New password is required'),
  passwordConfirmation: yup
    .string()
    .trim()
    .oneOf([yup.ref('newPassword')], 'Passwords do not match')
    .required('Password confirmation is required'),
});
