import moment from 'moment';
import { HTTP } from '../../index';
import apiUrls from '../../apiUrls';

const { CRASHES_OVERVIEW } = apiUrls;

const getCrashesOverview = (appId, fromDate, toDate) =>
  HTTP.waApi.get(CRASHES_OVERVIEW(appId), {
    params: {
      from: fromDate ? moment(fromDate).format() : undefined,
      to: toDate ? moment(toDate).format() : undefined,
    },
  });

export default {
  getCrashesOverview,
};
