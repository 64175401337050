import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import AlertArray from '../AlertArray';
import ContentMissing from '../ContentMissing';
import Header from './SubComponents/SessionDetailHeader';
import Metadata from './SubComponents/SessionMetadata';
import SessionEventDetails from './SubComponents/SessionEventDetails';
import SessionTimeline from './SubComponents/SessionTimeline';

import sessionTypes from '../../../utils/types/session';
import { useSessionDetails } from '../../../hooks/sessionHooks';
import useStyles from './SessionDetailStyle';

const SessionDetail = ({ sessionMetadata = {}, handleClose }) => {
  const eventDetailsTabRef = useRef();

  const { error, loadingDetails, sessionDetails } = useSessionDetails({
    sessionId: sessionMetadata.id,
  });
  const [selectedTimelineItem, setSelectedTimelineItem] = useState(undefined);

  const onTimelineItemClick = (timelineItem) => {
    if (timelineItem.id === selectedTimelineItem?.id) {
      setSelectedTimelineItem(undefined);
      eventDetailsTabRef.current.selectTab(0);
    } else {
      setSelectedTimelineItem(timelineItem);
      eventDetailsTabRef.current.selectTab(1);
    }
  };

  const onClose = () => {
    setSelectedTimelineItem(undefined);

    if (handleClose) handleClose();
  };

  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Header
        handleClose={onClose}
        sessionNumber={sessionMetadata.session}
        username={sessionMetadata.username}
      />

      <Metadata
        duration={sessionMetadata.duration}
        sessionStart={sessionMetadata.sessionStart}
        sessionEnd={sessionDetails?.session?.endTimestamp}
      />

      <Divider className={classes.divider} />

      {/* Error Display */}
      {error && !loadingDetails && (
        <Box className={classes.errorContainer}>
          <AlertArray alerts={[error]} severity='error' />
          <ContentMissing className={classes.contentMissing} />
        </Box>
      )}

      {/* Loading Display */}
      {!!loadingDetails && (
        <ContentMissing
          className={classes.contentMissing}
          loading
          loadingMessage='Loading Timeline...'
        />
      )}

      {/* Session Details */}
      {!loadingDetails && !error && (
        <Box className={classes.sessionDetailsRoot}>
          <Box className={classes.timelineContainer}>
            <Typography variant='h5' className={classes.timelineTitle}>
              Timeline
            </Typography>

            <SessionTimeline
              timeline={sessionDetails?.timeline}
              selectedItemId={selectedTimelineItem?.id}
              onItemClick={onTimelineItemClick}
            />
          </Box>

          <Box className={classes.detailsTabContainer}>
            <SessionEventDetails
              deviceInfo={sessionDetails?.deviceInfo}
              sdkVersion={sessionDetails?.session?.sdkVersion}
              ref={eventDetailsTabRef}
              timelineItem={selectedTimelineItem}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

SessionDetail.propTypes = {
  sessionMetadata: sessionTypes.sessionMetadata.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SessionDetail;
