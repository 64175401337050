import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CardMedia from '@material-ui/core/CardMedia';

import styles from './TopNavigationStyle';
import logo from '../../../static/media/logo.svg';
import DefaultNavigationItem from '../../Buttons/DefaultNavigationItem';
import { logout } from '../../../utils/auth/auth';
import RangeDatePickerPopover from '../../Pickers/RangeDatePickerPopover';
import DropDownPicker from '../../Pickers/DropDownPicker';
import appsTypes from '../../../utils/types/apps';

const TopDesktopNavigation = ({
  fromDate,
  toDate,
  onDatesSelected,
  highlightTab,
  apps,
  loading,
  currentAppId,
  setLocalAppId,
}) => {
  const classes = styles();

  const renderAppLogo = () => (
    <Link to='/' className={classes.appIconContainerDesktop} data-testid='TopNavigation-app-icon'>
      <CardMedia image={logo} className={classes.logo} data-testid='contentMissing' />
    </Link>
  );

  const appsValues = apps.map((item) => ({ value: item.id, text: item.appName }));

  const renderNavigationItems = () => (
    <div
      className={classes.navItemsContainer}
      data-testid='TopNavigation-navigation-items-container'
    >
      <DefaultNavigationItem key='dashboard' label='Dashboard' page='/' activePage={highlightTab} />
      <DefaultNavigationItem
        key='session'
        label='Sessions'
        page='/sessions'
        activePage={highlightTab}
      />
      <DefaultNavigationItem
        key='crashes'
        label='Crashes'
        page='/crashes'
        activePage={highlightTab}
      />
      <DefaultNavigationItem key='events' label='Events' page='/events' activePage={highlightTab} />
      <DefaultNavigationItem
        key='funnels'
        label='Funnels'
        page='/funnels'
        activePage={highlightTab}
      />
      <DefaultNavigationItem
        key='organization'
        label='Organization'
        page='/organization'
        activePage={highlightTab}
      />
    </div>
  );

  const renderActionItems = () => (
    <div
      className={classes.actionItemsContainer}
      data-testid='TopNavigation-action-items-container'
    >
      <RangeDatePickerPopover
        fromDate={fromDate}
        toDate={toDate}
        onDatesSelected={onDatesSelected}
      />

      <DropDownPicker
        value={currentAppId}
        items={appsValues}
        updateItem={setLocalAppId}
        buttonClass={classes.changeAppDropDownButton}
        itemClass={classes.changeAppDropDownMenuItem}
        loading={loading}
        spinnerSize={20}
      />

      <DefaultNavigationItem
        key='contactus'
        label='Contact Us'
        page='/contactus'
        activePage={highlightTab}
        dontNavigate
        onClick={() => {
          window.open('mailto:support@forage-analytics.com?subject=Forage%20Support%20Request');
        }}
      />
      <DefaultNavigationItem key='sign_out' label='Sign out' muted onClick={logout} activePage='' />
    </div>
  );

  return (
    <div className={classes.mainContainerDesktop} data-testid='TopNavigation-desktop-container'>
      {renderAppLogo()}
      {renderNavigationItems()}
      {renderActionItems()}
    </div>
  );
};

TopDesktopNavigation.propTypes = {
  fromDate: PropTypes.instanceOf(Date).isRequired,
  toDate: PropTypes.instanceOf(Date).isRequired,
  onDatesSelected: PropTypes.func.isRequired,
  highlightTab: PropTypes.string.isRequired,
  apps: appsTypes.apps.isRequired,
  currentAppId: PropTypes.string.isRequired,
  setLocalAppId: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TopDesktopNavigation;
