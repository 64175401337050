import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Loading from '../Spinners/LoadingSpinner';
import sadSquirrel from '../../static/media/sadSquirrel.png';
import styles from './ContentMissingStyle';

const ContentMissing = ({ loading, loadingMessage, className }) => {
  const classes = styles();
  return (
    <Container className={clsx(classes.squirrelImageContainer, className)}>
      <CardMedia
        image={sadSquirrel}
        className={classes.squirrelImage}
        data-testid='contentMissing'
      />
      {!!loading && (
        <>
          <Loading aria-label='loading content' className={classes.loadingSpinner} />
          <Typography variant='h5' className={classes.loadingMessage}>
            {loadingMessage}
          </Typography>
        </>
      )}
    </Container>
  );
};

export default ContentMissing;

ContentMissing.propTypes = {
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  className: PropTypes.string,
};

ContentMissing.defaultProps = {
  loading: false,
  loadingMessage: 'Loading Content...',
  className: undefined,
};
