import { makeStyles, createStyles } from '@material-ui/core/styles';
import { sidebarOpenWidth } from '../../Display/SidebarStyle';

const horizontalPadding = '12pt';
export default makeStyles((theme) =>
  createStyles({
    title: {
      padding: `10pt 0 5pt ${horizontalPadding}`,
    },
    eventsRoot: {
      padding: `10pt ${horizontalPadding}`,
      minWidth: `calc(${sidebarOpenWidth} - 20pt)`,
    },
    eventRow: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .eventText': {
        flexGrow: 1,
        whiteSpace: 'normal',
        color: theme.palette.text.primary,
      },

      '& .eventRemoveIcon': {
        cursor: 'pointer',
        padding: '5pt',
        borderRadius: '50%',
        color: theme.palette.text.secondary,
      },
      '& .eventRemoveIcon:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
      '& .eventRemoveIcon:active': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
    eventProperty: {
      color: '#8D8D8D',
      fontSize: '15px',
    },
    propertyFilter: {
      fontSize: '15px',

      '& input': {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    addEventRoot: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `15pt ${horizontalPadding}`,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.05)',
      },
      '&:active': {
        backgroundColor: 'rgba(0,0,0,0.08)',
      },
    },
    addEventText: {
      fontSize: 16,
      color: theme.palette.text.primary,
      paddingLeft: 10,
    },
  }),
);
