import { makeStyles, createStyles } from '@material-ui/core/styles';

const headerHeight = '60pt';

export default makeStyles(() =>
  createStyles({
    root: {
      height: `calc(95vh - ${headerHeight})`,
      overflow: 'hidden',
    },
  }),
);
