import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    root: {
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '10%',
      width: '50%',

      padding: '20pt 0',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      marginBottom: '20pt',
      textAlign: 'center',
    },
    message: {
      marginBottom: '20pt',
      textAlign: 'center',
    },
    loadingSpinner: {},
  }),
);
