import PropTypes from 'prop-types';
import React from 'react';

import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/CloseRounded';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useStyles from './SessionDetailHeaderStyle';

const Header = ({ sessionNumber, username, handleClose }) => {
  const classes = useStyles();

  return (
    <Box className={classes.headerContainer}>
      <Typography variant='h5' className={classes.headerTitle}>
        {`Session #${sessionNumber} - ${username || 'Anonymous'}`}
      </Typography>

      <IconButton
        className={classes.closeButton}
        color='textPrimary'
        aria-label='close selected session'
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default Header;

Header.propTypes = {
  handleClose: PropTypes.func.isRequired,
  sessionNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  username: PropTypes.string,
};

Header.defaultProps = {
  sessionNumber: '',
  username: 'Anonymous',
};
