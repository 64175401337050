export default {
  SIGN_IN: '/auth/sign-in',
  SIGN_UP: '/organisation',
  AUTHORISED_APPS: '/apps/authorised',
  APP_KEY: (id) => `/apps/${id}/key`,
  ADD_NEW_APP: '/apps',
  DELETE_APP: (id) => `/apps/${id}`,
  DASHBOARD: '/dashboard',
  SESSIONS: '/sessions',
  SESSION_TIMELINE: (id) => `/sessions/${id}/timeline`,
  EVENTS: (appId) => `/events/uniques/${appId}`,
  EVENT_OVERVIEW: (appId) => `/events/overview/${appId}`,
  ORGANISATION_OVERVIEW: 'organisation/overview',
  ORGANISATION_BILLING: '/organisation/billing',
  PROFILE_DETAILS: '/customer',
  UPDATE_PROFILE: '/customer/update-profile',
  UPDATE_PASSWORD: '/auth/update-password',
  DELETE_USER: (id) => `/customer/${id}`,
  ADD_NEW_USER: '/customer',
  SEND_NEW_USER_CONFIRMATION: '/customer/resend-invite',
  CHANGE_ROLE: '/customer/change-role',
  CRASHES_OVERVIEW: (appId) => `/crashes/overview/${appId}`,
  ADMIN_DASHBOARD: '/admin/dashboard',
  FUNNELS_EVENTS_LIST: (appId) => `/funnel/events/${appId}`,
  FUNNELS_GET_FUNNEL: (appId) => `/funnel/getFunnel/${appId}`,
  FUNNELS_GET_SAVED_FUNNELS: (appId) => `/funnel/${appId}`,
  FUNNELS_SAVE_FUNNEL: (appId) => `/funnel/${appId}`,
  FUNNELS_DELETE_FUNNEL: (funnelId) => `/funnel/${funnelId}`,
};
