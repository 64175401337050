import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';

import { Box, Typography, TableRow, ButtonBase, NativeSelect } from '@material-ui/core';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import useNotifications from '../../../contexts/notificationContext';

import * as OrganisationTypes from '../../../utils/types/organisation';
import CustomerTypes from '../../../utils/types/customer';

import { HTTP } from '../../../api';
import apiUrls from '../../../api/apiUrls';

import styles from './TeamMemberItemStyle';

const canDeleteUser = (currentUser, selectedEmail, selectedRole) => {
  // Check user role
  if (currentUser?.role === 'OWNER' || currentUser?.role === 'ADMIN') {
    // Check selected role
    if (selectedRole !== 'OWNER') {
      // Check not deleting current user
      if (selectedEmail !== currentUser?.email) {
        return true;
      }
    }
  }
  return false;
};

const TeamMemberItem = ({
  member,
  currentUser,
  onTeamMemberNotVerifiedIconPress,
  onUserRoleChange,
  onDeleteUser,
  isHeading = false,
}) => {
  const { addSuccessNotification, addErrorNotification } = useNotifications();
  const [hovered, setHovered] = useState(false);
  const { id: memberId, firstName, lastName, email, emailVerified, role } = member;
  const isCurrentUser = email === currentUser?.email;
  const isAdminOwner = currentUser?.role !== 'USER';
  const shouldShowDeleteButton = hovered && canDeleteUser(currentUser, email, role);

  const onMouseEnter = () => {
    if (isHeading) return;
    setHovered(true);
  };

  const onMouseLeave = () => {
    if (isHeading) return;
    setHovered(false);
  };

  const onDeletePress = () => {
    onDeleteUser(memberId);
  };

  const onRoleChange = async (event) => {
    const { value } = event.target;
    try {
      await HTTP.waApi.patch(apiUrls.CHANGE_ROLE, { emailAddress: email, role: value });
      addSuccessNotification('User Permission Changed');
      onUserRoleChange();
    } catch (error) {
      addErrorNotification(error.message);
    }
  };

  const classes = styles();

  return (
    <TableRow
      component={Box}
      className={clsx(classes.base, {
        [classes.root]: isHeading,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box className={clsx(classes.tableCell, { hovered })}>
        <Typography variant='body1' className={classes.textItem}>
          {`${firstName} ${lastName}`}
        </Typography>
      </Box>

      <Box className={clsx(classes.tableCell, { hovered })} style={{ width: '40%' }}>
        <Typography variant='body1' className={classes.textItem}>
          {email}
        </Typography>

        {!emailVerified && isAdminOwner && (
          <ButtonBase
            onClick={() => onTeamMemberNotVerifiedIconPress(member)}
            disableRipple
            disableTouchRipple
            aria-label='Resend Email'
          >
            <ReportProblemOutlinedIcon
              fontSize='small'
              className={classes.icon}
              style={{ marginLeft: 5, color: '#FF003B' }}
              data-testid='TeamMemberItem-emailVerifiedIcon'
            />
          </ButtonBase>
        )}
      </Box>

      <Box
        className={clsx(classes.roleSelectRow, classes.textItem, { hovered })}
        style={{ width: '20%' }}
      >
        {role === 'OWNER' || !hovered || !isAdminOwner ? (
          <Typography
            variant='body1'
            className={clsx(classes.textItem, { hovered })}
            data-testid='TeamMemberItem_roleText'
          >
            {role}
          </Typography>
        ) : (
          <Box>
            <NativeSelect
              value={role}
              onChange={onRoleChange}
              outlined={false}
              inputProps={{
                'data-testid': 'TeamMemberItem_roleSelect',
              }}
            >
              <option value='ADMIN' aria-label='admin'>
                ADMIN
              </option>
              <option value='USER' aria-label='user'>
                USER
              </option>
            </NativeSelect>
          </Box>
        )}

        {isCurrentUser && (
          <PersonRoundedIcon
            fontSize='small'
            className={classes.personIcon}
            data-testid='TeamMemberItem-ownerIcon'
          />
        )}

        {shouldShowDeleteButton && (
          <DeleteRoundedIcon
            fontSize='small'
            className={clsx(classes.deleteIcon, { hovered })}
            aria-label='Delete User'
            onClick={onDeletePress}
          />
        )}
      </Box>
    </TableRow>
  );
};

TeamMemberItem.propTypes = {
  member: OrganisationTypes.teamMember,
  currentUser: CustomerTypes.currentUser,
  onTeamMemberNotVerifiedIconPress: PropTypes.func,
  isHeading: PropTypes.bool,
  onUserRoleChange: PropTypes.func,
  onDeleteUser: PropTypes.func,
};

TeamMemberItem.defaultProps = {
  member: {
    id: '',
    firstName: 'Member',
    lastName: '',
    email: 'Email',
    emailVerified: true,
    role: 'Role',
  },
  currentUser: undefined,
  isHeading: false,
  onTeamMemberNotVerifiedIconPress: _.noop,
  onUserRoleChange: _.noop,
  onDeleteUser: _.noop,
};

export default TeamMemberItem;
