import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      ...theme.flexColumn,
      ...theme.customPaper,
    },
    container: {
      height: '100%',
      padding: '25px 35px 35px 25px',
    },
    gridTopLeft: {
      display: 'flex',
      flex: 1,
    },
    gridBottomLeft: {
      display: 'flex',
      alignItems: 'flex-end',
      flex: 1,
    },
    gridBottomRight: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    gridTopRight: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    textStyle: {
      height: 30,
    },
    percentile: {
      color: theme.palette.success.main,
      fontWeight: 'bold',
    },
    percentileNegative: {
      color: theme.palette.error.main,
      fontWeight: 'bold',
    },
    image: {
      backgroundSize: 'contain',
      float: 'right',
      width: '80%',
      minWidth: 100,
      '@media (min-width: 960px)': {
        minWidth: 60,
      },
      '@media (min-width: 1280px)': {
        minWidth: 100,
      },
    },
    subheader: {
      fontWeight: 'normal',
      fontSize: 50,

      '@media (min-width: 600px)': {
        minWidth: 60,
      },

      '@media (min-width: 960px)': {
        minWidth: 60,
      },
      '@media (min-width: 1280px)': {
        minWidth: 100,
      },
    },
    icon: {
      marginTop: 1,
    },
  }),
);
