import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import UpdatePassword from '../../Inputs/UpdatePassword';
import PanelHeader from '../PanelHeader';
import TitledText from '../../Text/TitledText';
import UpdateProfile from '../../Inputs/UpdateProfile';

import useStyles from './YourProfileStyle';
import errors from '../../../messages/errors';
import CustomerApi from '../../../api/v1/customer/customer';
import useNotifications from '../../../contexts/notificationContext';

// region Workaround for unit test causing incorrect validation
const _clearPasswordForm = (actions) => {
  actions.setValues(
    {
      currentPassword: '',
      newPassword: '',
      passwordConfirmation: '',
    },
    false,
  );
};

export const _exposers_ = {
  _clearPasswordForm,
};
// endregion Workaround for unit test causing incorrect validation

const YourProfile = ({
  email = 'Unknown',
  createdOnDate = '',
  firstName = 'Unknown',
  lastName = '',
  onProfileUpdated,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const formattedCreatedOnDate = moment(createdOnDate).format('ddd Do MMM, YYYY');
  const orientation = isMobile ? 'horizontal' : 'vertical';
  const { addSuccessNotification, addErrorNotification } = useNotifications();

  const initialProfileDetails = { email, firstName, lastName };

  const onUpdateProfileSubmit = async (values, actions) => {
    const { email: updatedEmail, firstName: updatedFirstname, lastName: updatedLastName } = values;
    actions.setSubmitting(true);

    try {
      await CustomerApi.updateProfileDetails({
        email: updatedEmail,
        firstName: updatedFirstname,
        lastName: updatedLastName,
      });

      addSuccessNotification('Profile updated');

      onProfileUpdated({
        email: updatedEmail,
        firstName: updatedFirstname,
        lastName: updatedLastName,
        createdOnDate,
      });
    } catch (e) {
      const errorToShow = e.message === 'Network Error' ? errors.SERVER_NOT_AVAILABLE : e.message;

      addErrorNotification(errorToShow);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const onChangePasswordSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      await CustomerApi.updatePassword({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });

      _exposers_._clearPasswordForm(actions);

      addSuccessNotification('Password updated');
    } catch (e) {
      _exposers_._clearPasswordForm(actions);

      const errorToShow = e.message === 'Network Error' ? errors.SERVER_NOT_AVAILABLE : e.message;

      if (e.message === 'Invalid Password') {
        actions.setFieldError('currentPassword', 'Invalid Password');
      } else {
        addErrorNotification(errorToShow);
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Box className={classes.root}>
      <PanelHeader title={`${firstName} ${lastName}`} />

      <Box className={classes.userDetailsContainer}>
        <TitledText
          title='Created On'
          text={formattedCreatedOnDate}
          containerClass={classes.userDetailItem}
        />
        <TitledText title='Email' text={email} containerClass={classes.userDetailItem} />
      </Box>

      <Divider />

      <Box className={classes.inputsContainer}>
        <Box className={classes.updateProfileContainer}>
          <Typography variant='h5' color='textPrimary' className={classes.updateProfileHeader}>
            Update Profile Details
          </Typography>
          <Typography color='textSecondary'>
            To edit simply update the inputs and click Submit.
          </Typography>

          {/* Input Container */}
          <UpdateProfile
            onSubmit={onUpdateProfileSubmit}
            updateProfileInitialValues
            initialValues={initialProfileDetails}
          />
        </Box>

        <Divider orientation={orientation} className={classes.divider} data-testid='Divider' />

        <Box className={classes.changePasswordContainer}>
          <Typography variant='h5' color='textPrimary' className={classes.updatePasswordTitle}>
            Change Password
          </Typography>
          <Typography color='textSecondary'>
            Please enter your existing and new password.
          </Typography>

          <UpdatePassword onSubmit={onChangePasswordSubmit} />
        </Box>
      </Box>
    </Box>
  );
};

export default YourProfile;

YourProfile.propTypes = {
  createdOnDate: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onProfileUpdated: PropTypes.func.isRequired,
};
