// eslint-disable-next-line import/prefer-default-export
export class _DefaultConsole {}

_DefaultConsole._log = console.log;
_DefaultConsole._warn = console.warn;
_DefaultConsole._error = console.error;
_DefaultConsole._assert = console.assert;
_DefaultConsole._dir = console.dir;

const hideConsoleOutput = process.env.REACT_APP_SHOW_DEBUGGING_INFO === 'false';

console.log = (...args) => {
  if (hideConsoleOutput) return;
  _DefaultConsole._log(...args);
};
console.warn = (...args) => {
  if (hideConsoleOutput) return;
  _DefaultConsole._warn(...args);
};
console.error = (...args) => {
  if (hideConsoleOutput) return;
  _DefaultConsole._error(...args);
};
console.assert = (...args) => {
  if (hideConsoleOutput) return;
  _DefaultConsole._assert(...args);
};
console.dir = (...args) => {
  if (hideConsoleOutput) return;
  _DefaultConsole._dir(...args);
};
