import React from 'react';
import PropTypes from 'prop-types';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Text from '../../../components/Text/Text';

const useStyle = makeStyles((theme) =>
  createStyles({
    containerStyle: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      padding: 10,
      paddingLeft: 13,
      marginTop: 2,
      backgroundColor: 'white',
      border: 0,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.common.borderColor,
      },
    },
    iconStyle: {
      fontSize: 15,
      color: theme.palette.forageRed,
      marginRight: 5,
    },
    textStyle: {
      fontSize: 12,
      color: theme.palette.text.primary,
    },
  }),
);

const SaveFunnelButton = ({ onClick, label }) => {
  const styles = useStyle();
  return (
    <button type='button' onClick={onClick} className={styles.containerStyle}>
      <AddCircleIcon className={styles.iconStyle} />
      <Text className={styles.textStyle}>{label}</Text>
    </button>
  );
};

export default SaveFunnelButton;

SaveFunnelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};
