import PropTypes from 'prop-types';
import React from 'react';

import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/SearchRounded';

import SidebarSubHeader from '../../Text/SidebarSubHeader';
import TextField from '../../TextFields/TextField';
import useStyles from './UserSearchSectionStyle';

const InputProps = (classes) => ({
  className: classes.textField,
  startAdornment: (
    <InputAdornment position='start'>
      <SearchIcon color='disabled' fontSize='small' />
    </InputAdornment>
  ),
});

const SideBarSearchField = ({ onChange, value, title, placeholder }) => {
  const classes = useStyles();
  const handleOnChange = (e) => onChange(e.target.value);

  return (
    <Box className={classes.root}>
      <SidebarSubHeader>{title}</SidebarSubHeader>
      <TextField
        className={classes.outerField}
        placeholder={placeholder}
        variant='outlined'
        onChange={handleOnChange}
        value={value}
        InputProps={InputProps(classes)}
        data-testid='sidebar-user-search'
      />
    </Box>
  );
};

export default SideBarSearchField;

SideBarSearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  title: PropTypes.string.isRequired,
};

SideBarSearchField.defaultProps = {
  placeholder: 'Search...',
};
