import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    pageContainer: {
      height: '100vh',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    errorContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
    sidebarAndContent: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
    contentContainer: {
      ...theme.headerSize,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      minWidth: 0,
    },
    centerContentHorizontal: {
      alignItems: 'center',
    },
    centerContentVertical: {
      justifyContent: 'center',
    },
    debugInfo: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      color: 'white',
      backgroundColor: 'black',
    },
  }),
);
