import { makeStyles } from '@material-ui/core/styles';

export const sidebarOpenWidth = '200pt';
const closedWidth = 50;
const padding = 10;

export default makeStyles((theme) => ({
  root: {
    width: closedWidth,
    transition: theme.transitions.create(['all'], {
      duration: 500,
    }),
    overflow: 'hidden',
    backgroundColor: 'white',
    '& *': {
      whiteSpace: 'nowrap',
    },
  },
  rootOpen: {
    width: sidebarOpenWidth,
    borderRight: '1px solid #EAEDF3',
  },
  header: {
    padding,
    marginBottom: 20,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0, 0.05)',
    },
    '&:active': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  },
  titleContainer: {
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
    pointerEvents: 'none',
    color: theme.palette.text.primary,
    fontSize: 16,
  },
  menuIcon: {
    width: closedWidth - padding * 2,
    paddingRight: 10,
  },
  hidden: {
    opacity: 0,
    transition: theme.transitions.create(['all'], {
      duration: 500,
    }),
  },
  visible: {
    opacity: '100%',
    transition: theme.transitions.create(['all'], {
      duration: 500,
    }),
  },
}));
