import React from 'react';
import Typography from '@material-ui/core/Typography';

import PropTypes from 'prop-types';

import styles from './HeaderStyles';

const Header = ({ children }) => {
  const { textStyle } = styles();

  return (
    <Typography variant='h1' className={textStyle}>
      {children}
    </Typography>
  );
};

Header.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Header;
