import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default makeStyles((theme) =>
  createStyles({
    container: {
      ...theme.customPaper,
      height: '100%',
    },
    headerRow: {
      paddingLeft: 20,
      paddingTop: 20,
      height: '10%',
    },
    graphContainer: {
      height: '90%',
      width: '100%',
    },
    subheader: {
      fontSize: 20,
    },
  }),
);
