/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Text from '../../../components/Text/Text';

const customStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderTop: '1px solid lightgrey',
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 15,
      paddingRight: 10,
      '&:hover': {
        backgroundColor: theme.palette.common.borderColor,
        '&.MuiSvgIcon-root': {
          backgroundColor: 'green',
        },
      },
    },
    eventText: {
      paddingLeft: 5,
      fontSize: 12,
      paddingTop: 2,
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      color: theme.palette.text.primary,
    },
    textAndIconContainer: {
      alignItems: 'center',
      justifyContent: 'space-between',
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
    },
    iconStyle: {
      marginTop: 5,
      cursor: 'pointer',
    },
    numberText: {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
  }),
);

const getIconField = (eventType, index) => {
  const iconStyle = { paddingLeft: index < 9 ? 10 : 4, fontSize: 16 };
  if (eventType.toUpperCase() === 'EVENT') {
    return (
      <FlagOutlinedIcon data-testid='funnelEventRowEventIcon' style={iconStyle} color='primary' />
    );
  }
  return (
    <PhoneIphoneOutlinedIcon
      data-testid='funnelEventRowScreenIcon'
      style={iconStyle}
      color='primary'
    />
  );
};

const iconStyle = { fontSize: 15, color: '#747983' };

const CurrentFunnelEventRow = ({
  eventName,
  eventType,
  onDeleteClick,
  onMoveEventClicked,
  index,
}) => {
  const classes = customStyles();
  return (
    <Box className={classes.container}>
      <Text className={classes.numberText}>{index + 1} </Text>
      {getIconField(eventType, index)}
      <div className={classes.textAndIconContainer}>
        <Text className={classes.eventText}>{eventName}</Text>
        <div className={classes.iconStyle}>
          <KeyboardArrowUpIcon
            style={iconStyle}
            data-testid='funnelEventRowUp'
            onClick={() => onMoveEventClicked(index, index - 1)}
          />
          <KeyboardArrowDownIcon
            style={iconStyle}
            data-testid='funnelEventRowDown'
            onClick={() => onMoveEventClicked(index, index + 1)}
          />
          <HighlightOffIcon
            data-testid='funnelEventRowRemove'
            style={{ ...iconStyle, marginLeft: 5 }}
            onClick={onDeleteClick}
          />
        </div>
      </div>
    </Box>
  );
};

export default CurrentFunnelEventRow;

CurrentFunnelEventRow.propTypes = {
  eventName: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onMoveEventClicked: PropTypes.func.isRequired,
};
