import AccessAlarmsRoundedIcon from '@material-ui/icons/AccessAlarmsRounded';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';

export const isGroupIndicator = (type) => {
  return type === 'SCREEN' || type === 'START' || type === 'END';
};

export const getDisplayTextForItem = (label, type) => {
  switch (type) {
    case 'START':
      return 'Session Start';
    case 'END':
      return 'Session End';
    case 'SCREEN':
    default:
      return label;
  }
};

export const getIconForItem = (type) => {
  switch (type) {
    case 'START':
      return AccessAlarmsRoundedIcon;
    case 'USERNAME':
      return PersonOutlineRoundedIcon;
    default:
      return undefined;
  }
};
