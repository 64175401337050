import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  container: {
    textTransform: 'capitalize',
    padding: 10,
    boxShadow: '0px 0px 5px 5px rgba(123,123,123,0.1)',
  },
  boldItem: {
    fontSize: 18,
    fontWeight: 'bold',
  },
});
