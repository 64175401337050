import Moment from 'moment';
import { stringToColour } from '../../../../utils/general/generalFunctions';
import {
  getDisplayTextForItem,
  getIconForItem,
  isGroupIndicator,
} from '../../../../utils/session/sessionUtils';
import { eventStyles, groupHeaderStyles, usernameStyles } from './TimelineItemStyle';

const groupHeaderDateFormat = 'HH:mm:ss';
const defaultDateFormat = 'HH:mm:ss.SSS';

const createTimelineItemProperties = ({ timelineItem, selected }) => {
  const { type, label, timestamp } = timelineItem;

  const displayText = getDisplayTextForItem(label, type);
  const Icon = getIconForItem(type);

  /* Group Item Properties */
  if (isGroupIndicator(type)) {
    return {
      formattedTimestamp: Moment(timestamp).local().format(groupHeaderDateFormat),
      classOverrides: groupHeaderStyles(),
      clickable: false,
      displayText,
      Icon,
    };
  }

  /* User Item Properties */
  if (type === 'USERNAME') {
    return {
      formattedTimestamp: Moment(timestamp).local().format(defaultDateFormat),
      classOverrides: usernameStyles(),
      clickable: false,
      displayText,
      Icon,
    };
  }

  /* Event Item Properties */
  const textColor = stringToColour(displayText);
  return {
    formattedTimestamp: Moment(timestamp).local().format(defaultDateFormat),
    classOverrides: eventStyles({ selected, textColor }),
    clickable: true,
    displayText,
    Icon,
  };
};

export default createTimelineItemProperties;
