import Box from '@material-ui/core/Box';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import CachedIcon from '@material-ui/icons/Cached';
import Collapse from '@material-ui/core/Collapse';
import Fab from '@material-ui/core/Fab';

import SessionsTable from '../../components/Tables/SessionsTable';
import Header from '../../components/Text/Header';
import { useEventFilters, useEvents } from '../../hooks/eventsHooks';
import styles from './_sessions.module.scss';
import sessionsAPI from '../../api/v1/sessions/sessions';
import PageLayout from '../../components/Display/PageLayout';
import SessionDetail from '../../components/Display/SessionDetail/SessionDetail';
import userOptionsActions from '../../redux/actions/userOptionsActions';
import userOptionsSelector from '../../redux/selectors/userOptionsSelector';
import SearchAndFilterSidebar from '../../components/Inputs/SearchAndFilterSidebar';
import SessionsTablePagination from '../../components/Tables/pagination/SessionsTablePagination';
import useActions from '../../redux/actions/useActions';
import DropDownPicker from '../../components/Pickers/DropDownPicker';
import DisplayError from '../../components/Generic/DisplayError';
import hidingSquirrel from '../../static/media/hiding_squirrel.svg';
import { NO_SESSIONS_FOUND_MESSAGE } from '../../messages/info';

const cancelToken = axios.CancelToken;

const Sessions = () => {
  /* Screen State */
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(undefined);
  const [refresh, setRefresh] = useState(false);
  const clearSelectedSession = () => setSelectedSession(undefined);

  /* Date Filtering */
  const { fromDate, toDate, currentAppId, apps } = useSelector(userOptionsSelector);
  const { setSelectedDateRange } = useActions(userOptionsActions);

  /* Username Filtering */
  const [usernameFilter, setUsernameFilter] = useState('');
  const [debouncedUsername] = useDebounce(usernameFilter, 500, { leading: true });

  /* Event Filtering */
  const { events, loadingEvents } = useEvents(currentAppId);
  const { eventFilters, eventFilterFunctions } = useEventFilters();
  const [showOnlySessionsWithCrashes, setShowOnlySessionsWithCrashes] = useState(false);

  /* Page Filtering */
  const [page, setPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sessionsPerPage, setSessionsPerPage] = useState(15);
  const [sessionOptions] = useState([
    { value: 15, text: '15 per page' },
    { value: 25, text: '25 per page' },
    { value: 50, text: '50 per page' },
  ]);

  useEffect(() => {
    setPage(1);
  }, [debouncedUsername, fromDate, toDate, eventFilters, showOnlySessionsWithCrashes]);

  useEffect(() => {
    const cancellationToken = cancelToken.source();

    (async () => {
      try {
        setLoading(true);
        setError(undefined);

        const { data } = await sessionsAPI.getSessions({
          currentAppId,
          usernameFilter: debouncedUsername,
          eventFilters,
          cancellationToken,
          fromDate,
          toDate,
          page: Math.max(page - 1, 0),
          sessionsPerPage,
          showOnlySessionsWithCrashes,
        });

        const { sessions: apiSessions = [], page: pageData } = data;

        setTotalElements(pageData.totalElements);
        setTotalPages(pageData.totalPages);

        if (pageData.number > pageData.totalPages) {
          setPage(pageData.totalPages);
        }

        setSessions(apiSessions);
        setLoading(false);
        setRefresh(false);
      } catch (err) {
        if (axios.isCancel(err)) return;
        setLoading(false);
        setRefresh(false);
        setError(err.message);
      }
    })();

    return () => {
      cancellationToken.cancel();
      setLoading(false);
    };
  }, [
    debouncedUsername,
    fromDate,
    toDate,
    eventFilters,
    showOnlySessionsWithCrashes,
    page,
    sessionsPerPage,
    refresh,
  ]);

  const sidebar = (
    <SearchAndFilterSidebar
      key='sidebar'
      usernameFilter={usernameFilter}
      setUsernameFilter={setUsernameFilter}
      eventFilters={eventFilters}
      eventFilterFunctions={eventFilterFunctions}
      showOnlySessionsWithCrashes={showOnlySessionsWithCrashes}
      setShowOnlySessionsWithCrashes={setShowOnlySessionsWithCrashes}
      events={events}
      loadingEvents={loadingEvents}
    />
  );

  return (
    <PageLayout
      error={error}
      sidebar={sidebar}
      highlightTab='Sessions'
      fromDate={fromDate}
      toDate={toDate}
      onDatesSelected={setSelectedDateRange}
      apps={apps}
      currentAppId={currentAppId}
      centerContentHorizontal={!sessions.length}
      centerContentVertical={!sessions.length}
      isLoading={loading}
    >
      {!sessions.length ? (
        <DisplayError
          title='No sessions found!'
          description={NO_SESSIONS_FOUND_MESSAGE}
          image={hidingSquirrel}
        />
      ) : (
        <div className={styles.pageContent}>
          <Collapse in={!selectedSession}>
            <Box className={styles.sessionContentContainer}>
              <div className={styles.titleContainer}>
                <Header>Sessions</Header>

                <div className={styles.flexSpaceBetween}>
                  <Fab
                    onClick={() => setRefresh(true)}
                    classes={{ root: styles.refreshButton }}
                    size='small'
                  >
                    <CachedIcon className={styles.refreshIcon} />
                  </Fab>
                  <DropDownPicker
                    value={sessionsPerPage}
                    items={sessionOptions}
                    updateItem={setSessionsPerPage}
                    buttonClass={styles.dropDownPicker}
                  />
                </div>
              </div>

              <SessionsTable rowData={sessions} rowClick={setSelectedSession} />
              <SessionsTablePagination
                page={Number(page)}
                changePage={setPage}
                totalElements={totalElements}
                totalPages={totalPages}
                sessionsPerPage={sessionsPerPage}
              />
            </Box>
          </Collapse>

          <Collapse in={!!selectedSession}>
            <Box className={styles.sessionContentContainer}>
              <SessionDetail sessionMetadata={selectedSession} handleClose={clearSelectedSession} />
            </Box>
          </Collapse>
        </div>
      )}
    </PageLayout>
  );
};

export default Sessions;
