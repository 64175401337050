import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  submitButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 15,
  },
  submitButton: {
    paddingLeft: 30,
    paddingRight: 30,
    height: 48,
  },
}));
