import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  link: {
    display: 'flex',
    alignItems: 'center',
    padding: '0pt 20pt',
    height: '100%',
    fontSize: 16,
    textDecoration: 'none',
    color: '#747983',
    '&:hover': {
      backgroundColor: '#F4F6FA',
    },
  },
  muted: {
    color: '#777',
    textDecoration: 'none',
    cursor: 'pointer',
    userSelect: 'none',
  },
  tabActive: {
    color: '#A52043',
    fontWeight: 'bold',
  },
});
