import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '../TextFields/TextField';
import BasicFormModal from './BasicFormModal';
import messages from '../../messages/info';
import appTypes from '../../utils/types/apps';
import AppsApi from '../../api/v1/app/app';
import useNotifications from '../../contexts/notificationContext';

const DeleteAppModal = ({ app, onModalClose }) => {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState(null);
  const { addSuccessNotification, addErrorNotification } = useNotifications();

  const onInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
    setInputErrorMessage(null);
  };

  const onInputBlur = (event) => {
    const { value } = event.target;

    if (value.length && value !== app.appName) {
      setInputErrorMessage('Entered value does not match app name');
    }
  };

  const onConfirmButtonPress = async () => {
    setIsLoading(true);

    try {
      await AppsApi.deleteApp(app.id);
      addSuccessNotification('App Successfully Deleted');
      onModalClose(app, true);
    } catch (err) {
      addErrorNotification(err.message);
      setIsLoading(false);
    }
  };

  const formControls = [
    <TextField
      value={inputValue}
      onChange={onInputChange}
      onBlur={onInputBlur}
      placeholder='Enter your app name to confirm'
      helperText={inputErrorMessage}
      variant='outlined'
      data-testid='input-app-name'
    />,
  ];

  return (
    <BasicFormModal
      title={`You're about to delete ${app.appName}`}
      info={messages.DELETE_APP_INFO}
      controls={formControls}
      confirmButtonText='Delete App'
      confirmButtonDisabled={!inputValue.length || inputValue !== app.appName}
      onCancel={onModalClose}
      onConfirm={onConfirmButtonPress}
      onClose={onModalClose}
      isLoading={isLoading}
    />
  );
};

DeleteAppModal.propTypes = {
  app: appTypes.app.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default DeleteAppModal;
