import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box, Hidden } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';

import AlertArray from '../../components/Display/AlertArray';
import PasswordField from '../../components/TextFields/PasswordField';
import TextField from '../../components/TextFields/TextField';
import { signUpValidation } from '../../validation/userAuthValidation';
import classes from './_auth.module.scss';
import { useSignUpCallback } from '../../hooks/authHooks';
import LoadingButton from '../../components/Buttons/LoadingButton';

import basicSquirrel from '../../static/media/Squirrel basic.svg';
import logo from '../../static/media/logo.svg';
import CheckboxInput from '../../components/Inputs/CheckboxInput';

const initialValues = {
  emailAddress: '',
  firstName: '',
  lastName: '',
  organisationName: '',
  password: '',
  passwordConfirmation: '',
  termsAndConditionsAccepted: false,
};

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const onSubmit = useSignUpCallback(history, setLoading);

  return (
    <Grid container className={classes.root}>
      <Hidden smDown>
        <Grid item md={6}>
          <Container className={classes.leftGridContainer}>
            <CardMedia
              image={basicSquirrel}
              className={classes.squirrelLogo}
              data-testid='contentMissing'
            />
          </Container>
        </Grid>
      </Hidden>
      <Grid item md={6} xs={12} className={classes.rightGrid}>
        <Container>
          <CardMedia image={logo} className={classes.forageLogo} data-testid='contentMissing' />

          <Typography variant='h6' color='textPrimary'>
            Welcome to Forage, Mobile Analytics made specifically for React Native. We look forward
            to serving you.
          </Typography>

          <Formik
            initialValues={initialValues}
            validationSchema={signUpValidation}
            onSubmit={onSubmit}
            validateOnChange={false}
          >
            {({ values, errors, handleChange, handleBlur, setFieldValue }) => (
              <Form method='post'>
                <AlertArray severity='error' alerts={errors.general} />
                <AlertArray severity='info' alerts={values.general} />
                <TextField
                  variant='outlined'
                  required
                  id='emailAddress'
                  label='Email'
                  autoComplete='email'
                  value={values.emailAddress}
                  error={errors.emailAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Grid container alignItems='flex-end' spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      variant='outlined'
                      required
                      id='firstName'
                      label='First Name'
                      value={values.firstName}
                      error={errors.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant='outlined'
                      required
                      id='lastName'
                      label='Last Name'
                      value={values.lastName}
                      error={errors.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <TextField
                  variant='outlined'
                  required
                  id='organisationName'
                  label='Organization Name'
                  value={values.organisationName}
                  error={errors.organisationName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <PasswordField
                  required
                  id='password'
                  label='Password'
                  value={values.password}
                  error={errors.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <PasswordField
                  required
                  id='passwordConfirmation'
                  label='Confirm Password'
                  value={values.passwordConfirmation}
                  error={errors.passwordConfirmation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <Box display='inline' className={classes.termsAndConditionsBox}>
                  <Typography variant='body1' display='inline' color='textSecondary'>
                    By creating an account you agree to our
                    <Link
                      id='eula'
                      to={{
                        pathname: 'https://www.forage-analytics.com/eula/',
                      }}
                      target='_blank'
                      display='inline'
                    >
                      <Typography
                        variant='body1'
                        align='center'
                        display='inline'
                        className={classes.signUpUnderLine}
                      >
                        EULA
                      </Typography>
                    </Link>
                    <Typography
                      variant='body1'
                      display='inline'
                      color='textSecondary'
                      style={{ marginLeft: 4 }}
                    >
                      and
                    </Typography>
                    <Link
                      id='eula'
                      to={{
                        pathname: 'https://www.forage-analytics.com/privacy-policy/',
                      }}
                      target='_blank'
                      display='inline'
                    >
                      <Typography
                        variant='body1'
                        display='inline'
                        className={classes.signUpUnderLine}
                      >
                        Privacy Policy
                      </Typography>
                    </Link>
                  </Typography>
                </Box>

                <CheckboxInput
                  label='I have read and accept the EULA + Privacy Policy'
                  isActive={values.termsAndConditionsAccepted}
                  onSetActive={(bool) => setFieldValue('termsAndConditionsAccepted', bool)}
                  id='termsAndConditionsAccepted'
                />

                <LoadingButton
                  isLoading={loading}
                  className={classes.loadingButton}
                  type='submit'
                  fullWidth
                  variant='contained'
                  id='submit'
                >
                  Sign Up
                </LoadingButton>
                <Link id='signup' to='/auth/signIn' className={classes.signUpLink}>
                  <Typography variant='body1' align='center' display='inline' color='textSecondary'>
                    Already have an account?
                  </Typography>

                  <Typography
                    variant='body1'
                    align='center'
                    display='inline'
                    className={classes.signUpUnderLine}
                  >
                    Sign In
                  </Typography>
                </Link>
              </Form>
            )}
          </Formik>
        </Container>
      </Grid>
    </Grid>
  );
};

export default SignUp;
