import moment from 'moment';
import apiUrls from '../../apiUrls';
import { HTTP } from '../../index';

const { SESSIONS, SESSION_TIMELINE } = apiUrls;

const getSessions = ({
  currentAppId,
  usernameFilter,
  cancellationToken,
  fromDate,
  toDate,
  eventFilters,
  page,
  sessionsPerPage,
  showOnlySessionsWithCrashes,
} = {}) =>
  HTTP.waApi.post(
    `${SESSIONS}/${currentAppId}`,
    {
      // Coerce falsy 'e.target.value' values to undefined to optimise filter calls
      username: usernameFilter || undefined,
      events: eventFilters && eventFilters.length ? eventFilters : undefined,
      from: fromDate ? moment(fromDate).format() : undefined,
      to: fromDate ? moment(toDate).format() : undefined,
      page,
      size: sessionsPerPage,
      showOnlySessionsWithCrashes,
    },
    {
      cancelToken: cancellationToken?.token,
    },
  );

const getSessionTimeline = ({ id, cancellationToken }) =>
  HTTP.waApi.get(SESSION_TIMELINE(id), {
    cancelToken: cancellationToken?.token,
  });

export default {
  getSessions,
  getSessionTimeline,
};
