import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      maxWidth: '50%',
      flexShrink: 0,
      marginRight: 44,
      minWidth: 300,

      [`@media only screen and (max-width: 960px)`]: {
        maxWidth: 'none',
        height: '50%',
        marginRight: 0,
      },
    },

    teamSubHeader: {
      fontSize: 20,
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },

    teamDescription: {
      fontSize: 15,
      color: theme.palette.text.secondary,
      marginTop: 10,
      maxWidth: 665,
    },
  }),
);
