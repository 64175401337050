import * as yup from 'yup';
import { VALID_STRING } from '../regex';

export const genericMinCharacterErrorMessage = ({ min }) =>
  `Must be greater than ${min} characters`;
export const genericMaxCharacterErrorMessage = ({ max }) =>
  `Must be no greater than ${max} characters`;
export const validStringErrorMessage = 'Must only contain valid characters';

export default yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .matches(VALID_STRING, validStringErrorMessage)
    .min(2, genericMinCharacterErrorMessage)
    .max(150, genericMaxCharacterErrorMessage),
  lastName: yup
    .string()
    .trim()
    .matches(VALID_STRING, validStringErrorMessage)
    .min(2, genericMinCharacterErrorMessage)
    .max(150, genericMaxCharacterErrorMessage),
  emailAddress: yup
    .string()
    .trim()
    .matches(VALID_STRING, validStringErrorMessage)
    .email('Email must be a valid email'),
  password: yup
    .string()
    .trim()
    .matches(VALID_STRING, validStringErrorMessage)
    .matches(
      /^(?=.*\d).{6,30}$/,
      'Password must be between 6-30 characters and contain at least 1 number',
    ),
  passwordConfirmation: yup
    .string()
    .trim()
    .oneOf([yup.ref('password')], 'Passwords do not match'),
  role: yup.string().oneOf(['USER', 'ADMIN']),
});
