import React from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';

import style from './FormTextErrorStyle';

const FormTextError = ({ error }) => {
  const classes = style();
  return (
    <FormHelperText role='note' error className={classes.formTextHelper}>
      {error}
    </FormHelperText>
  );
};

FormTextError.propTypes = {
  error: PropTypes.string.isRequired,
};

export default FormTextError;
