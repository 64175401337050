import apiUrls from '../../apiUrls';
import { HTTP } from '../../index';

const {
  FUNNELS_EVENTS_LIST,
  FUNNELS_GET_FUNNEL,
  FUNNELS_GET_SAVED_FUNNELS,
  FUNNELS_SAVE_FUNNEL,
  FUNNELS_DELETE_FUNNEL,
} = apiUrls;

const getFunnelEvents = (appId) => HTTP.waApi.get(FUNNELS_EVENTS_LIST(appId));

const getUserFunnel = (appId, steps, from, to) =>
  HTTP.waApi.post(FUNNELS_GET_FUNNEL(appId), {
    stepList: steps,
    from,
    to,
  });

const getSavedFunnels = (appId) => HTTP.waApi.get(FUNNELS_GET_SAVED_FUNNELS(appId));

const saveFunnel = (appId, funnelName, query) =>
  HTTP.waApi.post(FUNNELS_SAVE_FUNNEL(appId), {
    funnelName,
    query: JSON.stringify(query),
  });

const deleteFunnel = (funnelId) => HTTP.waApi.delete(FUNNELS_DELETE_FUNNEL(funnelId));

export default {
  getFunnelEvents,
  getUserFunnel,
  getSavedFunnels,
  saveFunnel,
  deleteFunnel,
};
