import React from 'react';
import _ from 'lodash';
import {
  TableBody,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
} from '@material-ui/core';
import PropTypes, { shape } from 'prop-types';
import styles from './EventsOverviewTableStyles';
import { numberWithCommas } from '../../utils/general/generalFunctions';

export const calculatePercentageFill = (currentCount, largestCount) => {
  return {
    fullFlex: currentCount,
    emptyFlex: largestCount - currentCount,
  };
};

const Row = ({ data, maxCount, alternateRow }) => {
  const classes = styles();
  const { eventName, eventCount } = data;
  const { fullFlex, emptyFlex } = calculatePercentageFill(eventCount, maxCount);
  return (
    <TableRow>
      <TableCell className={classes.tableRowTextCell}>{eventName}</TableCell>
      <TableCell className={classes.tableRowCellProgressContainer}>
        <div className={classes.tableRowProgressBarContainer}>
          <div
            style={{
              flex: fullFlex,
              backgroundColor: alternateRow ? '#FF678B' : '#53E9D2',
            }}
            className={classes.tableRowProgressBar}
          />
          <div
            style={{
              flex: emptyFlex,
              backgroundColor: '#F4F6FA',
            }}
            className={classes.tableRowProgressBar}
          />
        </div>
      </TableCell>
      <TableCell className={classes.tableRowTextCell}>{numberWithCommas(eventCount)}</TableCell>
    </TableRow>
  );
};

const EventsOverviewTable = ({ data }) => {
  const classes = styles();
  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeaderContainer}>
            <TableCell className={classes.tableHeaderTitle}>Event Name</TableCell>
            <TableCell />
            <TableCell className={classes.tableHeaderTitle}>Event Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(_.orderBy(data, ['eventCount'], ['desc']), (dataRow, index) => (
            <Row
              key={index}
              data={dataRow}
              alternateRow={!!(index % 2)}
              maxCount={_.maxBy(data, (item) => item.eventCount).eventCount}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

Row.propTypes = {
  data: shape({
    eventName: PropTypes.string,
    eventCount: PropTypes.number,
  }).isRequired,
  maxCount: PropTypes.number.isRequired,
  alternateRow: PropTypes.bool.isRequired,
};

EventsOverviewTable.propTypes = {
  data: PropTypes.arrayOf(shape({ eventName: PropTypes.string, eventCount: PropTypes.number }))
    .isRequired,
};

export default EventsOverviewTable;
