import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';

import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import ContainedButton from '../Buttons/Button';

import styles from './DropDownPickerStyles';
import Loading from '../Spinners/LoadingSpinner';

const handleClick = (item, updateItem, setOpen) => {
  updateItem(item.value);
  setOpen(false);
};

const DropDownPicker = ({
  value,
  items,
  updateItem,
  buttonClass,
  itemClass,
  loading,
  spinnerSize,
  children,
}) => {
  const classes = styles();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'dropdown-popover',
  });
  const { setOpen, isOpen } = popupState;

  return (
    <>
      <ContainedButton
        {...bindTrigger(popupState)}
        classes={{
          label: loading ? classes.tabButtonLabelLoading : classes.tabButtonLabel,
        }}
        className={clsx(classes.tabButton, buttonClass, {
          [classes.tabButtonNoPadding]: loading,
        })}
        data-testid='dropDownPicker-button'
      >
        {loading ? (
          <Loading size={spinnerSize} color='primary' />
        ) : (
          <>
            {items.find((item) => item.value === value).text}

            <KeyboardArrowDownRoundedIcon
              className={clsx(classes.buttonArrowIcon, {
                [classes.buttonArrowIconRotate]: isOpen,
              })}
            />
          </>
        )}
      </ContainedButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ top: 10 }}
        PaperProps={{ className: classes.paper }}
      >
        <Grid container className={classes.container}>
          {items.map((item) => (
            <MenuItem
              key={item.value}
              className={itemClass}
              onClick={() => handleClick(item, updateItem, setOpen)}
              data-testid={`dropDownPickerMenuItem-${item.text}`}
            >
              {item.text}
            </MenuItem>
          ))}
        </Grid>

        <Divider />

        {children}
      </Popover>
    </>
  );
};

DropDownPicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  updateItem: PropTypes.func.isRequired,
  buttonClass: PropTypes.string,
  itemClass: PropTypes.string,
  loading: PropTypes.bool,
  spinnerSize: PropTypes.number,
  children: PropTypes.node,
};

DropDownPicker.defaultProps = {
  buttonClass: '',
  itemClass: '',
  loading: false,
  spinnerSize: 40,
  children: undefined,
};

export default DropDownPicker;
