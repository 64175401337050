import PropTypes from 'prop-types';
import React from 'react';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

import styles from './TooltipSyle';

const ToolTip = ({ tooltipItems }) => {
  const classes = styles();
  return (
    <Grid container component={Paper} direction='column' className={classes.container}>
      {tooltipItems.map((item, index) => (
        <Grid item key={`${Object.keys(item)}`}>
          <Typography variant='subtitle2' className={clsx({ [classes.boldItem]: index === 0 })}>
            {`${Object.values(item)}`}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

ToolTip.propTypes = {
  tooltipItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }),
  ).isRequired,
};

export default React.memo(ToolTip);
