import moment from 'moment';
import apiUrls from '../../apiUrls';
import { HTTP } from '../../index';

const { DASHBOARD } = apiUrls;

const getDashboardData = (currentAppId, fromDate, toDate, keys) =>
  HTTP.waApi.get(`${DASHBOARD}/${currentAppId}`, {
    params: {
      from: fromDate ? moment(fromDate).format() : undefined,
      to: toDate ? moment(toDate).format() : undefined,
      keys,
    },
  });

export default {
  getDashboardData,
};
