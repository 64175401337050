import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useStyles from './ValueWithCaptionStyle';

const ValueWithCaption = ({ value, caption, bold }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant='h2' className={bold ? classes.boldValue : classes.value}>
        {value}
      </Typography>
      {caption && (
        <Typography variant='h3' className={classes.caption}>
          {caption}
        </Typography>
      )}
    </Box>
  );
};

export default ValueWithCaption;

ValueWithCaption.propTypes = {
  value: PropTypes.string.isRequired,
  caption: PropTypes.string,
  bold: PropTypes.bool,
};

ValueWithCaption.defaultProps = {
  caption: '',
  bold: false,
};
