import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '../TextFields/TextField';
import BasicFormModal from './BasicFormModal';
import useNotifications from '../../contexts/notificationContext';
import { APP_NAME_CHARACTER_LIMIT } from '../../config';

const ConfirmNameModal = ({
  title,
  info,
  placeholder,
  confirmButtonLabel,
  onModalClose,
  onConfirmButtonPress,
}) => {
  const [value, setAppName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [inputErrorMessage, setInputErrorMessage] = useState(null);
  const { addSuccessNotification, addErrorNotification } = useNotifications();

  const onInputChange = (event) => {
    const { value: changedValue } = event.target;

    setAppName(changedValue);

    if (changedValue.length > APP_NAME_CHARACTER_LIMIT && !inputErrorMessage) {
      setInputErrorMessage(
        `Name cannot be greater than ${APP_NAME_CHARACTER_LIMIT} characters long`,
      );
    } else if (changedValue.length <= APP_NAME_CHARACTER_LIMIT && !!inputErrorMessage) {
      setInputErrorMessage(null);
    }
  };

  const onConfirmButton = async () => {
    setIsLoading(true);
    try {
      await onConfirmButtonPress(value);
      addSuccessNotification(`${value} Created`);
      onModalClose(true);
    } catch (err) {
      addErrorNotification(err.message);
      setIsLoading(false);
    }
  };

  const formControls = [
    <TextField
      value={value}
      onChange={onInputChange}
      placeholder={placeholder}
      helperText={inputErrorMessage}
      variant='outlined'
      data-testid='input-app-name'
    />,
  ];

  return (
    <BasicFormModal
      title={title}
      info={info}
      controls={formControls}
      confirmButtonText={confirmButtonLabel}
      confirmButtonDisabled={!value.length || !!inputErrorMessage}
      onCancel={onModalClose}
      onConfirm={onConfirmButton}
      onClose={onModalClose}
      isLoading={isLoading}
    />
  );
};

ConfirmNameModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  confirmButtonLabel: PropTypes.string.isRequired,
  onConfirmButtonPress: PropTypes.func.isRequired,
};

ConfirmNameModal.defaultProps = {
  info: '',
};

export default ConfirmNameModal;
