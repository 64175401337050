import PropTypes from 'prop-types';
import React from 'react';
import ReactJson from 'react-json-view';

const jsonViewerTheme = {
  /* Background */
  base00: '#rgba(0,0,0,0)',
  /* Edit Background & AddKeyModal Label & Validation Icon & Validation Font */
  base01: '#ddd',
  /* DataType-Background */
  base02: '#C2C6CE88',
  /* Unused */
  base03: '#0f0',
  /* Object Size & AddKeyModal Border */
  base04: '#747983',
  /* DataType-Undefined & AddKeyModal Background */
  base05: '#AF3552',
  /* Unused */
  base06: '#0ff',
  /* Braces & Keys & Edit Border */
  base07: '#666',
  /* DataType-NaN */
  base08: '#faa',
  /* Ellipsis & DataType-String & ValidationFailure-Background & Edit Cancel/Remove Icons */
  base09: '#BA7421',
  /* DataType-Null & DataType-Regex & Edit Text & AddKeyModal Text */
  base0A: '#B42525',
  /* DataType-Float */
  base0B: '#0053f0',
  /* Array Key */
  base0C: '#AF3552',
  /* DataType-Date & DataType-Function */
  base0D: '#2C9090',
  /* DataType-Boolean & Edit Icon & Edit Add Icon & Edit Check Icon */
  base0E: '#4ec349',
  /* DataType-Integer */
  base0F: '#3a7de7',
};

const JSONViewer = ({ data, style }) => {
  return (
    <ReactJson
      src={data}
      displayDataTypes={false}
      name={false}
      groupArraysAfterLength={10}
      collapseStringsAfterLength={200}
      iconStyle='circle'
      style={{ backgroundColor: 'white', ...style }}
      theme={jsonViewerTheme}
    />
  );
};

export default JSONViewer;

JSONViewer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

JSONViewer.defaultProps = {
  data: undefined,
  style: {},
};
