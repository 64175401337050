import moment from 'moment';
import apiUrls from '../../apiUrls';
import { HTTP } from '../../index';

const { EVENTS, EVENT_OVERVIEW } = apiUrls;

const getEvents = ({ appId }) => HTTP.waApi.get(EVENTS(appId));

const getEventOverview = (appId, fromDate, toDate) =>
  HTTP.waApi.get(EVENT_OVERVIEW(appId), {
    params: {
      from: fromDate ? moment(fromDate).format() : undefined,
      to: toDate ? moment(toDate).format() : undefined,
    },
  });

export default {
  getEvents,
  getEventOverview,
};
