import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Divider from '@material-ui/core/Divider';
import { Button } from '@material-ui/core';

import styles from './SessionsTablePaginationStyles';
import TextField from '../../TextFields/TextField';

const onChange = (ev, totalPages, setLocalPage) => {
  ev.preventDefault();
  const { value } = ev.target;
  if (Number(value) || value === '') {
    if (ev.target.value > totalPages) setLocalPage(totalPages - 1);
    if (ev.target.value < 0) return;
    setLocalPage(ev.target.value);
  }
};

const buttonPress = (value, setLocalPage, changePage, totalPages) => {
  if (value > totalPages) {
    return;
  }

  if (value < 1) {
    return;
  }

  setLocalPage(value);
  changePage(value);
};

const onKeyChange = (ev, localPage, setLocalPage, totalPages, changePage) => {
  if (ev.key === 'Enter') {
    ev.preventDefault();

    if (localPage > totalPages) {
      setLocalPage(totalPages);
      changePage(totalPages);
      return;
    }

    if (localPage < 1) {
      setLocalPage(1);
      changePage(1);
      return;
    }

    changePage(localPage);
  }
};

const SessionsTablePagination = ({
  page,
  changePage,
  totalElements,
  totalPages,
  sessionsPerPage,
}) => {
  const [localPage, setLocalPage] = useState(page);
  const classes = styles();
  const minRange = (page - 1) * sessionsPerPage;
  const maxRange =
    sessionsPerPage > totalElements - minRange ? totalElements : minRange + sessionsPerPage;

  useEffect(() => {
    if (page !== localPage) {
      setLocalPage(page);
    }
  }, [page]);

  return (
    <Grid container>
      {totalElements > 0 && (
        <>
          <Grid item xs={4} className={classes.leftGridItem}>
            <Typography
              variant='body1'
              color='textSecondary'
              className={classes.paginationSessionShowing}
            >
              {`Showing ${minRange + 1} to ${maxRange} of ${totalElements} sessions`}
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.rightGridItem}>
            <Button
              className={classes.button}
              aria-label='sessions-previous-page'
              onClick={() => buttonPress(localPage - 1, setLocalPage, changePage, totalPages)}
            >
              <ArrowBackIosRoundedIcon className={classes.iconPrevious} fontSize='small' />
              Previous
            </Button>
            <Divider orientation='vertical' variant='middle' className={classes.divider} />
            <Typography variant='body1' color='textSecondary'>
              page
            </Typography>
            <TextField
              id='page'
              value={localPage}
              variant='outlined'
              className={classes.pageTextField}
              onChange={(ev) => onChange(ev, totalPages, setLocalPage)}
              onKeyPress={(ev) => onKeyChange(ev, localPage, setLocalPage, totalPages, changePage)}
              inputProps={{
                className: classes.pageInput,
                'data-testid': 'sessionPagination-pageChangeTextField',
                max: totalPages,
              }}
              noClear
            />
            <Typography variant='body1' color='textSecondary'>{`of ${totalPages}`}</Typography>
            <Divider orientation='vertical' variant='middle' className={classes.divider} />
            <Button
              className={classes.button}
              aria-label='sessions-next-page'
              onClick={() => buttonPress(localPage + 1, setLocalPage, changePage, totalPages)}
            >
              Next
              <ArrowForwardIosRoundedIcon className={classes.iconNext} fontSize='small' />
            </Button>
          </Grid>
        </>
      )}

      {totalElements < 1 && (
        <>
          <Grid item xs={10} className={classes.leftGridItem}>
            <Typography
              variant='body1'
              color='textPrimary'
              className={classes.paginationSessionShowing}
            >
              No Sessions to display
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

SessionsTablePagination.propTypes = {
  page: PropTypes.number,
  totalPages: PropTypes.number,
  changePage: PropTypes.func,
  totalElements: PropTypes.number,
  sessionsPerPage: PropTypes.number,
};

SessionsTablePagination.defaultProps = {
  page: null,
  totalPages: null,
  changePage: undefined,
  totalElements: 0,
  sessionsPerPage: null,
};

export default SessionsTablePagination;
