import React from 'react';
import PropTypes from 'prop-types';
import Sidebar from '../Display/Sidebar';
import FunnelBuilder from '../../pages/funnels/funnelBuilder/funnelBuilder';

const FunnelSidebar = ({
  funnelEventData,
  onFunnelGenerate,
  onSaveFunnel,
  isLoading,
  currentFunnel,
  setCurrentFunnel,
}) => {
  return (
    <Sidebar title='Create Funnel'>
      <FunnelBuilder
        funnelEventData={funnelEventData}
        onFunnelGenerate={onFunnelGenerate}
        isLoading={isLoading}
        currentFunnel={currentFunnel}
        setCurrentFunnel={setCurrentFunnel}
        onSaveFunnel={onSaveFunnel}
      />
    </Sidebar>
  );
};

export default FunnelSidebar;

FunnelSidebar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  funnelEventData: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentFunnel: PropTypes.array.isRequired,
  onFunnelGenerate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSaveFunnel: PropTypes.func.isRequired,
  setCurrentFunnel: PropTypes.func.isRequired,
};
