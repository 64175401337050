import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const SubHeader = ({ children, className }) => (
  <Typography variant='h2' className={className}>
    {children}
  </Typography>
);

SubHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.oneOfType([PropTypes.string]),
};

SubHeader.defaultProps = {
  className: undefined,
};

export default SubHeader;
