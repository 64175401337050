import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    modalContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
      margin: '0 auto',
    },
    modal: {
      position: 'relative',
      display: 'flex',
      margin: 'auto',
      minHeight: '251px',
      minWidth: '30vw',
      maxWidth: '80vw',
    },
    closeButton: {
      position: 'absolute',
      top: 10,
      right: 10,
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 70,
      paddingBottom: 70,
    },
    formTitle: {
      fontSize: 18,
    },
    formInfoText: {
      fontSize: 14,
    },
    formControl: {
      margin: 0,
    },
    formActionButton: {
      paddingTop: 10,
      paddingBottom: 10,
      width: '100%',
    },
    textCenter: {
      textAlign: 'center',
    },
    infoIcon: {
      width: '100%',
      fontSize: 36,
      color: theme.palette.forageRed,
    },
    textButton: {
      textDecoration: 'underline',
      color: theme.palette.text.secondary,
    },
  }),
);
