import apiUrls from '../../apiUrls';
import { HTTP } from '../../index';

const { ADMIN_DASHBOARD } = apiUrls;

const getAdminDashboard = () => HTTP.waApi.get(ADMIN_DASHBOARD);

export default {
  getAdminDashboard,
};
