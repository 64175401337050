import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Form, Formik } from 'formik';
import React from 'react';

import AlertArray from '../Display/AlertArray';
import LoadingButton from '../Buttons/LoadingButton';
import PasswordField from '../TextFields/PasswordField';

import { updatePasswordValidation } from '../../validation/updatePasswordValidation';
import useStyles from './UpdatePasswordStyle';

const initialValues = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
};

const UpdatePassword = ({ onSubmit }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={updatePasswordValidation}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
        <Form method='post'>
          <AlertArray severity='error' alerts={errors.general} />
          <AlertArray severity='info' alerts={values.general} />

          <PasswordField
            id='currentPassword'
            label='Current Password'
            placeholder='Current Password'
            value={values.currentPassword}
            error={touched.currentPassword && errors.currentPassword}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <PasswordField
            id='newPassword'
            label='New Password'
            placeholder='New Password'
            value={values.newPassword}
            error={touched.newPassword && errors.newPassword}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <PasswordField
            id='passwordConfirmation'
            label='Confirm Password'
            placeholder='Confirm Password'
            value={values.passwordConfirmation}
            error={touched.passwordConfirmation && errors.passwordConfirmation}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <Box className={classes.submitButtonContainer}>
            <LoadingButton
              isLoading={isSubmitting}
              type='submit'
              id='submit'
              name='Update Password'
              className={classes.submitButton}
            >
              Update Password
            </LoadingButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default UpdatePassword;

UpdatePassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
