import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme';

export default makeStyles({
  card: {
    position: 'relative',
    padding: 30,
  },
  cardHeaderText: {
    fontSize: '15px',
  },
  cardContent: {
    padding: 0,
    marginTop: 20,
    '&:last-child': {
      padding: 0,
    },
  },
  actionMenuButton: {
    position: 'absolute',
    top: 10,
    right: 5,
  },
  actionMenuItem: {
    color: theme.palette.text.secondary,
  },
  actionMenuItemIcon: {
    minWidth: 0,
    paddingRight: '16px',
  },
  actionMenuItemText: {
    paddingRight: '15px',
  },
  applicationName: {
    marginBottom: '10px',
    fontWeight: '600',
    fontSize: '23px',
  },
  creationDate: {
    marginBottom: '20px',
    fontSize: '12px',
  },
  viewAppKeyButton: {
    fontSize: '15px',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.8,
      cursor: 'pointer',
    },
  },
});
