import { createSelector } from 'reselect';

export const appsSelector = createSelector(
  (state) => state.UserOptions.apps,
  (apps) => ({ apps }),
);

export default createSelector(
  (state) => state.UserOptions,
  (UserOptions) => {
    const { currentAppId, currentAppKey, apps, selectedDateRange } = UserOptions;

    return {
      currentAppId,
      currentAppKey,
      apps,
      fromDate: new Date(selectedDateRange.fromDate),
      toDate: new Date(selectedDateRange.toDate),
    };
  },
);
