import { makeStyles } from '@material-ui/core/styles';

const borderRadius = '4px';

export default makeStyles((theme) => ({
  tab: {
    backgroundColor: theme.palette.common.background,
    border: `1px solid ${theme.palette.common.borderColor}`,
    borderLeft: 'none',
    opacity: 1,
    textTransform: 'none',
    color: theme.palette.text.primary,
  },
  start: {
    borderTopLeftRadius: borderRadius,
    borderLeft: `1px solid ${theme.palette.common.borderColor}`,
  },
  end: {
    borderTopRightRadius: borderRadius,
  },
  selected: {
    backgroundColor: 'white',
    borderBottom: 0,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
}));
