import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import Grid from '@material-ui/core/Grid';

import styles from './AppKeyModalStyles';
import appsApi from '../../api/v1/app/app';
import appTypes from '../../utils/types/apps';
import LoadingAnimation from '../Spinners/LoadingAnimation';

const AppKeyModal = ({ app, onModalClose }) => {
  const classes = styles();
  const [appKey, setAppKey] = useState();
  const textRef = useRef(null);

  useEffect(() => {
    (async () => {
      let appId;

      if (!app) {
        const authorisedAppsPayload = await appsApi.getAuthorisedApps();
        appId = authorisedAppsPayload.data[0].id;
      } else {
        appId = app.id;
      }

      const appKeyPayload = await appsApi.getAppKey(appId);
      setAppKey(appKeyPayload.data);
    })();
  }, []);

  let appKeyComponent;

  if (appKey) {
    appKeyComponent = (
      <p className={styles.textStyle} ref={textRef} data-testid='appKey-text'>
        {appKey}
      </p>
    );
  } else {
    appKeyComponent = <LoadingAnimation height={100} width={100} />;
  }

  return (
    <Fade in>
      <Paper data-testid='appKeyModal' className={classes.container}>
        <Grid container>
          <Grid item xs={12} className={classes.topItem}>
            <IconButton color='primary' aria-label='close the app key modal' onClick={onModalClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} className={classes.centreItem}>
            <Typography variant='body1' className={classes.titleText}>
              Your App Key
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h2' className={classes.bodyText}>
              {appKeyComponent}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Fade>
  );
};

export default AppKeyModal;

AppKeyModal.defaultProps = {
  app: null,
};

AppKeyModal.propTypes = {
  app: appTypes.app,
  onModalClose: PropTypes.func.isRequired,
};
