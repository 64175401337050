import { useCallback } from 'react';
import authApi from '../api/v1/auth/signin';
import { login } from '../utils/auth/auth';
import errors from '../messages/errors';
import info from '../messages/info';
import analytics from '../utils/analytics';

export const useSignInCallback = (history, setLoading) =>
  useCallback(
    async (values, actions) => {
      const { emailAddress, password } = values;

      try {
        setLoading(true);
        const res = await authApi.signIn(emailAddress, password);
        // Placeholder
        actions.setFieldValue('general', ['Success']);
        actions.setSubmitting(false);
        setLoading(false);

        analytics.setUser(emailAddress);
        login({ token: res.data.access_token, history });
      } catch (e) {
        const errorMessage = e.message;
        // Placeholder
        actions.setFieldError('general', [errorMessage]);
        actions.setFieldValue('password', '');
        actions.setSubmitting(false);
        setLoading(false);
      }
    },
    [history, setLoading],
  );

export const useSignUpCallback = (history, setLoading) =>
  useCallback(
    async (values, actions) => {
      const {
        emailAddress,
        firstName,
        lastName,
        organisationName,
        password,
        passwordConfirmation,
        termsAndConditionsAccepted,
      } = values;

      try {
        setLoading(true);

        if (!termsAndConditionsAccepted) {
          throw Error('Please accept EULA + Privacy Policy');
        }

        const res = await authApi.signUp(
          emailAddress,
          firstName,
          lastName,
          organisationName,
          password,
          passwordConfirmation,
          termsAndConditionsAccepted,
        );
        console.log('Response: ', res);
        // Placeholder
        actions.setFieldValue('general', ['Success']);
        actions.setSubmitting(false);
        setLoading(false);

        await history.replace({
          pathname: '/auth/signIn',
          state: { message: info.SIGN_UP_SUCCESS },
        });
      } catch (e) {
        let errorToShow = e.message === 'Network Error' ? errors.SERVER_NOT_AVAILABLE : e.message;

        if (!(errorToShow instanceof Array)) {
          errorToShow = [errorToShow];
        }
        // Placeholder
        actions.setFieldError('general', errorToShow);
        actions.setFieldValue('password', '');
        actions.setFieldValue('passwordConfirmation', '');
        actions.setSubmitting(false);
        setLoading(false);
      }
    },
    [history, setLoading],
  );
