import moment from 'moment';
import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import * as OrganisationTypes from '../../../utils/types/organisation';
import useStyles from './OrganisationMetaDataStyle';

const OrganisationMetaData = ({ createdOn, applications, team, subscription }) => {
  const createdOnDate = moment(createdOn).format('ddd D MMM YYYY');

  const classes = useStyles();

  return (
    <Grid container className={classes.organisationMetadataRoot}>
      <Box className={classes.organisationMetadata}>
        <Typography variant='subtitle2' color='textSecondary' className={classes.header}>
          Created On
        </Typography>
        <Typography variant='body1' color='textPrimary' className={classes.label}>
          {createdOnDate}
        </Typography>
      </Box>

      <Box className={classes.organisationMetadata}>
        <Typography variant='subtitle2' color='textSecondary' className={classes.header}>
          Applications
        </Typography>
        <Typography variant='body1' color='textPrimary' className={classes.label}>
          {applications}
        </Typography>
      </Box>

      <Box className={classes.organisationMetadata}>
        <Typography variant='subtitle2' color='textSecondary' className={classes.header}>
          Team
        </Typography>
        <Typography variant='body1' color='textPrimary' className={classes.label}>
          {team}
        </Typography>
      </Box>

      {!!subscription && (
        <Box className={classes.organisationMetadata}>
          <Typography variant='subtitle2' color='textSecondary' className={classes.header}>
            Subscription
          </Typography>
          <Typography variant='body1' color='textPrimary' className={classes.label}>
            {subscription}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default OrganisationMetaData;

OrganisationMetaData.propTypes = OrganisationTypes.organisation;

OrganisationMetaData.defaultProps = {
  subscription: undefined,
};
