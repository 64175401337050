import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import types from '../../../../utils/types/session';
import createTimelineItemProperties from './TimelineItemPropertyFactory';
import useRootStyles from './TimelineItemStyle';

const TimelineItem = ({ timelineItem, selected, onClick, 'data-testid': testId }) => {
  const {
    displayText,
    classOverrides,
    formattedTimestamp,
    Icon,
    clickable,
  } = createTimelineItemProperties({
    timelineItem,
    selected,
  });

  const wrappedOnClick = () => {
    if (clickable && onClick) onClick(timelineItem);
  };

  const classes = useRootStyles();
  return (
    <ButtonBase
      component={Box}
      disabled={!clickable}
      className={clsx(classes.root, classOverrides?.root, {
        [classes.clickable]: clickable,
      })}
      onClick={wrappedOnClick}
      data-testid={testId}
    >
      {/* Timestamp */}
      <Typography className={clsx(classes.timestamp, classOverrides?.timestamp)}>
        {formattedTimestamp}
      </Typography>

      {/* Label */}
      <Typography className={clsx(classes.label, classOverrides?.label)}>{displayText}</Typography>

      {Icon && (
        <Icon
          fontSize='small'
          className={clsx(classes.icon, classOverrides?.icon)}
          data-testid='TimelineItem-Icon'
        />
      )}
    </ButtonBase>
  );
};

export default TimelineItem;

TimelineItem.propTypes = {
  timelineItem: types.timelineItem.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  'data-testid': PropTypes.string,
};

TimelineItem.defaultProps = {
  onClick: undefined,
  selected: false,
  'data-testid': undefined,
};
