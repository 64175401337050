import { arrayOf, shape, string } from 'prop-types';

const app = shape({
  id: string,
  appName: string,
  creationDate: string,
});

const apps = arrayOf(app);

export default {
  app,
  apps,
};
