import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  title: {
    marginBottom: 5,
    fontSize: 14,
  },
  text: {
    fontSize: 15,
  },
});
