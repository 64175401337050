import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AddCircleIcon from '@material-ui/icons/AddCircleRounded';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import EventFilterModal from '../../Modals/EventFilterModal';

import SidebarSubHeader from '../../Text/SidebarSubHeader';
import TextField from '../../TextFields/TextField';

import useStyles from './EventSearchSectionStyle';

import types from '../../../utils/types/events';
import CheckboxInput from '../CheckboxInput';

const EventSearchSection = ({
  eventFilters,
  eventFilterFunctions,
  events,
  loadingEvents,
  showOnlySessionsWithCrashes,
  setShowOnlySessionsWithCrashes,
}) => {
  const { addEventFilter, removeEventFilter, updateEventFilterProperty } = eventFilterFunctions;

  const [eventFilterModalVisible, setShowEventFilterModal] = useState(false);

  const classes = useStyles();

  const showEventFilterModal = () => {
    setShowEventFilterModal(true);
  };

  const hideEventFilterModal = () => {
    setShowEventFilterModal(false);
  };

  const renderEventPropertyFilter = (event) => {
    return event?.propertyFilters?.map((property) => (
      <Box key={property.key} data-testid='EventSearchSection-propertyFilter'>
        <Typography className={classes.eventProperty}>{property.key}</Typography>
        <TextField
          id='property_change'
          variant='outlined'
          placeholder='Enter data...'
          onChange={(e) => updateEventFilterProperty(event.eventName, property.key, e.target.value)}
          className={classes.propertyFilter}
        />
      </Box>
    ));
  };

  const toggleShowOnlySessionsWithCrashes = () => {
    setShowOnlySessionsWithCrashes(!showOnlySessionsWithCrashes);
  };

  return (
    <>
      <SidebarSubHeader className={classes.title}>EVENTS & PROPERTIES</SidebarSubHeader>

      {!!eventFilters && !!eventFilters.length && (
        <>
          <Divider />

          <Box className={classes.eventsRoot} data-testid='EventSearchSection-eventsRoot'>
            {eventFilters.map((event) => (
              <React.Fragment key={event.eventName}>
                <Box className={classes.eventRow}>
                  <Typography variant='body1' className='eventText'>
                    {event.eventName}
                  </Typography>

                  <CloseIcon
                    fontSize='small'
                    className='eventRemoveIcon'
                    onClick={() => removeEventFilter(event.eventName)}
                    aria-label='remove event'
                  />
                </Box>

                {renderEventPropertyFilter(event)}
              </React.Fragment>
            ))}
          </Box>
        </>
      )}

      <Divider />

      <Box className={classes.addEventRoot} onClick={showEventFilterModal}>
        <AddCircleIcon color='primary' fontSize='small' />
        <Typography variant='body2' className={classes.addEventText}>
          Add Event
        </Typography>
      </Box>

      <Divider />

      <CheckboxInput
        isActive={showOnlySessionsWithCrashes}
        label='View only crashed sessions'
        onSetActive={toggleShowOnlySessionsWithCrashes}
        padding={12}
      />

      {!!eventFilterModalVisible && (
        <EventFilterModal
          open={eventFilterModalVisible}
          onClose={hideEventFilterModal}
          addEventFilter={addEventFilter}
          events={events}
          loadingEvents={loadingEvents}
        />
      )}
    </>
  );
};

export default EventSearchSection;

EventSearchSection.propTypes = {
  eventFilterFunctions: types.eventFilterFunctions.isRequired,
  events: types.events.isRequired,
  eventFilters: types.eventFilters,
  loadingEvents: PropTypes.bool,
  showOnlySessionsWithCrashes: PropTypes.bool,
  setShowOnlySessionsWithCrashes: PropTypes.func.isRequired,
};

EventSearchSection.defaultProps = {
  eventFilters: [],
  loadingEvents: false,
  showOnlySessionsWithCrashes: false,
};
