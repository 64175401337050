import { isGroupIndicator } from '../session/sessionUtils';

// eslint-disable-next-line import/prefer-default-export
export const mapSessionTimeline = (sessionDetails) => {
  const { session, timeline, deviceInfo } = sessionDetails;

  const mappedTimeline = [];
  let currentGroup;

  timeline.forEach((timelineItem) => {
    if (isGroupIndicator(timelineItem.type)) {
      currentGroup = { ...timelineItem, events: [] };
      mappedTimeline.push(currentGroup);
      return;
    }

    if (currentGroup) {
      currentGroup.events.push(timelineItem);
    } else {
      mappedTimeline.push(timelineItem);
    }
  });

  return {
    session,
    deviceInfo,
    timeline: mappedTimeline,
  };
};
