import React, { forwardRef, useImperativeHandle, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import types from '../../../../utils/types/session';

import TabItem from '../../../Inputs/TabItem';
import JSONViewer from '../../JSONViewer';
import TabPanel from '../../TabPanel';
import Tabs from '../../Tabs';
import useStyles from './SessionEventDetailsStyles';

const SessionEventDetails = forwardRef((props, ref) => {
  const { timelineItem, deviceInfo, sdkVersion } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const handleTabChange = (__, tabIndex) => {
    setSelectedTabIndex(tabIndex);
  };

  useImperativeHandle(ref, () => ({
    selectTab: setSelectedTabIndex,
  }));

  const classes = useStyles();

  return (
    <>
      <Tabs value={selectedTabIndex} onChange={handleTabChange}>
        <TabItem label='Technical Details' selected={selectedTabIndex === 0} start />
        <TabItem label='Event Details' selected={selectedTabIndex === 1} end />
      </Tabs>

      <TabPanel
        panelIndex={0}
        currentIndex={selectedTabIndex}
        className={classes.tabPanel}
        data-testid='EventDetails_TabPanel_Technical'
      >
        {!deviceInfo ? (
          <Typography>No Technical Data Sent</Typography>
        ) : (
          <JSONViewer data={{ sdkVersion, ...deviceInfo }} />
        )}
      </TabPanel>

      <TabPanel
        panelIndex={1}
        currentIndex={selectedTabIndex}
        className={classes.eventDetailsTabPanel}
        data-testid='EventDetails_TabPanel_Event'
      >
        <Typography className={classes.label}>{timelineItem?.label}</Typography>
        <JSONViewer data={timelineItem?.properties} />
      </TabPanel>
    </>
  );
});

export default SessionEventDetails;

SessionEventDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  deviceInfo: types.object,
  timelineItem: types.timelineItem,
  sdkVersion: types.string,
};

SessionEventDetails.defaultProps = {
  timelineItem: undefined,
  deviceInfo: undefined,
  sdkVersion: undefined,
};
