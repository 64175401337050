import PropTypes from 'prop-types';
import React from 'react';
import types from '../../utils/types/events';

import Sidebar from '../Display/Sidebar';
import EventSearchSection from './SidebarSections/EventSearchSection';
import SideBarSearchField from './SidebarSections/SideBarSearchField';

const SearchAndFilterSidebar = ({
  usernameFilter,
  setUsernameFilter,
  eventFilters,
  eventFilterFunctions,
  showOnlySessionsWithCrashes,
  setShowOnlySessionsWithCrashes,
  events,
  loadingEvents,
}) => {
  return (
    <Sidebar title='Search & Filter'>
      <SideBarSearchField title='USERS' value={usernameFilter} onChange={setUsernameFilter} />
      <EventSearchSection
        eventFilters={eventFilters}
        eventFilterFunctions={eventFilterFunctions}
        showOnlySessionsWithCrashes={showOnlySessionsWithCrashes}
        setShowOnlySessionsWithCrashes={setShowOnlySessionsWithCrashes}
        events={events}
        loadingEvents={loadingEvents}
      />
    </Sidebar>
  );
};

export default SearchAndFilterSidebar;

SearchAndFilterSidebar.propTypes = {
  eventFilters: types.eventFilters,
  eventFilterFunctions: types.eventFilterFunctions.isRequired,
  setUsernameFilter: PropTypes.func.isRequired,
  usernameFilter: PropTypes.string,
  events: types.events.isRequired,
  loadingEvents: PropTypes.bool,
  showOnlySessionsWithCrashes: PropTypes.bool,
  setShowOnlySessionsWithCrashes: PropTypes.func.isRequired,
};

SearchAndFilterSidebar.defaultProps = {
  eventFilters: [],
  usernameFilter: '',
  loadingEvents: false,
  showOnlySessionsWithCrashes: false,
};
