import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TopNavigation from './Navigation/TopNavigation';
import Header from '../Text/Header';
import styles from './PageLayoutStyle';
import LoadingAnimation from '../Spinners/LoadingAnimation';
import appsTypes from '../../utils/types/apps';
import DisplayError from '../Generic/DisplayError';
import hidingSquirrel from '../../static/media/hiding_squirrel.svg';

const PageLayout = ({
  children,
  isLoading,
  error,
  title,
  noPadding,
  sidebar,
  fromDate,
  toDate,
  onDatesSelected,
  highlightTab,
  apps,
  currentAppId,
  centerContentHorizontal,
  centerContentVertical,
}) => {
  const classes = styles();

  const renderError = () => {
    return (
      <div className={classes.errorContainer}>
        <DisplayError
          title='Oops, Something went wrong!'
          description='We have had trouble foraging for this. If this continues to be a problem please contact support.'
          error={error}
          linkLocation='/'
          linkText='Go Back'
          image={hidingSquirrel}
        />
      </div>
    );
  };

  return (
    <div className={classes.pageContainer}>
      <TopNavigation
        highlightTab={highlightTab || title}
        fromDate={fromDate}
        toDate={toDate}
        onDatesSelected={onDatesSelected}
        apps={apps}
        currentAppId={currentAppId}
      />

      <Box className={classes.sidebarAndContent}>
        {sidebar}

        <div
          className={`${classes.contentContainer} ${noPadding && classes.noPadding} ${
            (centerContentVertical || isLoading) && classes.centerContentVertical
          } ${(centerContentHorizontal || isLoading) && classes.centerContentHorizontal}`}
          data-testid='pageLayoutContainer'
        >
          {title && !isLoading && <Header>{title}</Header>}
          {isLoading && <LoadingAnimation height={150} width={150} />}
          {error && renderError()}
          {!isLoading && !error && children}
        </div>
      </Box>

      {process.env.REACT_APP_SHOW_DEBUGGING_INFO === 'true' && (
        <Typography variant='body1' className={classes.debugInfo}>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          {process.env.REACT_APP_AWS_BRANCH} - {process.env.REACT_APP_AWS_COMMIT_ID}
        </Typography>
      )}
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  noPadding: PropTypes.bool,
  sidebar: PropTypes.element,
  title: PropTypes.string,
  highlightTab: PropTypes.string,
  fromDate: PropTypes.instanceOf(Date).isRequired,
  toDate: PropTypes.instanceOf(Date).isRequired,
  onDatesSelected: PropTypes.func.isRequired,
  apps: appsTypes.apps.isRequired,
  currentAppId: PropTypes.string.isRequired,
  centerContentHorizontal: PropTypes.bool,
  centerContentVertical: PropTypes.bool,
};

PageLayout.defaultProps = {
  children: undefined,
  error: '',
  isLoading: false,
  noPadding: false,
  sidebar: undefined,
  title: undefined,
  highlightTab: undefined,
  centerContentHorizontal: false,
  centerContentVertical: false,
};

export default PageLayout;
