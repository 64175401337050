import apiUrls from '../../apiUrls';
import { HTTP } from '../../index';

const { AUTHORISED_APPS, APP_KEY, ADD_NEW_APP, DELETE_APP } = apiUrls;

const getAuthorisedApps = () => HTTP.waApi.get(AUTHORISED_APPS);

const getAppKey = (id) => HTTP.waApi.get(APP_KEY(id));

const addNewApp = (appName) =>
  HTTP.waApi.post(ADD_NEW_APP, {
    appName,
  });

const editAppName = (appId, name) =>
  HTTP.waApi.patch(ADD_NEW_APP, {
    appId,
    name,
  });

const deleteApp = (id) => HTTP.waApi.delete(DELETE_APP(id));

export default {
  getAuthorisedApps,
  getAppKey,
  addNewApp,
  editAppName,
  deleteApp,
};
