import PropTypes from 'prop-types';
import React from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '../Buttons/Button';
import useStyle from './InformationScreenStyle';

/**
 * @description This is just a temporary component until we can get a design for the screen
 */
const InformationScreen = ({ title, message, actionButton, onActionButtonClick }) => {
  const classes = useStyle();
  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        {!!title && (
          <Typography variant='h1' className={classes.title}>
            {title}
          </Typography>
        )}

        {!!message && (
          <Typography variant='subtitle2' className={classes.message}>
            {message}
          </Typography>
        )}

        {!!actionButton && (
          <Button fullWidth onClick={onActionButtonClick}>
            {actionButton}
          </Button>
        )}
      </Card>
    </Box>
  );
};

export default InformationScreen;

InformationScreen.propTypes = {
  message: PropTypes.node,
  title: PropTypes.node,
  actionButton: PropTypes.string,
  onActionButtonClick: PropTypes.func,
};

InformationScreen.defaultProps = {
  message: undefined,
  title: undefined,
  actionButton: undefined,
  onActionButtonClick: undefined,
};
