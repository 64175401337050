import { makeStyles } from '@material-ui/core/styles';

export const groupHeaderStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${theme.palette.common.borderColor}`,
  },
  timestamp: {
    color: theme.palette.text.primary,
    fontSize: 15,
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: 15,
  },
}));

export const usernameStyles = makeStyles(() => ({
  root: {},
}));

export const eventStyles = makeStyles(() => ({
  root: {
    backgroundColor: ({ selected, textColor }) => selected && `${textColor}30`,
  },
  timestamp: {
    color: ({ selected, textColor }) => selected && textColor,
    fontWeight: ({ selected }) => selected && 'bold',
  },
  label: {
    color: ({ textColor }) => textColor,
    fontWeight: ({ selected }) => selected && 'bold',
  },
}));

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 16px',
    alignItems: 'center',
    color: theme.palette.text.secondary,

    // Make only the next div have a top border
    '&+div': {
      borderTop: `1px solid ${theme.palette.common.borderColor}`,
    },
  },
  timestamp: {
    minWidth: '80pt',
    wordWrap: 'break-word',
    textAlign: 'left',
    fontSize: 15,
  },
  label: {
    wordBreak: 'break-word',
    flexGrow: 1,
    fontSize: 15,
  },
  icon: {
    paddingLeft: 20,
  },
}));
