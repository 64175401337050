import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  container: {
    minHeight: '200pt',
    display: 'flex',
    paddingRight: 3,
    paddingLeft: 3,
    flex: 1,
    flexDirection: 'column',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar ': {
      display: 'none',
    },
    height: '100%',
  },
  table: {
    borderSpacing: '0 3pt',
  },
  spinnerContainer: {
    margin: '20pt',
    display: 'flex',
    justifyContent: 'center',
  },
  tableRow: {
    backgroundColor: '#fff',
    borderRadius: 8,
    cursor: 'pointer',
  },
  tableHeadCell: {
    color: '#8D8D8D',
    fontSize: 14,
    lineHeight: '18px',
  },
  tableBodyCell: {
    fontWeight: 'normal',
    fontSize: 13,
    width: '5%',

    '&.messageCell': {
      width: '55%',
    },
    '&.dateCell': {
      width: '10%',
    },
    '& span.lighterText': {
      color: '#8D8D8D',
    },
  },
});
