import React from 'react';
import hidingSquirrel from '../../static/media/hiding_squirrel.svg';
import DisplayError from '../../components/Generic/DisplayError';

const styles = {
  container: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
};

const Page404 = () => (
  <div style={styles.container}>
    <DisplayError
      title='Oops, you’re lost in the woods!'
      description='We have had trouble foraging for this. The page you were looking for doesn’t exist or isn’t available.'
      error='404'
      linkLocation='/'
      linkText='Go Back'
      image={hidingSquirrel}
    />
  </div>
);

export default Page404;
