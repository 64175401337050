import * as yup from 'yup';
// import { VALID_STRING } from './regex';

// const validStringErrorMessage = 'Must only contain valid characters';

// eslint-disable-next-line import/prefer-default-export
export const updateProfileValidation = yup.object().shape({
  firstName: yup.string().nullable().required('First name is required'),
  lastName: yup.string().nullable().required('Last name is required'),
  // MVP + Feature
  // email: yup
  //   .string()
  //   .nullable()
  //   .trim()
  //   .matches(VALID_STRING, validStringErrorMessage)
  //   .email('Email must be a valid email')
  //   .required('Email is required'),
});
