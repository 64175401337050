import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  buttonStyle: {
    display: 'flex',
    width: '100%',
    fontSize: 20,
    textDecoration: 'none',
    paddingLeft: '22pt',
    paddingTop: '12pt',
    paddingBottom: '12pt',
    color: '#2F2F4B',
    '&:hover': {
      backgroundColor: '#F4F6FA',
    },
  },
});
