import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import TimelineItem from './TimelineItem';
import useStyles from './TimelineGroupStyle';
import types from '../../../../utils/types/session';

const TimelineGroup = ({ timelineGroupItem, selectedItemId, onItemClick }) => {
  const { events } = timelineGroupItem;

  const classes = useStyles();
  return (
    <Box className={classes.root} data-testid={`TimelineGroup-${timelineGroupItem.id}`}>
      {/* Group Heading */}
      <TimelineItem
        timelineItem={timelineGroupItem}
        data-testid={`TimelineItem-GroupHeader-${timelineGroupItem.id}`}
      />

      {/* Group Events */}
      {!!events &&
        events.map((timelineItem) => (
          <TimelineItem
            key={timelineItem.id}
            timelineItem={timelineItem}
            selected={timelineItem.id === selectedItemId}
            onClick={onItemClick}
            data-testid={`TimelineItem-${timelineItem.id}`}
          />
        ))}
    </Box>
  );
};

export default TimelineGroup;

TimelineGroup.propTypes = {
  timelineGroupItem: types.timelineItem.isRequired,
  selectedItemId: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
};

TimelineGroup.defaultProps = {
  selectedItemId: undefined,
};
