import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import styles from './TextStyle';

const Text = ({ children, className = {} }) => {
  const classes = styles();
  return (
    <Typography variant='body1' className={(classes.textStyle, className)}>
      {children}
    </Typography>
  );
};

Text.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Text.defaultProps = {
  className: undefined,
};

export default Text;
