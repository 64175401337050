import { createStyles, makeStyles } from '@material-ui/core/styles';

const commonTextStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export default makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      margin: 'auto',
      textWrap: 'normal',
      flexDirection: 'column',
      padding: 10,
      minHeight: '175px',
      minWidth: '30vw',
      maxWidth: '38vw',
    },
    topItem: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    centreItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    bottomItem: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignSelf: 'flex-start',
      paddingBottom: 30,
    },
    titleText: {
      ...commonTextStyle,
      fontSize: 18,
    },
    bodyText: {
      ...commonTextStyle,
      color: theme.palette.primary.dark,
      fontWeight: 900,
      fontSize: 30,
      fontAlign: 'center',
      '& p': {
        margin: 10,
      },
    },
    button: {
      margin: 30,
    },
  }),
);
