import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';

import styles from './DisplayErrorStyle';

const DisplayError = ({
  title,
  description,
  additionalContent,
  error,
  linkLocation,
  linkText,
  image,
}) => {
  const classes = styles();

  return (
    <Container className={classes.container}>
      <Grid container className={classes.row}>
        <Grid container spacing={2} item xs={12} sm={10} md={8} className={classes.leftGrid}>
          <Grid item xs={12} className={classes.gridItem}>
            <Typography variant='h1' className={classes.title}>
              {title}
            </Typography>
          </Grid>

          {description && (
            <Grid item xs={12} className={classes.gridItem} data-testid='display-error-description'>
              <Typography variant='h2' className={classes.description}>
                {description}
              </Typography>
            </Grid>
          )}

          {additionalContent && (
            <Grid
              item
              xs={12}
              className={classes.gridItem}
              data-testid='display-error-additional-content'
            >
              {additionalContent}
            </Grid>
          )}

          {error && (
            <Grid item xs={12} className={classes.gridItem} data-testid='display-error-error'>
              <Typography variant='h3' className={classes.error}>
                {`Error: ${error}`}
              </Typography>
            </Grid>
          )}

          {linkText && (
            <Hidden xsDown>
              <Grid item xs={12} className={classes.gridItem}>
                <Link to={linkLocation} className={classes.link} data-testid='unexpected-link'>
                  <Button variant='contained' color='primary' className={classes.button}>
                    {linkText}
                  </Button>
                </Link>
              </Grid>
            </Hidden>
          )}
        </Grid>

        <Grid item xs={4} sm={2} md={4} className={classes.imageContainer}>
          <CardMedia image={image} className={classes.image} />
        </Grid>

        {linkText && (
          <Hidden smUp>
            <Grid item xs={12} className={classes.xsColumn}>
              <Link to={linkLocation} className={classes.xsLink} data-testid='unexpected-link-xs'>
                <Button variant='contained' color='primary' className={classes.xsButton}>
                  {linkText}
                </Button>
              </Link>
            </Grid>
          </Hidden>
        )}
      </Grid>
    </Container>
  );
};

DisplayError.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  additionalContent: PropTypes.node,
  error: PropTypes.string,
  linkLocation: PropTypes.string,
  linkText: PropTypes.string,
  image: PropTypes.string.isRequired,
};

DisplayError.defaultProps = {
  title: '',
  description: '',
  additionalContent: undefined,
  error: '',
  linkLocation: '',
  linkText: '',
};

export default DisplayError;
