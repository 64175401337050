import _ from 'lodash';
import { addOrMerge } from '../general/generalFunctions';

const mergeEventProperties = (existingEvent, newEvent) => {
  const { propertyFilters: existingProperties } = existingEvent;
  const { propertyFilters: newProperties } = newEvent;

  const updatedProperties = [
    ...existingProperties,
    // Get only the properties that are new (Updating existing properties not supported)
    ..._.differenceBy(newProperties, existingProperties, 'key'),
  ];

  return {
    ...newEvent,
    propertyFilters: updatedProperties,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const addEventToEventFilters = (newEventFilter, eventFilters) => {
  return addOrMerge(eventFilters, newEventFilter, 'eventName', mergeEventProperties);
};
