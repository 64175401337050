import PropTypes from 'prop-types';
import cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import appsApi from '../api/v1/app/app';
import InformationScreen from '../components/Display/InformationScreen';
import FirstTimeUserAppCreation from '../components/Section/FirstTimeUserAppCreation/FirstTimeUserAppCreation';
import useNotifications from '../contexts/notificationContext';

const RouteComponentWrapper = ({ Component, apps, setApps, setCurrentApp, ...rest }) => {
  const [fetchingApps, setFetchingApps] = useState(false);
  const { addErrorNotification } = useNotifications();

  const hasCookie = cookies.get('token');

  const getUsersApps = (firstAppSet = false) => {
    if ((hasCookie && !apps) || firstAppSet) {
      setFetchingApps(true);

      (async () => {
        // Fetch all authorised apps
        let fetchedApps;
        try {
          const { data } = await appsApi.getAuthorisedApps();
          fetchedApps = data;
          setApps(fetchedApps);
        } catch (e) {
          addErrorNotification(e.message);
          setFetchingApps(false);
          setApps([]);
          return;
        }

        // Set the current users app
        try {
          const appId = fetchedApps[0].id;
          const { data: appKey } = await appsApi.getAppKey(appId);
          setCurrentApp({ appId, appKey });
          console.log('Set current app: ', appId, appKey);
        } catch (e) {
          console.error('Error fetching app key', e);
        }

        setFetchingApps(false);
      })();
    }
  };

  useEffect(getUsersApps, [apps, hasCookie, setApps, setCurrentApp]);

  if (!hasCookie) {
    return <Redirect to='/auth/signIn' />;
  }

  if (!apps || fetchingApps) {
    return <InformationScreen title='Loading Apps...' message={"This shouldn't take a minute!"} />;
  }

  if (!apps.length) {
    return <FirstTimeUserAppCreation getUsersApps={getUsersApps} />;
  }

  return <Component {...rest} />;
};

export default RouteComponentWrapper;

RouteComponentWrapper.propTypes = {
  Component: PropTypes.func.isRequired,
  apps: PropTypes.arrayOf(PropTypes.object),
  setApps: PropTypes.func.isRequired,
  setCurrentApp: PropTypes.func.isRequired,
};

RouteComponentWrapper.defaultProps = {
  apps: undefined,
};
