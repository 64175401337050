import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default makeStyles((theme) =>
  createStyles({
    paperContainer: {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      minWidth: '30%',
      maxWidth: '400pt',
      justifyContent: 'center',
      padding: '40pt',
    },
    gridContainer: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
    },
    questionStyle: {
      fontWeight: 600,
      fontSize: '18pt',
      color: theme.palette.text.primary,
    },
    factTextStyle: {
      fontSize: '11pt',
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },
  }),
);
