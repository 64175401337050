import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './DefaultNavigationItemStyles';

const DefaultNavigationItem = ({
  page = '/',
  label,
  muted,
  onClick,
  activePage,
  dontNavigate = false,
}) => {
  const classes = styles();
  return (
    <Link
      to={!dontNavigate && page}
      onClick={onClick}
      className={`${classes.link} ${muted && classes.muted} ${
        activePage === label && classes.tabActive
      }`}
      data-testid='DefaultNavigationItem-Link'
    >
      {label}
    </Link>
  );
};

DefaultNavigationItem.propTypes = {
  label: PropTypes.string,
  muted: PropTypes.bool,
  page: PropTypes.string,
  onClick: PropTypes.func,
  activePage: PropTypes.string.isRequired,
  dontNavigate: PropTypes.bool,
};

DefaultNavigationItem.defaultProps = {
  label: undefined,
  muted: false,
  page: undefined,
  onClick: undefined,
  dontNavigate: false,
};

export default DefaultNavigationItem;
