import { useCallback } from 'react';
import organisationApi from '../api/v1/organisation/organisations';
import type NewUserBody from '../api/v1/organisation/types/NewUserBody.type';

// eslint-disable-next-line import/prefer-default-export
export const useAddNewUserCallback = (
  setLoading,
  onSuccess,
  addSuccessNotification,
  addErrorNotification,
) =>
  useCallback(
    async (values: NewUserBody, { setFieldError, resetForm }) => {
      setLoading(true);

      try {
        await organisationApi.addNewUser(values);
        resetForm();
        onSuccess(values);
        addSuccessNotification('Team Member Added');
      } catch (error) {
        if (error.errorsArray) {
          setFieldError('general', error.errorsArray);
        } else {
          addErrorNotification(error.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );
