import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  submitButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 15,
  },
  submitButton: {
    paddingLeft: 30,
    paddingRight: 30,
    height: 48,
  },
  nameInputContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  nameInputLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  inputMarginEnd: {
    paddingRight: 10,
  },
  inputMarginStart: {
    paddingLeft: 10,
  },
}));
