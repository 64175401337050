import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { useTable } from 'react-table';

import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Box,
} from '@material-ui/core';

import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ContentMissing from '../Display/ContentMissing';
import LoadingAnimation from '../Spinners/LoadingAnimation';

import sessionTypes from '../../utils/types/session';
import styles from './SessionsTableStyles';

const SessionsTable = ({ rowData, rowClick, loading }) => {
  const classes = styles();

  const columns = useMemo(
    () => [
      {
        Header: 'User',
        accessor: (d) => (d.username ? d.username : 'Anonymous'),
      },
      {
        Header: '',
        accessor: 'crashCount',
        Cell: (cell) => {
          return (
            cell.value > 0 && (
              <div className={classes.crashSessionIconCell}>
                <ReportProblemOutlinedIcon
                  fontSize='22'
                  className={classes.crashSessionIcon}
                  data-testid='SessionsTable_crashSessionIcon'
                />
              </div>
            )
          );
        },
      },
      {
        Header: 'Session Start',
        accessor: (data) => {
          return Moment(data.sessionStart).local().format('HH:mm:ss - Do MMMM YYYY');
        },
      },
      {
        Header: 'Duration',
        accessor: 'duration',
      },
      {
        Header: 'Session Number',
        accessor: 'session',
      },
      {
        Header: 'Screen Count',
        accessor: 'screenCount',
      },
      {
        Header: 'Event Count',
        accessor: 'eventCount',
      },
    ],
    [],
  );

  const data = useMemo(() => rowData, [rowData]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  });

  const onRowClick = (e, row) => {
    e.stopPropagation();
    return rowClick(row.original);
  };

  if (rowData.length < 1) {
    return <ContentMissing loading={loading} loadingMessage='Loading Sessions...' />;
  }

  return (
    <TableContainer className={classes.container}>
      {rowData.length > 0 && (
        <Table stickyHeader {...getTableProps()} className={classes.table}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} className={classes.tableHeadRow}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()} className={classes.tableHeadCell}>
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {!loading && (
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <TableRow
                    {...row.getRowProps()}
                    className={classes.tableRow}
                    onClick={(e) => onRowClick(e, row)}
                    data-testid={row.original.id}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()} className={classes.tableBodyCell}>
                          {cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      )}

      {loading && (
        <Box className={classes.spinnerContainer}>
          <LoadingAnimation height='15vh' width='15vw' />
        </Box>
      )}
    </TableContainer>
  );
};

SessionsTable.propTypes = {
  rowData: PropTypes.arrayOf(sessionTypes.sessionMetadata),
  rowClick: PropTypes.func,
  loading: PropTypes.bool,
};

SessionsTable.defaultProps = {
  rowData: [],
  rowClick: undefined,
  loading: false,
};

export default SessionsTable;
