import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      ...theme.scrollbar,
      display: 'compact',
      height: '100%',
      overflowY: 'auto',
    },
    billingDetailsContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    billingDetailsItem: {
      margin: '0 50px 15px 0',
    },
    dividerHorizontal: {
      margin: '25px 0',
    },
    mainContent: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflowY: 'hidden',
      '@media only screen and (max-width: 960px)': {
        flexDirection: 'column',
      },
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    billingHistorySection: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      marginTop: '20px',
    },
    sectionHeader: {
      fontSize: 20,
      fontWeight: 'bold',
      color: theme.palette.text.primary,
    },
    subscriptionInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100px',
      margin: '25px 0',
      padding: '20px',
      paddingLeft: '30px',
      paddingRight: '30px',
      backgroundColor: theme.palette.common.background,
      borderRadius: '5px',
    },
    sectionText: {
      fontSize: 15,
      color: theme.palette.text.secondary,
      marginTop: 10,
      maxWidth: 665,
    },
    infoSubText: {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
    infoMainText: {
      fontSize: 23,
      color: theme.palette.text.primary,
    },
  }),
);
