/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import move from 'lodash-move';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SideBarSearchField from '../../../components/Inputs/SidebarSections/SideBarSearchField';
import FunnelEventRow from './funnelEventRow';
import SidebarSubHeader from '../../../components/Text/SidebarSubHeader';
import CurrentFunnelEventRow from './currentFunnelEventRow';
import LoadingButton from '../../../components/Buttons/LoadingButton';
import SaveFunnelButton from './saveFunnelButton';

const customStyles = makeStyles((theme) => {
  return createStyles({
    eventsContainer: {
      ...theme.scrollbar,
      overflowY: 'scroll',
      marginBottom: '20px',
    },
    funnelContainer: {
      ...theme.scrollbar,
      maxHeight: '300px',
      overflowY: 'scroll',
    },
    funnelTextHeader: {
      paddingLeft: 15,
    },
    buttonStyle: {
      width: '90%',
      marginLeft: 15,
      marginTop: 10,
      marginRight: 10,
    },
  });
});

const FunnelBuilder = ({
  funnelEventData,
  onFunnelGenerate,
  onSaveFunnel,
  isLoading,
  currentFunnel,
  setCurrentFunnel,
}) => {
  const styles = customStyles();
  const [funnelSearchField, setFunnelSearchField] = useState('');
  const [eventsToRender, setEventsToRender] = useState([]);

  useEffect(() => {
    const filteredData = _.filter(funnelEventData, (x) => {
      const upperCaseLabel = x.label.toUpperCase();
      const upperCaseSearchTerm = funnelSearchField.toUpperCase();
      return upperCaseLabel.includes(upperCaseSearchTerm);
    });
    setEventsToRender(filteredData);
  }, [funnelSearchField, funnelEventData]);

  const onAddEventClicked = (eventObject) => {
    const newFunnel = [...currentFunnel, eventObject];
    setCurrentFunnel(newFunnel);
  };

  const onRemoveEventClicked = (indexToRemove) => {
    const funnelToReturn = [...currentFunnel];
    _.pullAt(funnelToReturn, [indexToRemove]);
    setCurrentFunnel(funnelToReturn);
  };

  const onMoveEventClicked = (indexToMoveFrom, indexToMoveTo) => {
    if (indexToMoveTo < 0) return;
    if (indexToMoveTo > currentFunnel.length) return;
    const newFunnel = move(currentFunnel, indexToMoveFrom, indexToMoveTo);
    setCurrentFunnel(newFunnel);
  };

  return (
    <>
      <SideBarSearchField
        title='FUNNEL EVENTS'
        value={funnelSearchField}
        onChange={setFunnelSearchField}
      />
      <Box
        className={styles.eventsContainer}
        style={{ height: currentFunnel.length ? '250px' : '70vh' }}
      >
        {eventsToRender.map((x) => (
          <FunnelEventRow
            key={x.label}
            eventName={x.label}
            testID={`FunnelEventRow_${x.label}`}
            eventType={x.type}
            onClick={onAddEventClicked}
          />
        ))}
      </Box>

      {currentFunnel.length ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <SidebarSubHeader className={styles.funnelTextHeader}>CURRENT FUNNEL</SidebarSubHeader>
          <Box className={styles.funnelContainer}>
            {currentFunnel.map((x, index) => (
              <CurrentFunnelEventRow
                eventName={x.label}
                eventType={x.type}
                index={index}
                key={index}
                onMoveEventClicked={onMoveEventClicked}
                onDeleteClick={() => onRemoveEventClicked(index)}
              />
            ))}
          </Box>
          <LoadingButton
            className={styles.buttonStyle}
            onClick={() => onFunnelGenerate(currentFunnel)}
            isLoading={isLoading}
          >
            Generate Funnel
          </LoadingButton>
          <SaveFunnelButton onClick={() => onSaveFunnel(currentFunnel)} label='Save Funnel' />
        </div>
      ) : null}
    </>
  );
};

export default FunnelBuilder;

FunnelBuilder.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  funnelEventData: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentFunnel: PropTypes.array.isRequired,
  onFunnelGenerate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSaveFunnel: PropTypes.func.isRequired,
  setCurrentFunnel: PropTypes.func.isRequired,
};
