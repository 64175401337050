import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Typography,
  Card,
  CardContent,
  Link,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { MoreVert, Edit, Delete } from '@material-ui/icons';
import styles from './AppCardStyle';
import appTypes from '../../utils/types/apps';

const AppCard = forwardRef(
  ({ app, onEditPress, onDeletePress, onViewAppKeyPress, style, isAdmin }, ref) => {
    const [actionMenuAnchorElement, setActionMenuAnchorElement] = React.useState(null);

    const onActionMenuIconClick = (event) => {
      setActionMenuAnchorElement(event.currentTarget);
    };

    const onActionMenuClose = () => {
      setActionMenuAnchorElement(null);
    };

    const { id: appId, appName, creationDate } = app;
    const formattedDate = moment(creationDate).format('ddd DD MMM, YYYY');

    const classes = styles();

    return (
      <Card variant='outlined' className={classes.card} style={style} ref={ref}>
        <Typography color='textSecondary' className={classes.cardHeaderText}>
          Application
        </Typography>

        {isAdmin && (
          <>
            <IconButton
              id={`action-menu-button-app-${appId}`}
              onClick={onActionMenuIconClick}
              color='textPrimary'
              className={classes.actionMenuButton}
              aria-controls='action-menu'
            >
              <MoreVert fontSize='large' />
            </IconButton>

            <Menu
              id={`action-menu-app-${appId}`}
              anchorEl={actionMenuAnchorElement}
              keepMounted
              open={!!actionMenuAnchorElement}
              onClose={onActionMenuClose}
            >
              <MenuItem
                onClick={() => {
                  onEditPress();
                  onActionMenuClose();
                }}
                className={classes.actionMenuItem}
                testID='AppCard_actionMenu_edit'
              >
                <ListItemIcon className={classes.actionMenuItemIcon}>
                  <Edit />
                </ListItemIcon>
                <ListItemText primary='Edit' className={classes.actionMenuItemText} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onDeletePress();
                  onActionMenuClose();
                }}
                className={classes.actionMenuItem}
                testID='AppCard_actionMenu_delete'
              >
                <ListItemIcon className={classes.actionMenuItemIcon}>
                  <Delete />
                </ListItemIcon>
                <ListItemText primary='Delete' className={classes.actionMenuItemText} />
              </MenuItem>
            </Menu>
          </>
        )}

        <CardContent className={classes.cardContent}>
          <Typography variant='h5' className={classes.applicationName}>
            {appName}
          </Typography>
          <Typography color='textSecondary' className={classes.creationDate}>
            {`Created on ${formattedDate}`}
          </Typography>
          <Link onClick={onViewAppKeyPress} variant='none' className={classes.viewAppKeyButton}>
            View App Key
          </Link>
        </CardContent>
      </Card>
    );
  },
);

AppCard.propTypes = {
  app: appTypes.app.isRequired,
  onEditPress: PropTypes.func.isRequired,
  onDeletePress: PropTypes.func.isRequired,
  onViewAppKeyPress: PropTypes.func.isRequired,
  style: PropTypes.element,
  isAdmin: PropTypes.bool,
};

AppCard.defaultProps = {
  style: {},
  isAdmin: false,
};

export default AppCard;
