import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import BasicFormModal from '../../Modals/BasicFormModal';
import TeamMemberItem from './TeamMemberItem';

import * as OrganisationTypes from '../../../utils/types/organisation';
import CustomerTypes from '../../../utils/types/customer';
import CustomerApi from '../../../api/v1/customer/customer';
import useNotifications from '../../../contexts/notificationContext';

import styles from './TeamListStyle';

const TeamList = ({
  team,
  currentUser,
  onUserRoleChange,
  onUserDeleted,
  onTeamMemberNotVerifiedIconPress,
}) => {
  const [userToDelete, setUserToDelete] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  const { addSuccessNotification, addErrorNotification } = useNotifications();

  const clearConfirmDeleteUser = () => setUserToDelete(undefined);

  const handleUserToConfirmDelete = (id) => {
    const user = _.find(team, { id });
    setUserToDelete(user);
  };

  const handleDeleteUser = async () => {
    setIsDeleting(true);

    try {
      await CustomerApi.deleteUser({ id: userToDelete.id });
      clearConfirmDeleteUser();
      onUserDeleted();
      addSuccessNotification('User Deleted');
    } catch (error) {
      addErrorNotification(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const classes = styles();

  return (
    <Box className={classes.root} data-testid='teamList'>
      <TeamMemberItem isHeading />
      {!!team &&
        team.map((member) => (
          <TeamMemberItem
            member={member}
            onUserRoleChange={onUserRoleChange}
            onDeleteUser={handleUserToConfirmDelete}
            onTeamMemberNotVerifiedIconPress={onTeamMemberNotVerifiedIconPress}
            currentUser={currentUser}
            key={member.email}
          />
        ))}

      {!!userToDelete && (
        <Modal open={!!userToDelete} onClose={clearConfirmDeleteUser}>
          <BasicFormModal
            title={`You're about to delete ${userToDelete.firstName} ${userToDelete.lastName}`}
            info='This will permanently delete the user and all of their settings. Are you sure?'
            confirmButtonText='Delete User'
            onClose={clearConfirmDeleteUser}
            onCancel={clearConfirmDeleteUser}
            onConfirm={handleDeleteUser}
            isLoading={isDeleting}
          />
        </Modal>
      )}
    </Box>
  );
};

TeamList.propTypes = {
  team: OrganisationTypes.team.isRequired,
  currentUser: CustomerTypes.currentUser.isRequired,
  onUserRoleChange: PropTypes.func.isRequired,
  onUserDeleted: PropTypes.func.isRequired,
  onTeamMemberNotVerifiedIconPress: PropTypes.func.isRequired,
};

export default TeamList;
