import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PageLayout from '../../components/Display/PageLayout';
import StatCounter from '../../components/Display/StatCounter';
import crashesAPI from '../../api/v1/crashes/crashes';
import userOptionsActions from '../../redux/actions/userOptionsActions';
import LineGraph from '../../components/Graph/LineGraph';
import DisplayError from '../../components/Generic/DisplayError';
import userOptionsSelector from '../../redux/selectors/userOptionsSelector';
import useActions from '../../redux/actions/useActions';
import hiding from '../../static/media/hiding_squirrel.svg';
import useStyles from './crashesStyles';
import CrashesTable from '../../components/Tables/CrashesTable';

const Crashes = () => {
  const classes = useStyles();
  const { fromDate, toDate, apps, currentAppId } = useSelector(userOptionsSelector);
  const { setSelectedDateRange } = useActions(userOptionsActions);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [crashedSessionStats, setCrashedSessionStats] = useState(0);
  const [crashedUserStats, setCrashedUserStats] = useState(0);

  const [crashedSessionGraphData, setCrashedSessionGraphData] = useState([]);
  const [crashesTableData, setCrashesTableData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setError('');
        setLoading(true);

        const payload = await crashesAPI.getCrashesOverview(currentAppId, fromDate, toDate);
        const { sessionCrashCount, userCrashCount, crashTable, crashGraph } = payload.data;

        setCrashedSessionStats(sessionCrashCount);
        setCrashedUserStats(userCrashCount);
        setCrashedSessionGraphData(crashGraph);
        setCrashesTableData(crashTable);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [fromDate, toDate]);

  return (
    <PageLayout
      isLoading={loading}
      error={error}
      title={crashedSessionStats ? 'Crashes' : null}
      fromDate={fromDate}
      toDate={toDate}
      onDatesSelected={setSelectedDateRange}
      apps={apps}
      currentAppId={currentAppId}
      highlightTab='Crashes'
      centerContentVertical={!crashedSessionStats}
      centerContentHorizontal={!crashedSessionStats}
    >
      {!crashedSessionStats ? (
        <DisplayError
          title='No Crashes to Report!'
          description='Please check the date range is correctly set if you are expecting some crashes'
          image={hiding}
        />
      ) : (
        <Grid container spacing={2} className={classes.root}>
          <Grid container item spacing={4} className={classes.statsWrapper}>
            <Grid item xs={12} sm={12} md={2} className={classes.countersWrapper}>
              <Grid item xs={6} sm={6} md={12} className={classes.counter}>
                <StatCounter label='Crashed Sessions' value={crashedSessionStats} />
              </Grid>
              <Grid item xs={6} sm={6} md={12} className={classes.counter}>
                <StatCounter label='Crashed Users' value={crashedUserStats} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={10} className={classes.graph}>
              <LineGraph data={crashedSessionGraphData} title='Crashed Sessions' />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className={classes.tableWrapper}>
            <CrashesTable data={crashesTableData} />
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default Crashes;
