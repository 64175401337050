import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    tabButton: {
      backgroundColor: 'white',
      color: '#2F2F4B',
      border: `1px solid ${theme.palette.common.borderColor}`,
      paddingLeft: 10,
      paddingRight: 10,
      textTransform: 'none',
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
      marginRight: 15,
      paddingBottom: 5,
      fontSize: 15,
      '&:hover': {
        backgroundColor: 'white',
        boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
      },
    },
    buttonDateIcon: {
      color: theme.palette.text.secondary,

      '@media (min-width: 992px)': {
        paddingRight: 10,
      },
    },
    buttonArrowIcon: {
      paddingLeft: 10,
      color: theme.palette.primary.main,
    },
    flex: {
      display: 'flex',
    },
    paper: {
      border: `1px solid ${theme.palette.common.borderColor}`,
      boxShadow: '0px 0px 5px 5px rgba(123,123,123,0.1)',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    innerDate: {
      paddingLeft: 5,
    },
    container: {
      padding: '16px 0',
      maxWidth: 685,
    },
    selectedDate: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      border: `1px solid ${theme.palette.common.borderColor}`,
      margin: 10,
      padding: 5,
      borderRadius: 5,
    },
    predefinedDateRangeContainer: {
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      paddingRight: '10pt',
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      height: '60%',

      '@media (min-width: 992px)': {
        height: '100%',
      },
    },
    button: {
      border: '1px solid rgba(175, 53, 82, 1)',
      textTransform: 'none',
      marginRight: 3,
      marginLeft: 3,

      '@media (min-width: 992px)': {
        width: '80%',
        margin: 0,
        height: 35,
      },
    },
    textButton: {
      display: 'flex',
      marginTop: 'auto',
      textTransform: 'none',
      textDecoration: 'underline',
      color: '#747983',
    },

    applyContainer: { display: 'flex', justifyContent: 'center', marginTop: 8 },
    applyButton: {
      border: '1px solid rgba(175, 53, 82, 1)',
      textTransform: 'none',
      height: 35,
      marginRight: 3,
      marginLeft: 3,
      width: '93%',
    },
  }),
);
