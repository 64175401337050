import _ from 'lodash';
import errors from '../../messages/errors';

// TODO: Replace with a better function that selects randomly from an array
// eslint-disable-next-line import/prefer-default-export
export const stringToColour = (str) => {
  if (!str) return;
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // eslint-disable-next-line no-bitwise
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();

  // eslint-disable-next-line consistent-return
  return `#${'00000'.substring(0, 6 - c.length)}${c}`;
};

export const addOrMerge = (arr, object, identifier, mergePredicate) => {
  const updatedArray = [...arr];

  const index = _.findIndex(updatedArray, { [identifier]: object[identifier] });
  if (index === -1) {
    updatedArray.push(object);
  } else {
    const existing = updatedArray[index];
    updatedArray[index] = mergePredicate(existing, object);
  }

  return updatedArray;
};

export const getApiError = (error) => {
  if (typeof error === 'string') {
    return error;
  }

  if (error?.response?.data?.message) {
    return error.response.data.message;
  }

  // eslint-disable-next-line camelcase
  if (error?.response?.data?.error_description) {
    return error.response.data.error_description;
  }

  if (error?.response?.data?.errors[0]) {
    return error.response.data.errors[0];
  }

  if (error?.message === 'Network Error') {
    return errors.SERVER_NOT_AVAILABLE;
  }

  if (error?.message) {
    return error.message;
  }

  return 'Something went wrong';
};

export const numberWithCommas = (number) =>
  // eslint-disable-next-line no-restricted-globals
  !isNaN(number) && number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
