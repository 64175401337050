import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  styleLeft: {
    position: 'absolute',
    padding: 0,
    left: 17,
    top: 0,
  },
  styleRight: {
    position: 'absolute',
    padding: 0,
    right: 17,
    top: 0,
  },
});
