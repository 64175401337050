import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './MobileNavButtonStyle';

const MobileNavButton = ({ page = '/', label, onClick }) => {
  const classes = styles();
  return (
    <Link
      to={page}
      onClick={onClick}
      className={classes.buttonStyle}
      data-testid='MobileNavButton-Link'
    >
      {label}
    </Link>
  );
};

MobileNavButton.propTypes = {
  label: PropTypes.string,
  page: PropTypes.string,
  onClick: PropTypes.func,
};

MobileNavButton.defaultProps = {
  label: undefined,
  page: undefined,
  onClick: undefined,
};

export default MobileNavButton;
