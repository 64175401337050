import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Modal } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import StylesProvider from '@material-ui/styles/StylesProvider';
import { SnackbarProvider } from 'notistack';
import ModalContext from '../contexts/modalContext';
import theme from '../styles/theme';
import { persistor, store } from '../redux/store';
import Router from './Router';
import '../utils/logger';
import { NotificationProvider } from '../contexts/notificationProvider';

export default function App() {
  console.log('You Have Console Logging Enabled');

  /**
   * @deprecated Do not use ModalContext. Instead use material-ui <Modal> directly in your components
   */
  const [modalContent, setModalContent] = useState(undefined);
  const clearModalContent = () => setModalContent(undefined);
  const renderModalContent = () => {
    if (!modalContent) return null;

    return (
      <Modal style={{ display: 'flex' }} open onClose={clearModalContent}>
        {modalContent}
      </Modal>
    );
  };

  const modalProviderValue = {
    setModalContent,
    clearModalContent,
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StylesProvider injectFirst>
          <ModalContext.Provider value={modalProviderValue}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <NotificationProvider>
                  <Router />
                  {renderModalContent()}
                </NotificationProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </ModalContext.Provider>
        </StylesProvider>
      </PersistGate>
    </Provider>
  );
}
