import * as yup from 'yup';
import { VALID_STRING } from './regex';

const genericMinCharacterErrorMessage = ({ min }) => `Must be at least ${min} characters`;
const genericMaxCharacterErrorMessage = ({ max }) => `Must be less than ${max} characters`;
const validStringErrorMessage = 'Must only contain valid characters';

export const signInValidation = yup.object().shape({
  emailAddress: yup.string().email('Email must be a valid email'),
  password: yup.string().min(1, ({ min }) => `Password must be at least ${min} characters`),
});

export const newPasswordValidation = yup
  .string()
  .trim()
  .matches(VALID_STRING, validStringErrorMessage)
  .matches(
    /^(?=.*\d).{6,30}$/,
    'Password must be between 6-30 characters and contain at least 1 number',
  );

export const signUpValidation = yup.object().shape({
  emailAddress: yup
    .string()
    .trim()
    .matches(VALID_STRING, validStringErrorMessage)
    .email('Email must be a valid email'),
  firstName: yup
    .string()
    .trim()
    .matches(VALID_STRING, validStringErrorMessage)
    .min(2, genericMinCharacterErrorMessage)
    .max(150, genericMaxCharacterErrorMessage),
  lastName: yup
    .string()
    .trim()
    .matches(VALID_STRING, validStringErrorMessage)
    .min(2, genericMinCharacterErrorMessage)
    .max(150, genericMaxCharacterErrorMessage),
  organisationName: yup
    .string()
    .trim()
    .matches(VALID_STRING, validStringErrorMessage)
    .min(2, genericMinCharacterErrorMessage)
    .max(255, genericMaxCharacterErrorMessage),
  password: newPasswordValidation,
  passwordConfirmation: yup
    .string()
    .trim()
    .oneOf([yup.ref('password')], 'Passwords do not match'),
});
