import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { AutoSizer, List } from 'react-virtualized';
import TextField from '../../TextFields/TextField';
import hidingSquirrel from '../../../static/media/hiding_squirrel.svg';
import types from '../../../utils/types/events';
import useStyles from './EventFilterPropertiesColumnStyle';
import CustomLoadingSpinner from '../../Spinners/CustomLoadingSpinner';

const EventFilterPropertiesColumn = ({ properties, onPropertyClick, loadingEvents }) => {
  const [propertySearch, setPropertySearch] = useState('');
  const [filteredProperties, setFilteredProperties] = useState([]);

  const handleOnChange = (e) => setPropertySearch(e.target.value);

  useEffect(() => {
    (async () => {
      if (!propertySearch) {
        setFilteredProperties(properties);
      } else {
        const transformedEventSearch = propertySearch.toLowerCase().trim();
        const filter = properties.filter((property) =>
          property.toLowerCase().includes(transformedEventSearch),
        );

        setFilteredProperties(filter);
      }
    })();
  }, [propertySearch, properties]);

  const classes = useStyles({ propertiesCount: properties.length });

  // eslint-disable-next-line react/prop-types
  const renderPropertyRow = ({ index, key, style }) => {
    const property = filteredProperties[index];

    return (
      <ButtonBase
        key={key}
        component={Box}
        className={classes.propertyRow}
        style={style}
        onClick={() => onPropertyClick(property)}
      >
        <Typography variant='body1' className={classes.propertyText}>
          {property}
        </Typography>
      </ButtonBase>
    );
  };

  const renderNoRows = () => (
    <Box className={classes.noProperties}>
      <CardMedia
        className={classes.noPropertiesIcon}
        image={hidingSquirrel}
        aria-label='No Properties'
      />
    </Box>
  );

  const renderLoading = () => (
    <CustomLoadingSpinner height={150} width={150} className={classes.loading} />
  );

  return (
    <Box className={clsx(classes.column)}>
      <TextField
        className={classes.propertyNameSearch}
        value={propertySearch}
        onChange={handleOnChange}
        margin='none'
        placeholder='Search Properties...'
        fullWidth={false}
        InputProps={{ disableUnderline: true }}
      />

      {!!loadingEvents && renderLoading()}

      {!loadingEvents && !filteredProperties.length && renderNoRows()}

      {!loadingEvents && (
        <Box className={classes.propertiesContainer}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                className={classes.list}
                width={width}
                height={height}
                rowHeight={50}
                overscanRowCount={20}
                rowCount={filteredProperties.length}
                rowRenderer={renderPropertyRow}
              />
            )}
          </AutoSizer>
        </Box>
      )}
    </Box>
  );
};

export default EventFilterPropertiesColumn;

EventFilterPropertiesColumn.propTypes = {
  properties: types.properties,
  onPropertyClick: PropTypes.func.isRequired,
  loadingEvents: PropTypes.bool,
};

EventFilterPropertiesColumn.defaultProps = {
  properties: [],
  loadingEvents: false,
};
