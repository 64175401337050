import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default makeStyles((theme) =>
  createStyles({
    formTextHelper: {
      backgroundColor: theme.palette.error.light,
      paddingLeft: 15,
      paddingTop: 8,
      paddingBottom: 8,
      fontWeight: 'bold',
      borderRadius: 4,
    },
  }),
);
