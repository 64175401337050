import apiUrls from '../../apiUrls';
import { HTTP } from '../../index';

const { PROFILE_DETAILS, UPDATE_PROFILE, UPDATE_PASSWORD, DELETE_USER } = apiUrls;

const getProfileDetails = () =>
  HTTP.waApi.get(PROFILE_DETAILS).then(({ data: profileDetails }) => ({
    data: {
      email: profileDetails.email,
      firstName: profileDetails.firstName,
      lastName: profileDetails.lastName,
      createdOnDate: profileDetails.signedUpOn,
      role: profileDetails.role,
    },
  }));

const updateProfileDetails = ({ email, firstName, lastName }) =>
  HTTP.waApi.patch(UPDATE_PROFILE, {
    emailAddress: email,
    firstName,
    lastName,
  });

const updatePassword = ({ currentPassword, newPassword }) =>
  HTTP.waApi.patch(UPDATE_PASSWORD, {
    currentPassword,
    newPassword,
  });

const deleteUser = ({ id }) => HTTP.waApi.delete(DELETE_USER(id));

export default {
  getProfileDetails,
  updateProfileDetails,
  updatePassword,
  deleteUser,
};
