import { shape, number, string, arrayOf } from 'prop-types';

const crashesMetadata = shape({
  anonDevicesAffectedCount: number.isRequired,
  crashAmountCount: number.isRequired,
  crashMessage: string.isRequired,
  earliestOccurrence: string.isRequired,
  latestOccurrence: string.isRequired,
  sessionsAffectedCount: number.isRequired,
  totalPeopleAffectedCount: number.isRequired,
  usersAffectedCount: number.isRequired,
});

const crashTable = arrayOf(crashesMetadata);

export default {
  crashTable,
  crashesMetadata,
};
