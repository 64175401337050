import React from 'react';
import PropTypes from 'prop-types';

import { Fade, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import UnderlinedButton from '../Buttons/UnderlinedButton';

import styles from './GenericModalStyles';

const TeamMemberNotificationModal = ({
  titleText,
  bodyText,
  emailAddress,
  resendConfirmationEmail,
  onModalClose,
}) => {
  const classes = styles();
  const renderContent = () => {
    return (
      <Grid container item xs={12} className={classes.formContainer}>
        <Grid container item spacing={1} xs={10} md={8}>
          <Grid item xs={12} className={classes.textCenter}>
            <ReportProblemOutlinedIcon className={classes.infoIcon} />
          </Grid>
          <Grid item xs={12} className={classes.textCenter}>
            <Typography variant='body1' className={classes.formTitle}>
              {titleText}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.textCenter}>
            <Typography
              variant='body1'
              color='textSecondary'
              className={classes.formInfoText}
              data-testid='BasicFormModal-info'
            >
              {bodyText}
            </Typography>
          </Grid>

          {resendConfirmationEmail && (
            <Grid item xs={12} className={classes.textCenter}>
              <UnderlinedButton
                label='Re-Send Email'
                className={classes.textButton}
                onClick={() => resendConfirmationEmail(emailAddress)}
                aria-label='resend verification email'
                data-testid='TeamMemberNotVerifiedModal-resendEmailButton'
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Fade in>
      <Grid item xs={10} md={5} className={classes.modalContainer}>
        <Paper className={classes.modal} data-testid='TeamMemberNotVerifiedModal'>
          <Grid container>
            <IconButton
              onClick={onModalClose}
              color='primary'
              className={classes.closeButton}
              aria-label='close add new app modal'
              data-testid='TeamMemberNotVerifiedModal-closeIcon'
            >
              <CloseRoundedIcon />
            </IconButton>
            {renderContent()}
          </Grid>
        </Paper>
      </Grid>
    </Fade>
  );
};

TeamMemberNotificationModal.propTypes = {
  titleText: PropTypes.string,
  bodyText: PropTypes.string,
  emailAddress: PropTypes.string.isRequired,
  resendConfirmationEmail: PropTypes.func,
  onModalClose: PropTypes.func.isRequired,
};

TeamMemberNotificationModal.defaultProps = {
  titleText: "Team member hasn't accepted invitation!",
  bodyText: 'An email has been sent to confirm and create a full account.',
  resendConfirmationEmail: undefined,
};

export default TeamMemberNotificationModal;
