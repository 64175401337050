import cookie from 'js-cookie';
import axios from 'axios';

import { logout } from '../utils/auth/auth';
import apiUrls from './apiUrls';
import { getApiError } from '../utils/general/generalFunctions';

const isSignIn = (response) => {
  const responseURL = response.request?.responseURL;
  if (!responseURL) return false;
  return responseURL.endsWith(apiUrls.SIGN_IN);
};

const commonHeaders = () => {
  const token = cookie.get('token');

  const headers = {};

  if (token) headers.Authorization = `Bearer ${token}`;

  return headers;
};

export const setAxiosRequestInterceptorHelper = (apiInstance) => {
  apiInstance.interceptors.request.use(
    (config) => {
      const headers = commonHeaders();

      return {
        ...config,
        headers: { ...config.headers, ...headers },
      };
    },
    (error) => error,
  );
};

export const setAxiosResponseInterceptorHelper = (apiInstance) => {
  apiInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response = {} } = error;
      const { status } = response;

      const isCancellation = axios.isCancel(error);

      if (isCancellation) {
        return Promise.reject(error);
      }

      if (status === 401 && !isSignIn(response)) {
        logout({ windowRedirect: true });
      }

      const rejectionPayload = {
        ...error,
        message: getApiError(error),
        errorsArray: error?.response?.data?.errors,
      };

      return Promise.reject(rejectionPayload);
    },
  );
};
