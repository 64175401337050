import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Box, Grid, Radio, FormLabel, FormControlLabel } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { RadioGroup } from 'formik-material-ui';

import newUserValidation from '../../../validation/organization/newUserValidation';
import { useAddNewUserCallback } from '../../../hooks/organizationHooks';
import type NewUserBody from '../../../api/v1/organisation/types/NewUserBody.type';

import SubHeader from '../../Text/SubHeader';
import TextField from '../../TextFields/TextField';
import PasswordField from '../../TextFields/PasswordField';
import AlertArray from '../AlertArray';
import LoadingButton from '../../Buttons/LoadingButton';

import styles from './AddNewUserFormStyles';
import useNotifications from '../../../contexts/notificationContext';

const initialValues: NewUserBody = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  password: '',
  passwordConfirmation: '',
  role: 'USER',
};

const AddNewUserForm = ({ onSuccess }) => {
  const [isLoading, setLoading] = useState(false);
  const { addSuccessNotification, addErrorNotification } = useNotifications();
  const onSubmit = useAddNewUserCallback(
    setLoading,
    onSuccess,
    addSuccessNotification,
    addErrorNotification,
  );
  const classes = styles();

  return (
    <Box className={classes.container}>
      <SubHeader className={classes.subheader}>Add New User</SubHeader>
      <Typography variant='body1' className={classes.description}>
        Once you’ve filled all the details to add a new user, an email will be sent for the provided
        email address to confirm and create a full account.
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={newUserValidation}
        onSubmit={onSubmit}
        validateOnChange={false}
      >
        {({ values, errors, handleChange, handleBlur }) => (
          <Form method='post' className={classes.form}>
            <Grid container spacing={4}>
              {errors.general && (
                <Grid item xs={12}>
                  <AlertArray severity='error' alerts={errors.general} />
                </Grid>
              )}

              <Grid item xs={12} md={6}>
                <TextField
                  id='firstName'
                  label='First name'
                  placeholder='First name'
                  value={values.firstName}
                  error={errors.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoading}
                  variant='outlined'
                  required
                  fullWidth={false}
                  className={classes.textfield}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id='lastName'
                  label='Last name'
                  placeholder='Last name'
                  value={values.lastName}
                  error={errors.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoading}
                  variant='outlined'
                  required
                  fullWidth={false}
                  className={classes.textfield}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id='emailAddress'
                  label='Email'
                  placeholder='Email'
                  value={values.emailAddress}
                  error={errors.emailAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoading}
                  variant='outlined'
                  required
                  className={classes.textfield}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <PasswordField
                  id='password'
                  label='Create a password'
                  placeholder='Create a password'
                  value={values.password}
                  error={errors.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoading}
                  required
                  className={classes.textfield}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <PasswordField
                  id='passwordConfirmation'
                  label='Confirm new password'
                  placeholder='Confirm new password'
                  value={values.passwordConfirmation}
                  error={errors.passwordConfirmation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoading}
                  required
                  className={classes.textfield}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormLabel component='legend' className={classes.formLabel}>
                  Assign team member&apos;s role
                </FormLabel>
                <Field component={RadioGroup} name='role' row>
                  <FormControlLabel
                    value='USER'
                    label='User'
                    control={<Radio disabled={isLoading} />}
                    disabled={isLoading}
                    className={classes.formControlLabel}
                  />
                  <FormControlLabel
                    value='ADMIN'
                    label='Administrator'
                    control={<Radio disabled={isLoading} />}
                    disabled={isLoading}
                    className={classes.formControlLabel}
                    style={{ marginLeft: 45 }}
                  />
                </Field>
              </Grid>

              <Grid item xs={12} md={6}>
                <LoadingButton
                  id='submit'
                  name='Add Team Member'
                  type='submit'
                  isLoading={isLoading}
                  fullWidth
                  variant='contained'
                  className={classes.button}
                >
                  Add Team Member
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

AddNewUserForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default AddNewUserForm;
