import PropTypes from 'prop-types';
import clsx from 'clsx';
import React from 'react';
import Tab from '@material-ui/core/Tab';
import useStyles from './TabItemStyles';

const TabItem = ({ label, selected, start, end, uppercase, ...rest }) => {
  const classes = useStyles();
  return (
    <Tab
      label={label}
      className={clsx(classes.tab, {
        [classes.selected]: selected,
        [classes.start]: start,
        [classes.end]: end,
        [classes.uppercase]: uppercase,
      })}
      {...rest}
    />
  );
};

export default TabItem;

TabItem.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  start: PropTypes.bool,
  end: PropTypes.bool,
  uppercase: PropTypes.bool,
};

TabItem.defaultProps = {
  end: false,
  selected: false,
  start: false,
  uppercase: false,
};
