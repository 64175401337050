import { createMuiTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: '0px 0px 5px #BEBEBE29',
        border: `1px solid #C2C6CE88`,
        '&:hover': {
          boxShadow: '0px 0px 5px #BEBEBE29',
        },
        '&:active': {
          boxShadow: '0px 0px 5px #BEBEBE29',
        },
      },
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        body2: 'span',
      },
    },
    MuiButtonRoot: {
      textTransform: 'none',
    },
  },
  palette: {
    forageRed: '#A52043',
    common: {
      background: '#f3f3f3',
      borderColor: '#C2C6CE88',
    },
    primary: {
      light: '#AF3552',
      main: '#AF3552',
      dark: '#AF3552',
    },
    text: {
      primary: '#2F2F4B',
      secondary: '#747983',
    },
    error: {
      light: '#FEEAED',
      main: '#F02A48',
      dark: '#F02A48',
    },
    warningYellow: '#FF9D00',
    success: {
      light: '#B8FDF0',
      main: '#59D0BD',
      dark: '#309B89',
    },
  },
  customPaper: {
    border: '1px solid',
    borderColor: '#EAEDF3',
    borderRadius: 10,
  },
  typography: {
    body2: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.13rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
  },
  headerSize: {
    padding: '15pt 25pt 10pt 25pt',
  },
  flexColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  headerHeight: '55pt',
  timelineItemHeight: 60,
  scrollbar: {
    '&::-webkit-scrollbar': {
      width: '5pt',
      height: '5pt',
    },
    '&::-webkit-scrollbar-track': {
      background: '#EDF0F7',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#747983',
      borderRadius: '100pt',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#666',
    },
  },
});

export default theme;
